import React from "react";

export function useAsyncEffect(
  effect: () => (Promise<void> | (() => void | undefined)),
  deps?: React.DependencyList
): void {
  React.useEffect(() => {
    let handler: void | (() => void | undefined);

    (async () => {
      handler = await effect();
    })();

    return () => {
      if (handler instanceof Function) {
        handler();
      }
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
