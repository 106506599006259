import React, { useRef, useEffect, useState, ReactElement } from "react";

interface AuctionDialProps {
  value: number;
  totalBlocks: number;
}

const START_RED_VALUE = 70;
const END_RED_VALUE = 255;
const HEX_MAX = 256;

export const AuctionDial = ({
  value,
  totalBlocks,
}: AuctionDialProps): ReactElement => {
  const canvasRef = useRef(null);

  const [steps, setSteps] = useState(totalBlocks);
  const [currentVal, setCurrentVal] = useState(value);

  useEffect(() => {
    const canvas: any = canvasRef.current;
    const context = canvas.getContext("2d");

    const draw = (ctx: any) => {
      ctx.canvas.width = 350;
      ctx.canvas.height = 350;
      let i, j, ref;
      ctx.save();
      ctx.translate(175, 175);
      ctx.rotate(-90 * (Math.PI / 180) - (Math.PI * 2) / steps);
      for (i = j = 0, ref = steps - 1; j <= ref; i = j += 1) {
        ctx.beginPath();
        ctx.rotate((Math.PI * 2) / steps);
        ctx.lineWidth = 2;
        ctx.lineTo(130, 0);
        ctx.lineTo(170, 0);
        if (i <= Math.floor(currentVal)) {
          const numberToHex = (
            (START_RED_VALUE +
              (END_RED_VALUE - START_RED_VALUE) * (i / steps)) %
            HEX_MAX
          )
            .toString(16)
            .slice(0, 2);
          ctx.shadowBlur = 10;
          ctx.strokeStyle = `#${numberToHex}00FF`;
          ctx.shadowColor = `#${numberToHex}00FF`;
        } else {
          ctx.strokeStyle = "#EEEFF0";
          ctx.shadowBlur = 0;
          ctx.shadowColor = "#fff";
        }
        ctx.stroke();
      }
      ctx.restore();
    };

    const render = () => {
      setSteps(totalBlocks);
      setCurrentVal(value);
      draw(context);
    };
    render();
  }, [currentVal, steps, totalBlocks, value]);

  return <canvas ref={canvasRef} />;
};

export default AuctionDial;
