import { useWeb3Contracts } from "contexts/Web3ContractsContext";
import { useCountdown } from "hooks/useCountdown";
import { BigNumber } from "@ethersproject/bignumber";
import React from "react";
import config from "assets/config.json";
import { formatPrefix, now, formatUnits } from "web3/utils";
import Card from "components/Card/Card";
import Panel from "components/Panel/Panel";
import { Pool, PoolData } from "web3/contracts/Pool";
import { mulPrice, scaleUnit, toPrice } from "web3/prices";
import { usePrices } from "contexts/PriceContext";

const phase1 = ["dai", "usdt", "usdc"] as const;

const phase2 = [
  ...phase1,
  "wbtc",
  "sushi",
  "eth",
  "yfi",
  "bankEthSLP",
  "yam",
] as const;

const phase4 = ["bankEthSLP"] as const;

const phase4b = ["floatEthSLP", "float"] as const;

const multiplier = ["bank", "bankEthSLP", "floatEthSLP"] as const;

interface StatPanelProps {
  isGenesis?: boolean;
}

const GenesisStatPanel: React.FC = () => {
  const contracts = useWeb3Contracts();
  const { bank, bankPhase4aPool, bankEthSLPPhase4Pool, floatMintingCeremony } =
    contracts;

  const nowEpoch = now();

  const timeLeftInGenesis = useCountdown(
    Math.max(bankPhase4aPool.endWindow ?? 0, config.minting_end)
  );

  const totalStaked = bankPhase4aPool.totalStaked?.add(
    bankEthSLPPhase4Pool.totalStaked ?? 0
  );

  return (
    <Panel>
      {nowEpoch >= config.phase_3_end && nowEpoch < config.minting_end ? (
        <Card title="Time left in Genesis" content={timeLeftInGenesis} />
      ) : (
        <Card title="Genesis ended" content={"00h 00m 00s"} />
      )}
      <Card
        title="Inactive FLOAT"
        content={formatUnits(floatMintingCeremony.totalSupply, 18) ?? "-"}
        footnote={`$${
          formatPrefix(
            floatMintingCeremony.totalSupply?.mul(1618).div(1000),
            18
          ) ?? "-"
        }`}
      />
      <Card
        title="BANK locked in Genesis"
        content={`${
          formatUnits(totalStaked?.mul(1e6).div(bank.totalSupply ?? 1), 4) ??
          "-"
        }%`}
      />
      <Card title="FLOAT allowance usage" content={`85.64%`} />
    </Panel>
  );
};

const PoolsStatPanel: React.FC = () => {
  const contracts = useWeb3Contracts();
  const { bankPhase4aPool } = contracts;
  const { fetched, getPriceData } = usePrices();

  const [weeklyReward, setWeeklyReward] = React.useState("-");
  const [tvl, setTVL] = React.useState("-");

  React.useEffect(() => {
    let tvlSum = BigNumber.from(0);
    let rewardSum = BigNumber.from(0);
    let rewardSumb = BigNumber.from(0);
    for (const key of phase1) {
      const pool = contracts[
        `${key}Pool` as keyof typeof contracts
      ] as Pool<PoolData>;
      const token = contracts[key];
      const tokenPrice = toPrice(token.priceInUSD ?? 0);
      const scaledAmount = scaleUnit(
        pool.totalStaked,
        token.tokenMeta.decimals
      );
      const tvl = mulPrice(scaledAmount, tokenPrice);
      tvlSum = tvlSum.add(tvl ?? 0);
    }
    for (const key of phase2) {
      const pool = contracts[
        `${key}Phase2Pool` as keyof typeof contracts
      ] as Pool<PoolData>;
      const token = contracts[key];
      const tokenPrice = toPrice(token.priceInUSD ?? 0);
      const scaledAmount = scaleUnit(
        pool.totalStaked,
        token.tokenMeta.decimals
      );
      const tvl = mulPrice(scaledAmount, tokenPrice);
      tvlSum = tvlSum.add(tvl ?? 0);
      rewardSum = rewardSum.add(pool.totalReward ?? 0);
    }

    for (const key of phase4) {
      const pool = contracts[
        `${key}Phase4Pool` as keyof typeof contracts
      ] as Pool<PoolData>;
      const token = contracts[key];
      const tokenPrice = toPrice(token.priceInUSD ?? 0);
      const scaledAmount = scaleUnit(
        pool.totalStaked,
        token.tokenMeta.decimals
      );
      const tvl = mulPrice(scaledAmount, tokenPrice);
      tvlSum = tvlSum.add(tvl ?? 0);
      rewardSum = rewardSum.add(pool.totalReward ?? 0);
    }
    for (const key of phase4b) {
      const pool = contracts[
        `${key}Phase4Pool` as keyof typeof contracts
      ] as Pool<PoolData>;
      const token = contracts[key];
      const tokenPrice = toPrice(token.priceInUSD ?? 0);
      const scaledAmount = scaleUnit(
        pool.totalStaked,
        token.tokenMeta.decimals
      );
      const tvl = mulPrice(scaledAmount, tokenPrice);
      tvlSum = tvlSum.add(tvl ?? 0);
      rewardSumb = rewardSumb.add(pool.totalReward ?? 0);
    }

    for (const key of multiplier) {
      const pool = contracts[
        `${key}_MultiplierPool` as keyof typeof contracts
      ] as Pool<PoolData>;
      const token = contracts[key];
      const tokenPrice = toPrice(token.priceInUSD ?? 0);
      const scaledAmount = scaleUnit(
        pool.totalStaked,
        token.tokenMeta.decimals
      );
      const tvl = mulPrice(scaledAmount, tokenPrice);
      tvlSum = tvlSum.add(tvl ?? 0);
      rewardSumb = rewardSumb.add(pool.totalReward ?? 0);
    }

    const phase4aPool = contracts.bankPhase4aPool;
    const bank = contracts.bank;
    const tokenPrice = toPrice(bank.priceInUSD ?? 0);
    const scaledAmount = scaleUnit(
      phase4aPool.totalStaked,
      bank.tokenMeta.decimals
    );
    const tvl = mulPrice(scaledAmount, tokenPrice);
    tvlSum = tvlSum.add(tvl ?? 0);

    setTVL(formatPrefix(tvlSum) ?? "-");
    // TODO: Do this properly tomorrow.
    setWeeklyReward("640");
  }, [contracts, bankPhase4aPool.duration]);

  const [bankPriceInEth, setBankPriceInEth] = React.useState("-");
  const [bankPrice, setBankPrice] = React.useState("-");

  React.useEffect(() => {
    const bank = getPriceData("BANK");
    if (bank) {
      bank.eth && setBankPriceInEth(parseFloat(bank.eth).toFixed(4));
      bank.usd && setBankPrice(parseFloat(bank.usd).toFixed(2));
    }
  }, [fetched, getPriceData]);

  return (
    <Panel>
      <Card title="Total Value Staked" content={tvl ? `$${tvl}` : "-"} />
      <Card title="Weekly Distribution" content={weeklyReward ?? "-"} />

      {/* 
      {nowEpoch >= config.phase_3_end ? (
        <Card title="Phase 4" content="New Pools pending" />
      ) : null}

      {nowEpoch < config.phase_3_end && nowEpoch >= config.phase_2_end ? (
        <Card title="Time left in Phase 3" content={timeLeftInPhase3} />
      ) : null}

      {nowEpoch < config.phase_2_end && nowEpoch >= config.phase_1_end ? (
        <Card title="Time left in Phase 2" content={timeLeftInPhase2} />
      ) : null}
      {nowEpoch < config.phase_1_end && nowEpoch >= config.phase_1_start ? (
        <Card title="Time left in Phase 1" content={timeLeftInPhase1} />
      ) : null}
      {nowEpoch < config.phase_1_start ? (
        <Card title="Time left to Launch" content={timeLeftToLaunch} />
      ) : null} */}
      <Card
        title="Bank Price"
        content={`${bankPriceInEth} ETH`}
        footnote={`$${bankPrice}`}
      />
    </Panel>
  );
};

export const StatPanel: React.FC<StatPanelProps> = ({
  isGenesis = false,
}: StatPanelProps) => {
  if (isGenesis) {
    return <GenesisStatPanel />;
  }

  return <PoolsStatPanel />;
};
