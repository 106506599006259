import React, { PropsWithChildren } from "react";

import AuctionDial from "components/AuctionDial/AuctionDial";

import "./AuctionProgress.css";

interface AuctionProgressProps {
  value: number;
  totalBlocks: number;
  children?: React.ReactNode;
  strokeWidth?: number;
}

export const AuctionProgress: React.FC<AuctionProgressProps> = ({
  value,
  totalBlocks,
  children,
}: PropsWithChildren<AuctionProgressProps>) => {
  return (
    <div className="auction-progress-circle-container">
      <AuctionDial value={value} totalBlocks={totalBlocks} />

      <div className="auction-progress-title">{children}</div>
    </div>
  );
};

export default AuctionProgress;
