import React, { ReactElement } from "react";
import Modal from "react-modal";

import "../FarmStatementCard/FarmStatementCard.css";
import "./GenesisStatementCard.css";

import { useWallet } from "wallets/wallet";
import ConnectWalletButton from "components/ConnectWallet/ConnectWalletButton";
import { useStakeOption } from "contexts/StakeOption";
import LoadingButton from "components/LoadingButton/LoadingButton";
import { formatUnits, now } from "web3/utils";
import { Phase4aPool } from "web3/contracts/Phase4Pool";
import { useCountdown, useCountdownQuick } from "hooks/useCountdown";
import Ticker from "components/Ticker/Ticker";

Modal.setAppElement("#root");

const TWO_HOURS = 7200;
const EIGHT_DAYS = 691200;

function GenesisStatementCard(): ReactElement {
  const { isActive } = useWallet();

  const {
    pool,
    token,
    startApprove,
    startRequestWithdraw,
    startWithdraw,
    startDeposit,
    approving,
    requestWithdrawing,
    withdrawing,
    depositing,
  } = useStakeOption();
  const {
    staked,
    proportionOfPool,
    startWindow,
    inLimbo,
    unlockStarted,
    endWindow,
    periodFinish,
    totalReward,
  } = pool as Phase4aPool;
  const { allowance } = token;
  const { icon, decimals } = token.tokenMeta;

  const deposit = staked ? formatUnits(staked, decimals) : "-";
  const locked = inLimbo ? formatUnits(inLimbo, decimals) : "-";

  const poolAllowance = allowance[pool.contract.address];
  const hasNoAllowance = poolAllowance && poolAllowance.lte(0);
  const hasNoStake = staked && staked.lte(0);
  
  // Time Periods
  const epochNow = now() ?? 0;
  const unlockFinished = unlockStarted && unlockStarted + EIGHT_DAYS;
  const autoUnlock = (periodFinish ?? 0) + TWO_HOURS;

  const isEmpty = totalReward?.eq(0) && epochNow >= autoUnlock;
  const hasUnlockPassed =
    !!(unlockFinished && epochNow >= unlockFinished) || !!isEmpty; // 8 days have passed since unlock
  const isInLimbo = !hasUnlockPassed && inLimbo && inLimbo.gt(0);

  const stillToStart = startWindow ? startWindow > now() : false;

  const [timeToGo] = useCountdownQuick(stillToStart ? startWindow : undefined);
  const [timeLeftInLimbo] = useCountdown(
    isInLimbo ? unlockFinished : undefined
  );

  const canStillStake = endWindow ? endWindow > now() : true;

  const ApproveOrDeposit = () => (
    <>
      {hasNoAllowance ? (
        <LoadingButton
          className="medium-button loading-button"
          text="Approve"
          loading={approving}
          onClick={startApprove}
        />
      ) : (
        <>
          {canStillStake && (
            <LoadingButton
              className="medium-button loading-button"
              text="Deposit"
              loading={depositing}
              onClick={startDeposit}
            />
          )}
        </>
      )}
    </>
  );

  const UnlockOrWithdraw = () => (
    <>
      {isInLimbo && (
        <LoadingButton
          className="medium-button loading-button"
          text="Unlocking..."
          loading={true}
        />
      )}
      {!isInLimbo && !hasNoStake && (
        <LoadingButton
          className="medium-button loading-button"
          text="Unlock"
          loading={requestWithdrawing}
          onClick={startRequestWithdraw}
        />
      )}
      {hasUnlockPassed && (
        <LoadingButton
          className="medium-button loading-button"
          text="Withdraw"
          loading={withdrawing}
          onClick={startWithdraw}
        />
      )}
    </>
  );

  if (stillToStart) {
    return (
      <div className="farm-statement-card-container">
        {icon}
        <p>Pool opens in:</p>
        <div className="countdown-container">
          <Ticker
            value={timeToGo.replace(" ", "")}
            constantKeys={["h", "m", "s"]}
            style={{
              fontSize: "3rem",
            }}
          />
        </div>
      </div>
    );
  }

  const DepositDescriptionOrTimer = () => (
    <>
      {isInLimbo || hasUnlockPassed ? (
        <>
          <p>Your Stake</p>
          <div className="deposit-value-container">
            <div className="deposit-container">
              <h2 className="user-deposit">{inLimbo?.gt(0) ? locked : deposit}</h2>
            </div>

            {inLimbo?.gt(0) && <p className="percentage-container">
              {" "}
              (<span className="percentage-value">{timeLeftInLimbo}</span> left)
            </p>}
          </div>
        </>
      ) : (
        <>
          {canStillStake || !hasNoStake ? (
            <>
              <p>Your Deposit</p>
              <div className="deposit-value-container">
                <div className="deposit-container">
                  <h2 className="user-deposit">{deposit}</h2>
                </div>

                <p className="percentage-container">
                  {" "}
                  (<span className="percentage-value">
                    {proportionOfPool}%
                  </span>{" "}
                  of total)
                </p>
              </div>
            </>
          ) : (
            <>
              <p>Genesis is over. You may no longer stake.</p>
              <h2 className="bank-tokens-earned">-</h2>
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <div className="farm-statement-card-container">
        {icon}

        <DepositDescriptionOrTimer />
        {isActive && (
          <div className="buttons-container">
            <UnlockOrWithdraw />
            <ApproveOrDeposit />
          </div>
        )}
        {!isActive && (
          <>
            <div className="buttons-container">
              <ConnectWalletButton />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default GenesisStatementCard;
