import { useReload } from "hooks/useReload";
import React, { PropsWithChildren } from "react";

interface PriceData {
  usd: string;
  eth: string;
}

interface CoinCurrencies {
  [coinName: string]: PriceData;
}
interface Prices {
  prices: CoinCurrencies;
  fetched: boolean;
  getPrice(name: string): string | undefined;
  getPriceData(name: string): PriceData | undefined;
  getPriceFloat(name: string): number | undefined;
}

export const COINGECKO_ID_MAPPING = {
  SUSHI: "sushi",
  USDT: "tether",
  wBTC: "wrapped-bitcoin",
  YFI: "yearn-finance",
  YAM: "yam-2",
  ETH: "ethereum",
  DAI: "dai",
  USDC: "usd-coin",
  FEI: "fei-protocol",
  BANK: "float-protocol",
  wETH: "ethereum",
  FLOAT: "float-protocol-float",
};

const PricesContext = React.createContext<Prices>({} as Prices);

export function usePrices(): Prices {
  return React.useContext(PricesContext);
}

const PricesProvider: React.FC = ({ children }: PropsWithChildren<unknown>) => {
  const [reload] = useReload();
  const [fetched, setFetched] = React.useState<boolean>(false);
  const [prices, setPrices] = React.useState<CoinCurrencies>({});

  const getPriceData = (name: string) => {
    if (!(name in COINGECKO_ID_MAPPING)) {
      console.log(`Not sure how to price: ${name}`);
      return undefined;
    }

    const coingeckoId =
      COINGECKO_ID_MAPPING[name as keyof typeof COINGECKO_ID_MAPPING];

    return prices[coingeckoId];
  };

  const getPrice = (name: string) => {
    const priceData = getPriceData(name);
    return priceData?.usd;
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const ids = Object.values(COINGECKO_ID_MAPPING).join(",");
      const url = `https://api.coingecko.com/api/v3/simple/price/?ids=${ids}&vs_currencies=usd,eth`;
      if (!ids) return;
      try {
        const response = await fetch(url).then((r) => r.json());
        setPrices(response);
        setFetched(true);
      } catch (err) {
        console.warn(`Failed to fetch token price of ${ids}`, err);
      }
    };

    setFetched(false);
    fetchData();
  }, [reload]);

  return (
    <PricesContext.Provider
      value={{
        prices,
        fetched,
        getPrice,
        getPriceData,
        getPriceFloat: (n) => {
          const price = getPrice(n);
          return price ? parseFloat(price) : undefined;
        },
      }}
    >
      {children}
    </PricesContext.Provider>
  );
};

export default PricesProvider;
