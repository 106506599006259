import React, { useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import DropdownMenu from "components/DropdownMenu/DropdownMenu";
import ExternalLink from "components/ExternalLink/ExternalLink";
import FloatProtocolLogo from "assets/float/float_thumbprint.svg";
import ConnectWalletButton from "components/ConnectWallet/ConnectWalletButton";
import "./TopBar.css";
import useOnClickOutside from "hooks/useOnClickOutside";

interface TopBarProps {
  showConnect: boolean;
  menuState: boolean;
  setMenuState: any;
}

function TopBar({
  showConnect,
  setMenuState,
  menuState,
}: TopBarProps): React.ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const location = useLocation();
  useEffect(() => {
    setMenuState(false);
  }, [location, setMenuState]);

  useOnClickOutside(ref, () => setDropdownOpen(false));

  return (
    <div className="topbar-container">
      <div className="box topbar-box-left">
        <Link to="/">
          <img
            className="topbar-logo"
            src={FloatProtocolLogo}
            alt="FLOAT PROTOCOL"
          />
        </Link>
      </div>
      <div className="box topbar-box-center">
        <div className="topbar-routes">
          <p className="topbar-word">
            <Link className="topbar-link -attract" to="/auction">
              Auction
            </Link>
          </p>
          <p className="topbar-word">
            <Link className="topbar-link" to="/pools">
              Pools
            </Link>
          </p>
          <p className="topbar-word">
            <ExternalLink
              className="topbar-link"
              href="https://snapshot.org/#/snapshot.floatprotocol.eth"
            >
              Governance
            </ExternalLink>
          </p>
        </div>
        {showConnect && (
          <div className="box topbar-box-right">
            <ConnectWalletButton
              dropdownOpen={dropdownOpen}
              openDropdown={() => setDropdownOpen(true)}
            />
            <DropdownMenu
              ref={ref}
              isOpen={dropdownOpen}
              onClose={() => setDropdownOpen(false)}
            />
          </div>
        )}
      </div>
      <div onClick={() => setMenuState(!menuState)} className="burger-menu">
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

export default TopBar;
