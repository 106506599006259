import React from "react";
import styled from "styled-components";

import StakeOptionProvider from "contexts/StakeOption";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";

import GenesisStakePanel from "components/GenesisStakePanel/GenesisStakePanel";
import ExchangePanel from "components/ExchangePanel/ExchangePanel";
import ThemeProvider from "theme";
import ExchangeProvider from "contexts/ExchangeContext";
import { StatPanel } from "components/StatPanel/StatPanel";
import PhaseInfo from "components/PhaseInfo/PhaseInfo";
import ExternalLink from "components/ExternalLink/ExternalLink";

const ExchangePanelWrapper = styled.div`
  background: white;
  border: 1px solid ${({ theme }) => theme.bg2};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: auto;
  padding: 0 2rem 4rem;
`;

const ExchangeWrapper = styled.div`
  justify-content: center;
  margin-top: 2rem;
`;

const BANKPhase4Panel = () => {
  const { bank, bankPhase4aPool } = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={bank}
      pool={bankPhase4aPool}
      options={{
        displayName: "Genesis BANK",
        description: (
          <>
            <p>To purchase FLOAT, you must stake BANK during Genesis.</p>
            <p>
              Stake{" "}
              <ExternalLink
                href={`https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=${bank.contract.address}`}
              >
                BANK
              </ExternalLink>{" "}
              to earn BANK tokens.
            </p>
          </>
        ),
        subText: (
          <>
            {" "}
            <p>
              If you request to withdraw from the BANK-Only pool, your stake is
              locked for 8 days and you will no longer receive rewards during
              this period.
            </p>
          </>
        ),
      }}
    >
      <GenesisStakePanel showAPR />
    </StakeOptionProvider>
  );
};

const ExchangeLastGroup = (
  <>
    <ExchangeProvider>
      <ExchangePanelWrapper>
        <ExchangePanel />
      </ExchangePanelWrapper>
      <PhaseInfo title={<h4>Genesis</h4>}>
        <h3>
          Genesis staking is over. The Genesis Pool will remain earning rewards
          until Sunday 18th July 10pm UTC by
          <ExternalLink href="https://snapshot.org/#/snapshot.floatprotocol.eth/proposal/QmU2AcgY6Q9KstpuDqViCQMyZC5xaeFEi5JTRBjMWSJueA">
            {" governance vote"}
          </ExternalLink>
          . The rewards per locked BANK can now only increase as others exit.
        </h3>
        <p>
          If you request to withdraw from the BANK-only pool, your entire stake
          is locked for 8 days and you will no longer receive rewards during
          this period.
        </p>
        <p>
          If you top-up your stake in the pool the lock timer is reset and your
          entire stake continues to earn rewards.
        </p>

        <p>
          320 BANK per week will be distributed to the BANK-only pool for the
          duration of the extended Phase 4 lasting 5 weeks, starting Sunday 13th
          of June at 10pm UTC and finishing on the 18th of July at 10pm UTC.
        </p>
      </PhaseInfo>

      <BANKPhase4Panel />
    </ExchangeProvider>
  </>
);

const GenesisView: React.FC = () => {
  return (
    <ThemeProvider>
      <StatPanel isGenesis />
      <ExchangeWrapper>{ExchangeLastGroup}</ExchangeWrapper>
    </ThemeProvider>
  );
};

export default GenesisView;
