import React from "react";

import { BigNumber } from "@ethersproject/bignumber";

import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useReload } from "hooks/useReload";
import { useWallet } from "wallets/wallet";
import { usePreferences } from "contexts/PreferencesContext";
import { TokenMeta } from "web3/TokenMeta";
import { ABIS, getDeployment, getLPs, toBigNumber } from "web3/utils";
import Web3Contract, { BatchContractMethod } from "web3/Web3Contract";
import {
  TokenContractWithAllowance,
  TokenContractWithAllowanceData,
  USDCTokenMeta,
} from "./StakeTokens";
import GuniLogo from "assets/tokens/GUNI.png";
import FloatLogo from "assets/float/float_thumbprint.svg";
import { scaleUnit } from "web3/prices";

import { usePrices } from "contexts/PriceContext";

interface GuniTokenAdditional extends TokenContractWithAllowanceData {
  amount0?: BigNumber;
  amount1?: BigNumber;
}

export interface GuniToken
  extends TokenContractWithAllowance,
    GuniTokenAdditional {}

const initialData: Omit<TokenContractWithAllowanceData, "tokenMeta"> = {
  balance: undefined,
  allowance: {},
  priceInUSD: "0",
};

interface UseTokenContractProps {
  tokenMeta: TokenMeta;
  tokenPairIds: {
    token0: string;
    token1: string;
  };
  allowanceAddresses?: string[];
}

export function useGuniToken({
  tokenMeta,
  tokenPairIds,
  allowanceAddresses = [],
}: UseTokenContractProps): GuniToken {
  const [reload] = useReload();
  const wallet = useWallet();
  const { txn } = usePreferences();
  const { fetched, getPriceFloat } = usePrices();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(tokenMeta.abi, tokenMeta.address, tokenMeta.name);
  }, [tokenMeta.abi, tokenMeta.name, tokenMeta.address]);

  const [data, setData] = React.useState<GuniTokenAdditional>({
    ...initialData,
    tokenMeta,
  });

  useAsyncEffect(async () => {
    const [underlyingBalances, totalSupply] = await contract.batch([
      {
        method: "getUnderlyingBalances",
        transform: (value: any) => {
          return [
            toBigNumber(value.amount0Current),
            toBigNumber(value.amount1Current),
          ];
        },
      },
      {
        method: "totalSupply",
        transform: (value: string) => BigNumber.from(value),
      },
    ]);

    const [amount0, amount1] = underlyingBalances ?? [undefined, undefined];

    let priceInUSD: string | undefined;

    if (amount0 && amount1 && totalSupply) {
      const token0Price: number | undefined = getPriceFloat(
        tokenPairIds.token0
      );
      const token1Price: number | undefined = getPriceFloat(
        tokenPairIds.token1
      );

      const precision = 1e4;
      const totalLocked0 = scaleUnit(amount0, 6, 18)?.mul(
        ((token0Price ?? 0) * precision).toFixed(0)
      );

      const totalLocked1 = scaleUnit(amount1)?.mul(
        ((token1Price ?? 0) * precision).toFixed(0)
      );
      if (totalLocked0 && totalLocked1) {
        priceInUSD = (
          totalLocked0
            ?.add(totalLocked1 ?? 0)
            ?.div(totalSupply)
            ?.toNumber() / precision
        ).toString();
      }
    }

    setData((prevState) => ({
      ...prevState,
      priceInUSD,
      amount0,
      amount1,
    }));
  }, [reload, fetched]);

  useAsyncEffect(async () => {
    let balance: BigNumber | undefined;
    let allowanceResults: (BigNumber | undefined)[] = [];

    if (wallet.account) {
      [balance, ...allowanceResults] = await contract.batch([
        {
          method: "balanceOf",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
        ...allowanceAddresses.map<BatchContractMethod>((address) => ({
          method: "allowance",
          methodArgs: [wallet.account, address],
          transform: (value: string) => BigNumber.from(value),
        })),
      ]);
    }

    const allowance = Object.fromEntries(
      allowanceAddresses.map((addr, i) => {
        return [addr, allowanceResults[i]];
      })
    );

    setData((prevState) => ({
      ...prevState,
      balance,
      allowance,
    }));
  }, [reload, wallet.account]);

  const approveSend = React.useCallback(
    (address: string, value: BigNumber): Promise<void> => {
      if (!wallet.account) {
        return Promise.reject();
      }

      if (!allowanceAddresses.includes(address)) {
        return Promise.reject("Unmonitored address");
      }

      return contract
        .send("approve", [address, value], {
          from: wallet.account,
          type: txn.type,
        })
        .then(reload);
    },
    [wallet.account, contract, allowanceAddresses, txn.type, reload]
  );
  return React.useMemo<TokenContractWithAllowance>(
    () => ({
      ...data,
      contract,
      reload,
      approveSend,
    }),
    [data, contract, reload, approveSend]
  );
}

// GUNI FLOAT/USDC
export const guniFloatUsdTokenMeta: TokenMeta = {
  abi: getDeployment().contracts.GUNI_FLOAT_USDC?.abi ?? ABIS.GUNI_TOKEN,
  address: getLPs().guniFloatUsdc,
  decimals: 18,
  name: "G-UNI USDC/FLOAT",
  icon: (
    <div className="token-pair">
      {USDCTokenMeta.icon}
      <span className="lp-provider">
        <img
          src={GuniLogo}
          className="coin-logo"
          alt="Gelato Finance: G-UNI Token"
        />
      </span>
      <img
        src={FloatLogo}
        className="coin-logo"
        alt="Float Protocol: FLOAT logo"
      />
    </div>
  ),
};

export function useGuniFloatUsdcContract(): TokenContractWithAllowance {
  const multiplierPoolAddress =
    getDeployment().contracts.GUNI_FLOAT_USDC_MultiplierPool?.address;
  return useGuniToken({
    tokenMeta: guniFloatUsdTokenMeta,
    tokenPairIds: {
      token0: "USDC",
      token1: "FLOAT",
    },
    allowanceAddresses: [
      ...(multiplierPoolAddress ? [multiplierPoolAddress] : []),
    ],
  });
}
