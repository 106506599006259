import React, { ReactElement } from "react";

import "./Banner.css";

interface BannerProps {
  content: React.ReactNode;
}
function Banner({ content }: BannerProps): ReactElement {
  return <div className="banner">{content}</div>;
}

export default Banner;
