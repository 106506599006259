import React, { ReactElement } from "react";
import { ArrowDown } from "react-feather";
import FloatLogo from "assets/float/float_thumbprint.svg";

import { AutoRow } from "../Row";
import PriceInputPanel from "components/PriceInputPanel/PriceInputPanel";
import { Target, TradeChoice, useExchange } from "contexts/ExchangeContext";
import { useWallet } from "wallets/wallet";

import { formatUnits as ethersFormatUnits } from "@ethersproject/units";
import { MaxUint256 } from "@ethersproject/constants";
import { formatUnits, now } from "web3/utils";

import LoadingButton from "components/LoadingButton/LoadingButton";
import ConnectWalletButton from "components/ConnectWallet/ConnectWalletButton";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";

import "./ExchangePanel.css";

const navigateToExternalUrl = (url: string, shouldOpenNewTab = true) =>
  shouldOpenNewTab ? window.open(url, "_blank") : (window.location.href = url);

const ExchangePanel = (): ReactElement => {
  const {
    target,
    choice,
    spendToken,
    approving,
    purchasing,
    minting,
    fromAmount,
    toAmount,
    inputAmount,
    startApprove,
    startPurchase,
    startMint,
    handleTypeInput,
    selectCurrency,
  } = useExchange();
  const { floatMintingCeremony, float, zapInFloatMinting } = useWeb3Contracts();
  const { allowance: reserveAllowance, balance: reserveBalance } =
    floatMintingCeremony;
  const { balance: spendBalance, allowance: spendAllowance } = spendToken;
  const { isActive } = useWallet();

  // Allowance checking
  const contractAllowance =
    choice === TradeChoice.ETH
      ? MaxUint256
      : spendAllowance[
          choice === TradeChoice.WETH
            ? floatMintingCeremony.contract.address
            : zapInFloatMinting.contract.address
        ];
  const hasNoContractAllowance = contractAllowance && contractAllowance.lte(0);

  const anyAllowance = contractAllowance?.gt(reserveAllowance ?? 0)
    ? reserveAllowance
    : contractAllowance;

  // Flags
  const hasReserved = reserveBalance?.gt(0);
  const isOver = now() > (floatMintingCeremony.ceremonyEnd ?? 0);
  const exceedReserveAllowance =
    reserveAllowance && reserveAllowance.lt(toAmount ?? 0);

  const exceedBalance = spendBalance && spendBalance.lt(fromAmount ?? 0);

  const hasNoBalance = spendBalance ? spendBalance.lte(0) : true;
  const empty = inputAmount === "";
  const disabled = exceedBalance || exceedReserveAllowance || empty;
  const hasNoAllowance = anyAllowance ? anyAllowance.lte(0) : true;

  // Formatting
  const floatAllowance =
    formatUnits(reserveAllowance, float.tokenMeta.decimals) ?? "-";
  const floatBalance =
    formatUnits(reserveBalance, float.tokenMeta.decimals) ?? "-";
  const fromAmountFormatted = fromAmount
    ? ethersFormatUnits(fromAmount, spendToken.tokenMeta.decimals)
    : "";
  const toAmountFormatted = toAmount
    ? ethersFormatUnits(toAmount, float.tokenMeta.decimals)
    : "";
  const spendBalanceFormatted = spendBalance
    ? ethersFormatUnits(spendBalance, spendToken.tokenMeta.decimals)
    : "";
  const allowanceFormatted = reserveAllowance
    ? ethersFormatUnits(reserveAllowance, float.tokenMeta.decimals)
    : "";

  // Handlers
  const maximiseFloat = () => {
    handleTypeInput(Target.TO, allowanceFormatted);
  };

  const maximiseSpend = () => {
    handleTypeInput(Target.FROM, spendBalanceFormatted);
  };

  const fromLabel = "From";
  const toLabel = "To (estimated)";

  if (isOver) {
    const labelText =
      hasReserved === undefined ? "Mint / Buy" : hasReserved ? "Mint" : "Buy";
    return (
      <div className="exchange-panel">
        <div className="trade-title">
          <h1>{labelText}</h1>
        </div>
        <img src={FloatLogo} alt="FLOAT logo" />
        <h2 className="float-tokens-earned">
          {(hasReserved && floatBalance) ?? "-"}
        </h2>
        {isActive ? (
          <div className="buttons-container">
            <LoadingButton
              className={`max-width-button loading-button`}
              text={labelText}
              loading={minting}
              onClick={
                hasReserved
                  ? startMint
                  : () =>
                      navigateToExternalUrl(
                        "https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xb05097849BCA421A3f51B249BA6CCa4aF4b97cb9"
                      )
              }
            />
          </div>
        ) : (
          <>
            <div className="buttons-container">
              <ConnectWalletButton />
            </div>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="exchange-panel">
        <div className="trade-title">
          <h1>Reserve</h1>
          <span className="whitelist-badge">
            Reserve up to {floatAllowance} FLOAT
          </span>
        </div>
        <PriceInputPanel
          label={fromLabel}
          value={target === Target.FROM ? inputAmount : fromAmountFormatted}
          onUserInput={(value: string) => handleTypeInput(Target.FROM, value)}
          onMax={hasNoBalance ? undefined : maximiseSpend}
          selectCurrency={selectCurrency}
          error={exceedBalance}
          token={spendToken}
        />
        <AutoRow justify="center">
          <ArrowDown />
        </AutoRow>
        <PriceInputPanel
          label={toLabel}
          onUserInput={(value: string) => handleTypeInput(Target.TO, value)}
          value={target === Target.TO ? inputAmount : toAmountFormatted}
          error={exceedReserveAllowance}
          token={float}
          customAction={
            anyAllowance ? (
              <>
                Allowance: {formatUnits(anyAllowance, float.tokenMeta.decimals)}{" "}
              </>
            ) : (
              <></>
            )
          }
          onMax={hasNoAllowance ? undefined : maximiseFloat}
        />
        <div className="sub-heading">
          Note: You may reserve 100 FLOAT for every BANK staked during Genesis
          Period. You can claim your FLOAT after the 15th of May 10pm UTC.
        </div>
        <br />
        {isActive && hasNoContractAllowance && (
          <div className="buttons-container">
            <LoadingButton
              className="max-width-button loading-button"
              text="Approve"
              loading={approving}
              onClick={startApprove}
            />
          </div>
        )}
        {isActive && !hasNoContractAllowance && (
          <div className="buttons-container">
            <LoadingButton
              className={`max-width-button loading-button${
                disabled ? " -disabled" : ""
              }`}
              text={
                exceedBalance
                  ? "Insufficient balance"
                  : exceedReserveAllowance
                  ? "Requires more BANK to be staked"
                  : empty
                  ? "Enter an amount"
                  : "Reserve"
              }
              loading={purchasing}
              onClick={disabled ? console.warn : startPurchase}
            />
          </div>
        )}
        {!isActive && (
          <>
            <div className="buttons-container">
              <ConnectWalletButton />
            </div>
          </>
        )}
      </div>
    );
  }
};

export default ExchangePanel;
