import React, { ReactElement } from "react";

import { ExternalIcon } from "components/ExternalLink/ExternalLink";

import { useStakeOption } from "contexts/StakeOption";
import { etherscanAddress, formatPrefix, formatUnits } from "web3/utils";
import BankEarnedCard from "components/BankEarnedCard/BankEarnedCard";
import { BANKTokenMeta } from "web3/contracts/TokenContractWithSupply";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";
import { mulPrice, scaleUnit, toApr, toPrice } from "web3/prices";
import StatementCard from "./MultiplierPanel.StatementCard";
import { MultiplierPool } from "web3/contracts/MultiplierPool";

interface MultiplierPanelProps {
  showAPR?: boolean;
}

function MultiplierPanel({ showAPR }: MultiplierPanelProps): ReactElement {
  const { token, pool, options } = useStakeOption();
  const { totalReward, duration, totalStaked } = pool as MultiplierPool;
  const { bank } = useWeb3Contracts();
  const { name, decimals } = token.tokenMeta;
  const etherscanLink = etherscanAddress(pool.contract.address);

  const tokenPriceInUsd = toPrice(token.priceInUSD ?? 0);
  const totalStakedUnified = scaleUnit(totalStaked, decimals);
  const totalStakedDollar = mulPrice(totalStakedUnified, tokenPriceInUsd);
  const bankPrice = toPrice(bank.priceInUSD ?? 0);
  const weeklyBank = totalReward
    ?.mul(1e4)
    ?.div(duration ? Math.round((duration * 1e4) / 60 / 60 / 24 / 7) : 1);

  const weeklyRewardInUsd = mulPrice(weeklyBank, bankPrice);

  const formattedWeeklyReward = weeklyBank
    ? formatPrefix(weeklyBank, BANKTokenMeta.decimals, 0)
    : "-";
  const totalStakedPrefixed = formatPrefix(totalStaked, decimals);
  const totalStakedDollarPrefixed = formatPrefix(totalStakedDollar);

  const calcAPR = toApr(totalStakedDollar, weeklyRewardInUsd);

  return (
    <div className="farm-panel-container">
      <div className={`farm-panel-header${showAPR ? " three-stat" : ""}`}>
        <div className="farm-panel-instructions">
          <h2>
            {options?.displayName ?? name}
            <ExternalIcon href={etherscanLink} />
          </h2>
          {options?.description ?? <p>Deposit {name} to earn BANK tokens</p>}
        </div>

        <div className="smallColumnDivider"></div>

        {showAPR && (
          <>
            <div className="farm-deposit-stats">
              <p className="farm-secondary-title">{name} APR</p>
              <p className="farm-stat">
                ~{calcAPR && formatUnits(calcAPR, 16)}%
              </p>
              <p className="farm-subtitle">Average staker return</p>
            </div>

            <div className="smallColumnDivider"></div>
          </>
        )}

        <div className="farm-deposit-stats">
          <p className="farm-secondary-title">Total Staked</p>
          <p className="farm-stat">{totalStakedPrefixed}</p>
          {totalStaked && (
            <p className="farm-subtitle">
              {totalStakedDollarPrefixed
                ? `$${totalStakedDollarPrefixed}`
                : "-"}
            </p>
          )}
        </div>

        <div className="smallColumnDivider"></div>

        <div className="farm-deposit-stats">
          <p className="farm-secondary-title">Weekly Distribution</p>
          <p className="farm-stat">{formattedWeeklyReward}</p>
          <p className="farm-subtitle">BANK per week</p>
        </div>
      </div>

      <div className="farm-panel-body">
        <div className="left-box">
          <BankEarnedCard />
        </div>

        <div className="columnDivider"></div>

        <div className="right-box">
          <StatementCard />
        </div>
      </div>

      <div>{options?.subText}</div>
    </div>
  );
}

export default MultiplierPanel;
