import React, { PropsWithChildren } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { MaxUint256 } from "@ethersproject/constants";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";
import { AlertTriangle } from "react-feather";

import CurrencySelectModal from "components/CurrencySelectModal/CurrencySelectModal";
import { TokenContractWithAllowance } from "web3/contracts/StakeTokens";
import PurchaseModal from "components/PurchaseModal/PurchaseModal";
import GeneralModal from "components/GeneralModal/GeneralModal";
import { useReceipt } from "hooks/useReceipt";
import { toast } from "react-toastify";
import { safeParseUnits } from "web3/utils";
import {
  amountIn,
  amountOut,
  divPrice,
  mulPrice,
  scaleUnit,
  toPrice,
} from "web3/prices";

export enum TradeChoice {
  ETH,
  DAI,
  USDC,
  FEI,
  USDT,
  WETH,
}

export enum Target {
  FROM,
  TO,
}

interface Exchange {
  selectCurrency: VoidFunction;
  onCurrencySelect(currency: string, choice: TradeChoice): void;
  selectedCurrency: string;
  spendToken: TokenContractWithAllowance;
  choice: TradeChoice;

  // Controllers
  approving: boolean;
  startApprove: VoidFunction;

  purchasing: boolean;
  startPurchase: VoidFunction;
  purchase(): void;

  minting: boolean;
  startMint: VoidFunction;
  // Ordering
  target: Target;

  // Amounts
  inputAmount: string;
  fromAmount?: BigNumber;
  toAmount?: BigNumber;
  handleTypeInput(target: Target, value: string): void;

  onMax(type: string): void;
}

type ChoiceToTokenMapping = {
  [choice in TradeChoice]: "usdt" | "usdc" | "dai" | "eth" | "fei" | "weth";
};

const MAPPING: ChoiceToTokenMapping = {
  [TradeChoice.ETH]: "eth",
  [TradeChoice.WETH]: "weth",
  [TradeChoice.DAI]: "dai",
  [TradeChoice.USDC]: "usdc",
  [TradeChoice.USDT]: "usdt",
  [TradeChoice.FEI]: "fei",
};

const ExchangeContext = React.createContext<Exchange>({} as any);

export function useExchange(): Exchange {
  return React.useContext(ExchangeContext);
}

const ExchangeProvider: React.FC = ({
  children,
}: PropsWithChildren<unknown>) => {
  // Modal Controllers
  const [currencySelectModalOpen, setCurrencySelectModalOpen] =
    React.useState(false);
  const [purchaseModalOpen, setPurchaseModalOpen] = React.useState(false);
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);
  const [error, setError] = React.useState("");

  // Status Controllers
  const [purchasing, setPurchasing] = React.useState(false);
  const [approving, setApproving] = React.useState(false);
  const [minting, setMinting] = React.useState(false);

  // Currency State
  const [selectedCurrency, setSelectedCurrency] = React.useState("ETH");
  const [choice, setChoice] = React.useState(TradeChoice.ETH);
  const [target, setTarget] = React.useState(Target.FROM);

  // Price
  const [price, setPrice] = React.useState<BigNumber>();

  // Amounts
  const [inputAmount, setInputAmount] = React.useState<string>("");
  const [fromAmount, setFromAmount] = React.useState<BigNumber>();
  const [toAmount, setToAmount] = React.useState<BigNumber>();

  const contracts = useWeb3Contracts();
  const spendToken = contracts[MAPPING[choice]];
  const { floatMintingCeremony, zapInFloatMinting, weth, float } = contracts;
  const mintingTarget =
    choice === TradeChoice.WETH ? floatMintingCeremony : zapInFloatMinting;

  const { floatFromEth: floatPerEth } = floatMintingCeremony;

  // CurrencySelect Modal open/close
  const currencySelectModalClose = React.useCallback(() => {
    setCurrencySelectModalOpen(false);
  }, [setCurrencySelectModalOpen]);

  const selectCurrency = () => {
    setCurrencySelectModalOpen(true);
  };

  const onCurrencySelect = (currency: string, choice: TradeChoice) => {
    setSelectedCurrency(currency);
    setChoice(choice);
    setCurrencySelectModalOpen(false);
  };

  // Handle input
  const handleTypeInput = (target: Target, value: string) => {
    setTarget(target);
    setInputAmount(value.toString());
  };

  // Calculate Price
  React.useEffect(() => {
    if (
      !floatPerEth ||
      !spendToken.priceInUSD ||
      !weth.priceInUSD ||
      Number(weth.priceInUSD) === 0
    ) {
      return;
    }

    // X FLOAT/ETH / USD/ETH * USD/T = Y FLOAT/T
    const floatPerDAI = divPrice(floatPerEth, toPrice(weth.priceInUSD));
    const floatPerSpendToken = mulPrice(
      floatPerDAI,
      toPrice(spendToken.priceInUSD)
    );
    setPrice(floatPerSpendToken);
  }, [floatPerEth, spendToken.priceInUSD, weth.priceInUSD, choice]);

  // Update From Amount
  React.useEffect(() => {
    if (target === Target.FROM) {
      const amountFrom: BigNumber | undefined = safeParseUnits(
        inputAmount,
        spendToken.tokenMeta.decimals
      );

      setFromAmount(amountFrom);

      if (!amountFrom || !price) {
        return;
      }

      const normalisedAmountFrom = scaleUnit(
        amountFrom,
        spendToken.tokenMeta.decimals,
        float.tokenMeta.decimals
      );

      // Assumes amount out in e18
      setToAmount(amountOut(normalisedAmountFrom, price));
      return;
    }

    if (target === Target.TO) {
      const amountTo: BigNumber | undefined = safeParseUnits(
        inputAmount,
        float.tokenMeta.decimals
      );

      setToAmount(amountTo);

      if (!amountTo || !price) {
        return;
      }

      const amountFrom = amountIn(amountTo, price);

      const downScaledAmountFrom = scaleUnit(
        amountFrom,
        float.tokenMeta.decimals,
        spendToken.tokenMeta.decimals
      );
      setFromAmount(downScaledAmountFrom);
    }
  }, [
    spendToken.tokenMeta.decimals,
    inputAmount,
    target,
    price,
    float.tokenMeta.decimals,
  ]);

  // Handle Transactions
  useReceipt(
    (receipt) =>
      toast.success(
        `${spendToken.tokenMeta.name} approved #${receipt.transactionHash}`
      ),
    spendToken.contract,
    "approve"
  );

  const startPurchase = () => {
    setPurchaseModalOpen(true);
  };

  const purchase = async () => {
    console.log(`Tried to purchase ${toAmount} for ${fromAmount}`);
    setPurchasing(true);

    if (!toAmount || !fromAmount) {
      setError(`Purchase failed > We couldn't estimate your amounts`);
      setErrorModalOpen(true);
      setPurchasing(false);
      setPurchaseModalOpen(false);
      return;
    }

    try {
      const toAmountWithSlippage = toAmount.mul(995).div(1e3);
      if (choice === TradeChoice.WETH) {
        await floatMintingCeremony.commit(fromAmount, toAmountWithSlippage);
      } else {
        await zapInFloatMinting.zapIn(
          spendToken.contract.address,
          fromAmount,
          toAmountWithSlippage
        );
      }
      spendToken.reload();
    } catch (err) {
      setError(`Purchase failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("deposit.purchase.err", err);
    }
    setPurchasing(false);
  };

  const startApprove = async () => {
    setApproving(true);
    try {
      await spendToken.approveSend(mintingTarget.contract.address, MaxUint256);
      spendToken.reload();
    } catch (err) {
      setError(`Approve failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("claim.claim.err", err);
    }
    setApproving(false);
  };

  const startMint = async () => {
    setMinting(true);
    try {
      await floatMintingCeremony.mint();
    } catch (err) {
      setError(`Mint failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("mint.mint.err", err);
    }
    setMinting(false);
  };

  const onMax = (side: "from" | "to") => {
    if (side === "from") {
      setFromAmount(BigNumber.from(10).pow(20));
    }
    if (side === "to") {
      setToAmount(BigNumber.from(10).pow(20));
    }
  };

  const value = {
    selectCurrency,
    selectedCurrency,
    onCurrencySelect,
    spendToken,
    target,
    choice,
    purchasing,
    approving,
    startApprove,
    startPurchase,
    purchase,
    inputAmount,
    fromAmount,
    toAmount,
    handleTypeInput,
    onMax,
    minting,
    startMint,
  };

  return (
    <ExchangeContext.Provider value={value}>
      <CurrencySelectModal
        isOpen={currencySelectModalOpen}
        onCancel={currencySelectModalClose}
        modalTitle="Select Currency"
      />
      <PurchaseModal
        isOpen={purchaseModalOpen}
        onCancel={() => setPurchaseModalOpen(false)}
      />
      <GeneralModal
        className="error-modal"
        isOpen={errorModalOpen}
        onCancel={() => setErrorModalOpen(false)}
        modalTitle={"Here's the issue."}
        modalBody={error}
        modalIcon={<AlertTriangle />}
      />
      {children}
    </ExchangeContext.Provider>
  );
};

export default ExchangeProvider;
