import React, { useEffect } from "react";

import { BigNumber } from "@ethersproject/bignumber";

import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useReload } from "hooks/useReload";
import { useWallet } from "wallets/wallet";
import { usePreferences } from "contexts/PreferencesContext";
import { TokenMeta } from "web3/TokenMeta";
import { ABIS, getDeployment, getLPs, now, toBigNumber } from "web3/utils";
import Web3Contract, { BatchContractMethod } from "web3/Web3Contract";
import { BANKTokenMeta, FLOATTokenMeta } from "./TokenContractWithSupply";
import {
  TokenContractWithAllowance,
  ETHTokenMeta,
  TokenContractWithAllowanceData,
  SUSHITokenMeta,
} from "./StakeTokens";
import { scaleUnit } from "web3/prices";

import { usePrices } from "contexts/PriceContext";

interface LPTokenAdditional extends TokenContractWithAllowanceData {
  totalSupply?: BigNumber;
  reserve0?: BigNumber;
  reserve1?: BigNumber;
  price0Cumulative?: BigNumber;
  price1Cumulative?: BigNumber;

  priceInToken0?: BigNumber;
  priceInToken1?: BigNumber;
}

export interface LPToken
  extends TokenContractWithAllowance,
    LPTokenAdditional {}

const initialData: Omit<TokenContractWithAllowanceData, "tokenMeta"> = {
  balance: undefined,
  allowance: {},
  priceInUSD: "0",
};

interface UseTokenContractProps {
  tokenMeta: TokenMeta;
  allowanceAddresses?: string[];
}

export function useLPToken({
  tokenMeta,
  allowanceAddresses = [],
}: UseTokenContractProps): LPToken {
  const [reload] = useReload();
  const wallet = useWallet();
  const { txn } = usePreferences();
  const { fetched, getPrice } = usePrices();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(tokenMeta.abi, tokenMeta.address, tokenMeta.name);
  }, [tokenMeta.abi, tokenMeta.name, tokenMeta.address]);

  const [data, setData] = React.useState<LPTokenAdditional>({
    ...initialData,
    tokenMeta,
  });

  useAsyncEffect(async () => {
    const [reserves, totalSupply, price0Cumulative, price1Cumulative] =
      await contract.batch([
        {
          method: "getReserves",
          transform: (value: any) => {
            return [
              toBigNumber(value._reserve0),
              toBigNumber(value._reserve1),
              Number(value._blockTimestampLast),
            ];
          },
        },
        {
          method: "totalSupply",
          transform: (value: string) => BigNumber.from(value),
        },
        {
          method: "price0CumulativeLast",
          transform: toBigNumber,
        },
        {
          method: "price1CumulativeLast",
          transform: toBigNumber,
        },
      ]);

    const [reserve0, reserve1, blockTimestampLast] = reserves ?? [
      undefined,
      undefined,
      undefined,
    ];

    // Cumulative prices
    let price0CumulativeToNow: BigNumber | undefined;
    let price1CumulativeToNow: BigNumber | undefined;

    if (price0Cumulative && price1Cumulative) {
      const blockTimestamp = now() % 2 ** 32;
      const timeElapsed = blockTimestamp - blockTimestampLast;

      price0CumulativeToNow = price0Cumulative.add(
        reserve1.mul(timeElapsed).div(reserve0).mul(BigNumber.from(2).pow(112))
      );
      price1CumulativeToNow = price1Cumulative.add(
        reserve0.mul(timeElapsed).div(reserve1).mul(BigNumber.from(2).pow(112))
      );
    }

    setData((prevState) => ({
      ...prevState,
      totalSupply,
      reserve0,
      reserve1,
      price0Cumulative: price0CumulativeToNow,
      price1Cumulative: price1CumulativeToNow,
    }));
  }, [reload]);

  useEffect(() => {
    let priceInUSD: string | undefined;
    let priceInToken0: BigNumber | undefined;
    let priceInToken1: BigNumber | undefined;

    if (data.reserve0 && data.reserve1 && data.totalSupply) {
      const price = getPrice("ETH");
      const ethPrice: number = price ? parseFloat(price) : 0;
      const precision = 1e4;
      const priceMeasure = ethPrice * precision;

      priceInToken0 = scaleUnit(data.reserve1, -9)?.div(data.reserve0);
      priceInToken1 = scaleUnit(data.reserve0, -9)?.div(data.reserve1);

      priceInUSD = (
        data.reserve1
          .mul(priceMeasure.toFixed(0))
          .mul(2)
          .div(data.totalSupply)
          .toNumber() / precision
      ).toString();
    }

    setData((prevState) => ({
      ...prevState,
      priceInToken0,
      priceInToken1,
      priceInUSD,
    }));
  }, [fetched, getPrice, data.reserve0, data.reserve1, data.totalSupply]);

  useAsyncEffect(async () => {
    let balance: BigNumber | undefined;
    let allowanceResults: (BigNumber | undefined)[] = [];

    if (wallet.account) {
      [balance, ...allowanceResults] = await contract.batch([
        {
          method: "balanceOf",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
        ...allowanceAddresses.map<BatchContractMethod>((address) => ({
          method: "allowance",
          methodArgs: [wallet.account, address],
          transform: (value: string) => BigNumber.from(value),
        })),
      ]);
    }

    const allowance = Object.fromEntries(
      allowanceAddresses.map((addr, i) => {
        return [addr, allowanceResults[i]];
      })
    );

    setData((prevState) => ({
      ...prevState,
      balance,
      allowance,
    }));
  }, [reload, wallet.account]);

  const approveSend = React.useCallback(
    (address: string, value: BigNumber): Promise<void> => {
      if (!wallet.account) {
        return Promise.reject();
      }

      if (!allowanceAddresses.includes(address)) {
        return Promise.reject("Unmonitored address");
      }

      return contract
        .send("approve", [address, value], {
          from: wallet.account,
          type: txn.type,
        })
        .then(reload);
    },
    [wallet.account, contract, allowanceAddresses, txn.type, reload]
  );

  return React.useMemo<TokenContractWithAllowance>(
    () => ({
      ...data,
      contract,
      reload,
      approveSend,
    }),
    [data, contract, reload, approveSend]
  );
}

// BANK-ETH sLP
export const bankSLPTokenMeta: TokenMeta = {
  abi: ABIS.LP_TOKEN,
  address: getLPs().bankEth,
  decimals: 18,
  name: "BANK/ETH sLP",
  icon: (
    <div className="token-pair">
      {BANKTokenMeta.icon}
      <span className="lp-provider">{SUSHITokenMeta.icon}</span>
      {ETHTokenMeta.icon}
    </div>
  ),
};

export function useBankEthSLPContract(): LPToken {
  return useLPToken({
    tokenMeta: bankSLPTokenMeta,
    allowanceAddresses: [
      getDeployment().contracts.sLPPhase2Pool.address,
      getDeployment().contracts.BankEthLPPhase4Pool.address,
      getDeployment().contracts.BankEthLP_MultiplierPool.address,
    ],
  });
}

// FLOAT-ETH sLP
export const floatSLPTokenMeta: TokenMeta = {
  abi: ABIS.LP_TOKEN,
  address: getLPs().floatEth,
  decimals: 18,
  name: "FLOAT/ETH sLP",
  icon: (
    <div className="token-pair">
      {FLOATTokenMeta.icon}
      <span className="lp-provider">{SUSHITokenMeta.icon}</span>
      {ETHTokenMeta.icon}
    </div>
  ),
};

export function useFloatEthSLPContract(): LPToken {
  const floatEthLPPhase4PoolAddress =
    getDeployment().contracts.FloatEthLPPhase4Pool?.address;
  return useLPToken({
    tokenMeta: floatSLPTokenMeta,
    allowanceAddresses: [
      getDeployment().contracts.sLPPhase2Pool.address,
      ...(floatEthLPPhase4PoolAddress ? [floatEthLPPhase4PoolAddress] : []),
      getDeployment().contracts.FloatEthLP_MultiplierPool.address,
    ],
  });
}
