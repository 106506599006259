import React, { PropsWithChildren } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";
import { useReceipt } from "hooks/useReceipt";
import { toast } from "react-toastify";
import { useWallet } from "wallets/wallet";
import { Cases, AuctionData } from "web3/contracts/AuctionHouse";
import GeneralModal from "components/GeneralModal/GeneralModal";
import { MaxUint256 } from "@ethersproject/constants";

import { safeParseUnits } from "web3/utils";
import { mulPrice, amountOut, amountIn } from "web3/prices";

export enum StabilisationCase {
  Up = "Up",
  Restock = "Re-stock",
  Confidence = "Confidence",
  Down = "Down",
}

export enum TradeChoice {
  WETH,
  BANK,
  FLOAT,
}

export enum Target {
  WETH,
  BANK,
  FLOAT,
}

interface Auction {
  stabilisationCase?: Cases;
  latestAuction?: AuctionData;
  isExpansion: boolean;
  step: number;
  currentPrice: {
    eth?: BigNumber;
    bank?: BigNumber;
    ethInUsd?: BigNumber;
    bankInUsd?: BigNumber;
    inUsd?: BigNumber;
    inEth?: BigNumber;
  };

  floatMarketPriceInEth?: BigNumber;
  floatMarketPriceInUsd?: BigNumber;
  floatTargetPriceInEth?: BigNumber;
  floatTargetPriceInUsd?: BigNumber;
  floatTwapPriceInEth?: BigNumber;
  floatTwapPriceInUsd?: BigNumber;

  // Ordering
  target: Target;

  // Amounts
  inputAmount: string;
  wethAmount?: BigNumber;
  floatAmount?: BigNumber;
  bankAmount?: BigNumber;
  handleTypeInput(target: Target, value: string): void;

  // Controllers
  starting: boolean;
  startAuction: VoidFunction;
  buying: boolean;
  buy: VoidFunction;
  selling: boolean;
  sell: VoidFunction;
  approving: {
    bank: boolean;
    float: boolean;
    weth: boolean;
  };
  approve(tokenName: string): void;
  wrapping: boolean;
  wrap: VoidFunction;
}

const AuctionContext = React.createContext<Auction>({} as any);

export function useAuctionContext(): Auction {
  return React.useContext(AuctionContext);
}

const AuctionProvider: React.FC = ({
  children,
}: PropsWithChildren<unknown>) => {
  // CONTRACTS //
  const { account } = useWallet();
  const {
    blockNumber,
    weth,
    float,
    bank,
    monetary,
    ethUsdOracle,
    floatEthSLP,
    bankEthSLP,
    auctionHouse,
  } = useWeb3Contracts();
  const tokens = {
    weth,
    float,
    bank,
  };

  const {
    wethPrice: priceInEth,
    bankPrice: priceInBank,
    latestAuction,
  } = auctionHouse;

  const { priceInToken0: floatMarketPriceInEth } = floatEthSLP;
  const { priceInToken0: bankPriceInEth } = bankEthSLP;
  const { ethPriceInUsd, reload: reloadEthUsd } = ethUsdOracle;
  const bankPriceInUsd = mulPrice(bankPriceInEth, ethPriceInUsd);
  const floatMarketPriceInUsd = mulPrice(floatMarketPriceInEth, ethPriceInUsd);
  const floatTargetPriceInEth = latestAuction?.targetFloatInEth;
  const floatTargetPriceInUsd = monetary?.targetPriceInUsd;
  const floatTwapPriceInEth = latestAuction?.marketFloatInEth;
  const floatTwapPriceInUsd = mulPrice(floatTwapPriceInEth, ethPriceInUsd);

  const currentEthPriceInUsd = mulPrice(priceInEth, ethPriceInUsd);
  const currentBankPriceInUsd = mulPrice(priceInBank, bankPriceInUsd);

  const currentPriceInEth = priceInEth?.add(
    mulPrice(priceInBank, bankPriceInEth) ?? BigNumber.from(0)
  );
  const currentPriceInUsd = currentEthPriceInUsd?.add(
    currentBankPriceInUsd ?? BigNumber.from(0)
  );

  // STATE //

  // Currency State
  const [target, setTarget] = React.useState(Target.WETH);

  // Amounts
  const [inputAmount, setInputAmount] = React.useState<string>("");
  const [bankAmount, setBankAmount] = React.useState<BigNumber>();
  const [wethAmount, setWethAmount] = React.useState<BigNumber>();
  const [floatAmount, setFloatAmount] = React.useState<BigNumber>();

  // Modal Controllers
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);

  // Status Controllers
  const [starting, setStarting] = React.useState(false);
  const [buying, setBuying] = React.useState(false);
  const [selling, setSelling] = React.useState(false);
  const [wrapping, setWrapping] = React.useState(false);
  const [approving, setApproving] = React.useState({
    bank: false,
    weth: false,
    float: false,
  });

  // Error Controller
  const [error, setError] = React.useState("");

  // Handle input
  const handleTypeInput = (target: Target, value: string) => {
    setTarget(target);
    setInputAmount(value.toString());
  };

  // Update From Amount
  React.useEffect(() => {
    // WETH target => BANK, FLOAT UPDATE
    if (target === Target.WETH) {
      const wethAmount: BigNumber | undefined = safeParseUnits(
        inputAmount,
        weth.tokenMeta.decimals
      );

      setWethAmount(wethAmount);

      if (!wethAmount || !priceInEth) {
        return;
      }

      const floatForWeth = amountIn(wethAmount, priceInEth);
      setFloatAmount(floatForWeth);

      setBankAmount(amountOut(floatForWeth, priceInBank));
    }

    if (target === Target.BANK) {
      const bankAmount: BigNumber | undefined = safeParseUnits(
        inputAmount,
        bank.tokenMeta.decimals
      );
      setBankAmount(bankAmount);

      if (!bankAmount || !priceInEth || !priceInBank) {
        return;
      }

      const floatForBank = amountIn(bankAmount, priceInBank);
      setFloatAmount(floatForBank);

      setWethAmount(amountOut(floatForBank, priceInEth));
    }

    if (target === Target.FLOAT) {
      const amountTo: BigNumber | undefined = safeParseUnits(
        inputAmount,
        float.tokenMeta.decimals
      );

      setFloatAmount(amountTo);

      if (!amountTo || !priceInEth || !priceInBank) {
        return;
      }

      const wethForFloat = amountOut(amountTo, priceInEth);
      const bankForFloat = amountOut(amountTo, priceInBank);
      setWethAmount(wethForFloat);
      setBankAmount(bankForFloat);
    }
  }, [
    weth.tokenMeta.decimals,
    bank.tokenMeta.decimals,
    float.tokenMeta.decimals,
    inputAmount,
    target,
    priceInEth,
    priceInBank,
  ]);

  React.useEffect(() => {
    auctionHouse.onNewBlock();
    reloadEthUsd();
  }, [blockNumber]); // eslint-disable-line

  // useReceipts
  useReceipt(
    (receipt) =>
      toast.success(
        `${bank.tokenMeta.name} approved #${receipt.transactionHash}`
      ),
    bank.contract,
    "approve"
  );

  useReceipt(
    (receipt) =>
      toast.success(
        `${float.tokenMeta.name} approved #${receipt.transactionHash}`
      ),
    float.contract,
    "approve"
  );

  useReceipt(
    (receipt) =>
      toast.success(
        `${weth.tokenMeta.name} approved #${receipt.transactionHash}`
      ),
    weth.contract,
    "approve"
  );

  useReceipt(
    (receipt) =>
      toast.success(`Auction House started #${receipt.transactionHash}`),
    auctionHouse.contract,
    "start"
  );

  const startAuction = async () => {
    if (!account) {
      setError(`Can't start without an account connected`);
      setErrorModalOpen(true);
      console.error("start", "start without account");
      return;
    }

    setStarting(true);
    try {
      await auctionHouse.start();
      auctionHouse.reload();
    } catch (err) {
      setError(`Start failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("start.start.err", err);
    }
    setStarting(false);
  };

  const buy = async () => {
    if (!account) {
      setError(`Can't start without an account connected`);
      setErrorModalOpen(true);
      console.error("buy.account", account);
      return;
    }

    if (!wethAmount || !bankAmount || !floatAmount) {
      setError(`Can't buy with an undefined amount`);
      setErrorModalOpen(true);
      console.error("buy.undef", wethAmount, bankAmount, floatAmount);
      return;
    }

    setBuying(true);
    try {
      await auctionHouse.buy(wethAmount, bankAmount, floatAmount);
      weth.reload();
      bank.reload();
      float.reload();
    } catch (err) {
      setError(`Start failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("buy.buy.err", err);
    }
    setBuying(false);
  };

  const sell = async () => {
    if (!account) {
      setError(`Can't start without an account connected`);
      setErrorModalOpen(true);
      console.error("sell.account", account);
      return;
    }

    if (!wethAmount || !bankAmount || !floatAmount) {
      setError(`Can't sell with an undefined amount`);
      setErrorModalOpen(true);
      console.error("sell.undef", wethAmount, bankAmount, floatAmount);
      return;
    }

    setSelling(true);
    try {
      await auctionHouse.sell(floatAmount, wethAmount, bankAmount);
      weth.reload();
      bank.reload();
      float.reload();
    } catch (err) {
      setError(`Start failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("sell.sell.err", err);
    }
    setSelling(false);
  };

  const approve = async (tokenName: keyof typeof tokens) => {
    setApproving((prev) => ({
      ...prev,
      [tokenName]: true,
    }));
    try {
      await tokens[tokenName].approveSend(
        auctionHouse.contract.address,
        MaxUint256
      );
      tokens[tokenName].reload();
    } catch (err) {
      setError(`Approve failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("auctionhouse.approve.err", err);
    }
    setApproving((prev) => ({
      ...prev,
      [tokenName]: false,
    }));
  };

  const wrap = async () => {
    setWrapping(true);

    if (!wethAmount) {
      return;
    }

    try {
      await weth.wrap(wethAmount.sub(weth?.balance ?? 0));
    } catch (err) {
      setError(`Wrap failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("auctionhouse.wrap.err", err);
    }
    setWrapping(false);
  };

  const stabilisationCase = auctionHouse.latestAuction?.stabilisationCase;

  const value = {
    stabilisationCase,
    latestAuction,
    isExpansion:
      stabilisationCase !== undefined &&
      (stabilisationCase === Cases.Up || stabilisationCase === Cases.Restock),
    step: auctionHouse.step ?? 0,
    currentPrice: {
      eth: priceInEth,
      bank: priceInBank,
      ethInUsd: currentEthPriceInUsd,
      bankInUsd: currentBankPriceInUsd,
      inUsd: currentPriceInUsd,
      inEth: currentPriceInEth,
    },
    floatMarketPriceInEth,
    floatMarketPriceInUsd,
    floatTargetPriceInUsd,
    floatTargetPriceInEth,
    floatTwapPriceInEth,
    floatTwapPriceInUsd,
    target,
    handleTypeInput,
    inputAmount,
    wethAmount,
    floatAmount,
    bankAmount,
    starting,
    startAuction,
    buying,
    buy,
    selling,
    sell,
    approving,
    approve,
    wrapping,
    wrap,
  };

  return (
    <AuctionContext.Provider value={value}>
      <GeneralModal
        className="error-modal"
        isOpen={errorModalOpen}
        onCancel={() => setErrorModalOpen(false)}
        modalTitle={"Here's the issue."}
        modalBody={error}
      />
      {children}
    </AuctionContext.Provider>
  );
};

export default AuctionProvider;
