import { ChevronDown } from "react-feather";

import PriceInput from "components/PriceInput/PriceInput";
import { formatUnits } from "web3/utils";
import { TokenContractWithAllowance } from "web3/contracts/StakeTokens";

import "./PriceInputPanel.css";
import React, { ReactElement } from "react";

interface PriceInputPanelProps {
  label: string;
  value: string;
  token: TokenContractWithAllowance;

  customAction?: React.ReactNode;
  disabled?: boolean;
  error?: boolean;

  onUserInput?: (value: string) => void;
  onMax?: () => void;
  selectCurrency?: () => void;
}

const PriceInputPanel = ({
  label,
  value,
  token,
  error,
  disabled,
  customAction,
  onMax,
  onUserInput,
  selectCurrency,
}: PriceInputPanelProps): ReactElement => {
  const { balance, tokenMeta } = token;
  return (
    <div className="input-panel">
      <div className="label-row">
        {label}{" "}
        <span>
          {balance && !customAction && (
            <>Balance: {formatUnits(balance, tokenMeta.decimals, 6)} </>
          )}
          {customAction}
          {onMax && (
            <button className="button-link" onClick={onMax}>
              (Max)
            </button>
          )}
          {/* {additionalLabel} */}
        </span>
      </div>
      <div className="input-row">
        <PriceInput
          value={value}
          onUserInput={onUserInput}
          disabled={disabled}
          error={error}
        />
        {selectCurrency ? (
          <div className="currency-label" onClick={selectCurrency}>
            {token.tokenMeta.icon}
            <span className="token-name">{tokenMeta.name}</span>
            <ChevronDown />
          </div>
        ) : (
          <div className="currency-label -no-options">
            {token.tokenMeta.icon}
            <span className="token-name">{tokenMeta.name}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceInputPanel;
