import { useLocalStorage } from "./useLocalStorage";
import { TransactionTypes } from "@ethersproject/transactions";
import React from "react";

const TRANSACTION_TYPE_KEY = "USER_TXN_TYPE";

export enum SendableTransactionType {
  LEGACY = "0x0",
  EIP2930 = "0x1",
  EIP1559 = "0x2",
}

const MAP_TO_SENDABLE = {
  0: SendableTransactionType.LEGACY,
  1: SendableTransactionType.EIP2930,
  2: SendableTransactionType.EIP1559,
};

export type TransactionTypeReturn = [SendableTransactionType, React.Dispatch<React.SetStateAction<SendableTransactionType>>]

export function useTransactionType(): TransactionTypeReturn {
  const [txnType, setTxnType] = useLocalStorage<TransactionTypes>(TRANSACTION_TYPE_KEY, TransactionTypes.eip1559);

  const sendableTxnType = MAP_TO_SENDABLE[txnType];

  return [sendableTxnType, (txnType) => {
    console.log("Setting txn type", txnType);
    return setTxnType(Number(txnType));
  }];
}