import React from "react";

import "./BasicAuctionStat.css";

interface BasicAuctionStatProps {
  title?: React.ReactNode;
  content?: React.ReactNode;
  footnote?: React.ReactNode;
  gradientContent?: boolean;
  purpleContent?: boolean;
}

export const BasicAuctionStat: React.FC<BasicAuctionStatProps> = ({
  title,
  content,
  footnote,
  gradientContent,
  purpleContent,
}: BasicAuctionStatProps) => {
  return (
    <div className="auction-stat-container">
      <span className="auction-stat-title">{title}</span>

      <span
        className={`auction-stat-content 
                ${gradientContent ? "gradient" : ""} 
                ${purpleContent ? "purple" : ""}`}
      >
        {content}
      </span>

      {footnote && <span className="auction-stat-footnote">{footnote}</span>}
    </div>
  );
};

export default BasicAuctionStat;
