import React, { useEffect } from "react";
import { AbiItem } from "web3-utils";
import { useReload } from "hooks/useReload";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useWallet } from "wallets/wallet";
import Web3Contract from "web3/Web3Contract";
import { usePreferences } from "contexts/PreferencesContext";

import { BigNumber } from "@ethersproject/bignumber";
import {
  getDeployment,
  getStorageLocationOfMapping,
  now,
  percentageFromBigNumbers,
} from "web3/utils";
import { PoolType, Pool, PoolData } from "./Pool";
import { Web3Provider } from "@ethersproject/providers";

export interface LockInPoolData extends PoolData {
  // Quanity in limbo
  inLimbo: BigNumber | undefined;
  unlockStarted: number | undefined;
}

const initialData: LockInPoolData = {
  poolType: PoolType.FOUR,
  periodFinish: undefined,
  rewardPerToken: undefined,
  totalStaked: undefined,
  earned: undefined,
  totalReward: undefined,
  duration: undefined,
  maximumContribution: undefined,
  staked: undefined,
  proportionOfPool: "-",
  inLimbo: undefined,
  unlockStarted: undefined,
};

// Forgetting a public modifier is painful, would not recommend.
// mapping(address => uint256) private _unlocks
// @ 14 in Phase4a
// @ 12 in Phase4
const SLOT_NUMBER_FOR_UNLOCKS_IN_PHASE4A = 14;
const SLOT_NUMBER_FOR_UNLOCKS_IN_PHASE4 = 12;

interface UsePhase4PoolProps {
  abi: AbiItem[];
  addr: string;
  name: string;
  poolType?: PoolType;
  slot?: number;
}

export type Phase4Pool = Pool<LockInPoolData> & {
  unlock(): Promise<void>;
};

function usePhase4Pool({
  abi,
  addr,
  name,
  poolType: phase = PoolType.FOUR,
  slot = SLOT_NUMBER_FOR_UNLOCKS_IN_PHASE4,
}: UsePhase4PoolProps): Phase4Pool {
  const [reload] = useReload();
  const wallet = useWallet();
  const { txn } = usePreferences();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  const [data, setData] = React.useState<LockInPoolData>({
    ...initialData,
    poolType: phase,
  });

  useAsyncEffect(async () => {
    const [
      totalReward,
      periodFinish,
      rewardPerToken,
      totalStaked,
      duration,
      maximumContribution,
      inLimbo,
    ] = await contract.batch([
      {
        method: "getRewardForDuration",
        transform: (value: string) => BigNumber.from(value),
      },
      {
        method: "periodFinish",
        transform: (value: string) => Number(value),
      },
      {
        method: "rewardPerToken",
        transform: (value: string) => Number(value),
      },
      {
        method: "totalSupply",
        transform: (value: string) => BigNumber.from(value),
      },
      {
        method: "duration",
        transform: (value: string) => Number(value),
      },
      {
        method: "maximumContribution",
        transform: (value: string) => BigNumber.from(value),
      },
      {
        method: "inLimbo",
        transform: (value: string) => BigNumber.from(value),
      },
    ]);

    setData((prevState) => ({
      ...prevState,
      periodFinish,
      rewardPerToken,
      totalStaked,
      totalReward: periodFinish >= now() ? totalReward : BigNumber.from(0),
      duration,
      maximumContribution,
      inLimbo,
    }));
  }, [reload]);

  useAsyncEffect(async () => {
    let unlockStarted: number | undefined;
    if (wallet.account && wallet.provider) {
      unlockStarted = BigNumber.from(
        await new Web3Provider(wallet.provider).getStorageAt(
          addr,
          getStorageLocationOfMapping(slot, wallet.account)
        )
      ).toNumber();
    }
    setData((prevState) => ({
      ...prevState,
      unlockStarted,
    }));
  }, [wallet.account, wallet.provider, reload]);

  useAsyncEffect(async () => {
    let earned: BigNumber | undefined;
    let staked: BigNumber | undefined;
    let inLimbo: BigNumber | undefined;

    if (wallet.account) {
      [earned, staked, inLimbo] = await contract.batch([
        {
          method: "earned",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
        {
          method: "balanceOf",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
        {
          method: "inLimbo",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
      ]);
    }

    setData((prevState) => ({
      ...prevState,
      earned,
      staked,
      inLimbo,
    }));
  }, [reload, wallet.account]);

  useEffect(() => {
    const proportionOfPool = percentageFromBigNumbers(
      data.staked,
      data.totalStaked
    );

    setData((prevState) => ({
      ...prevState,
      proportionOfPool,
    }));
  }, [data.staked, data.totalStaked]);

  const stake = React.useCallback(
    (amount) => {
      if (!wallet.account) {
        return Promise.reject();
      }
      return contract
        .send("stake", [amount], {
          from: wallet.account,
          type: txn.type,
        })
        .then(reload);
    },
    [wallet.account, contract, txn.type, reload]
  );

  const withdraw = React.useCallback(
    (amount) => {
      if (!wallet.account) {
        return Promise.reject();
      }
      return contract
        .send("withdraw", [BigNumber.from(amount)], {
          from: wallet.account,
          type: txn.type,
        })
        .then(reload);
    },
    [contract, reload, txn.type, wallet.account]
  );

  const unlock = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject("No account");
    }

    return contract
      .send("unlock", [], {
        from: wallet.account,
        type: txn.type,
      })
      .then(reload);
  }, [wallet.account, contract, txn.type, reload]);

  const claim = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject();
    }
    return contract
      .send("getReward", [], {
        from: wallet.account,
        type: txn.type,
      })
      .then(reload);
  }, [wallet.account, contract, txn.type, reload]);

  const exit = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject();
    }
    return contract
      .send("exit", [], {
        from: wallet.account,
        type: txn.type,
      })
      .then(reload);
  }, [wallet.account, contract, txn.type, reload]);

  return React.useMemo<Phase4Pool>(
    () => ({
      ...data,
      contract,
      reload,
      stake,
      withdraw,
      claim,
      exit,
      unlock,
    }),
    [data, contract, reload, stake, withdraw, claim, exit, unlock]
  );
}

export type Phase4aPool = Phase4Pool & {
  startWindow?: number;
  endWindow?: number;
};
export function usePhase4aPool(params: UsePhase4PoolProps): Phase4aPool {
  const phase4Pool = usePhase4Pool({
    ...params,
    poolType: PoolType.GENESIS,
    slot: SLOT_NUMBER_FOR_UNLOCKS_IN_PHASE4A,
  });

  const [startWindow, setStartWindow] = React.useState<number>();
  const [endWindow, setEndWindow] = React.useState<number>();

  useAsyncEffect(async () => {
    const [startWindow, endWindow] = await phase4Pool.contract.batch([
      {
        method: "startWindow",
        transform: (value: string) => Number(value),
      },
      {
        method: "endWindow",
        transform: (value: string) => Number(value),
      },
    ]);

    setStartWindow(startWindow);
    setEndWindow(endWindow);
  }, []);

  return React.useMemo<Phase4aPool>(
    () => ({
      ...phase4Pool,
      startWindow,
      endWindow,
    }),
    [phase4Pool, startWindow, endWindow]
  );
}

export function useBankEthSLPPhase4Pool(): Phase4Pool {
  return usePhase4Pool({
    abi: getDeployment().contracts.BankEthLPPhase4Pool.abi,
    addr: getDeployment().contracts.BankEthLPPhase4Pool.address,
    name: "BANK_ETH_SLP_PHASE4_POOL",
  });
}

export function useFloatEthSLPPhase4Pool(): Phase4Pool {
  return usePhase4Pool({
    abi: getDeployment().contracts.FloatEthLPPhase4Pool.abi,
    addr: getDeployment().contracts.FloatEthLPPhase4Pool.address,
    name: "FLOAT_ETH_SLP_PHASE4_POOL",
  });
}

export function useFloatPhase4Pool(): Phase4Pool {
  return usePhase4Pool({
    abi: getDeployment().contracts.FLOATPhase4Pool.abi,
    addr: getDeployment().contracts.FLOATPhase4Pool.address,
    name: "FLOAT_PHASE4_POOL",
  });
}

export function useBANKPhase4aPool(): Phase4aPool {
  return usePhase4aPool({
    abi: getDeployment().contracts.BANKPhase4aPool.abi,
    addr: getDeployment().contracts.BANKPhase4aPool.address,
    name: "BANK_PHASE4A_POOL",
  });
}
