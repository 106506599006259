import React, { ReactElement } from "react";
import Modal from "react-modal";
import ConfirmationForm from "components/ConfirmationForm/ConfirmationForm";
import { useStakeOption } from "contexts/StakeOption";

import CloseIcon from "assets/modal/close.png";
import "../GeneralModal/GeneralModal.css";
import { useTransactionHash } from "hooks/useTransactionHash";
import { useReceipt } from "hooks/useReceipt";
import { TransactionBlock } from "components/GeneralModal/GeneralModal.TransactionBlock";
import { toast } from "react-toastify";
import { formatUnits } from "web3/utils";

Modal.setAppElement("#root");

interface RequestWithdrawModalProps {
  isOpen: boolean;
  onCancel: VoidFunction;
  modalBody?: React.ReactNode;
}

function RequestWithdrawModal({
  isOpen,
  onCancel,
  modalBody,
}: RequestWithdrawModalProps): ReactElement {
  const { pool, token, requestWithdrawing, requestWithdraw } = useStakeOption(); // TODO: chage stakeOPtions
  const { staked } = pool;
  const { name, icon } = token.tokenMeta;

  const stakedFormatted = formatUnits(staked, token.tokenMeta.decimals);

  // Change transactional hash stuff to match request to withdraw tx/ Unlock
  const [txn, setTxn] = React.useState<string>();

  useTransactionHash(setTxn, pool.contract, "unlock");
  useReceipt(
    (receipt) => {
      setTxn("");
      toast.success(`${name} Request confirmed #${receipt.transactionHash}`);
    },
    pool.contract,
    "unlock"
  );

  return (
    <Modal
      className="general-modal"
      overlayClassName="general-overlay"
      isOpen={isOpen}
      onRequestClose={onCancel}
    >
      <div className="general-modal-container">
        <input
          className="close-modal-button"
          type="image"
          src={CloseIcon}
          alt="Close window"
          onClick={onCancel}
        />

        <div className="modal-header with-icon">
          {icon}
          <div className="title-wrapper">
            <p className="modal-title">Request To Withdraw {name}</p>
          </div>
          <div className="smallColumnDivider"></div>

          <div className="modal-key-info">
            <p>My Current Stake: </p>
            <p className="modal-numeric-value">
              {stakedFormatted} {name}
            </p>
          </div>
        </div>

        <div className="modal-body">
          <p>
            Are you sure? You will be disabling rewards for the next 8 days by
            requesting to withdraw. {modalBody}
          </p>
        </div>

        <TransactionBlock txn={txn} onCancel={onCancel}>
          <ConfirmationForm
            loading={requestWithdrawing}
            onSubmit={requestWithdraw}
            onCancel={onCancel}
          />
        </TransactionBlock>
      </div>
    </Modal>
  );
}

export default RequestWithdrawModal;
