import React from "react";
import PhaseInfo from "components/PhaseInfo/PhaseInfo";
import StakeOptionProvider from "contexts/StakeOption";
import FarmPanel from "components/FarmPanel/FarmPanel";
import { useWhitelist } from "contexts/WhitelistContext";
import { useWallet } from "wallets/wallet";
import ExternalLink from "components/ExternalLink/ExternalLink";

import { useWeb3Contracts } from "contexts/Web3ContractsContext";
import { StatPanel } from "components/StatPanel/StatPanel";
import GenesisStakePanel from "components/GenesisStakePanel/GenesisStakePanel";

const BANKPhase4Panel = () => {
  const { bank, bankPhase4aPool } = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={bank}
      pool={bankPhase4aPool}
      options={{
        displayName: "Genesis BANK",
        description: (
          <>
            <p>To purchase FLOAT, you must stake BANK during Genesis.</p>
            <p>
              Stake{" "}
              <ExternalLink
                href={`https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=${bank.contract.address}`}
              >
                BANK
              </ExternalLink>{" "}
              to earn BANK tokens.
            </p>
          </>
        ),
        subText: (
          <>
            {" "}
            <p>
              If you request to withdraw from the BANK-Only pool, your stake is
              locked for 8 days and you will no longer receive rewards during
              this period.
            </p>
          </>
        ),
      }}
    >
      <GenesisStakePanel showAPR />
    </StakeOptionProvider>
  );
};

// PHASE 1 PANELS
const DAIPhase1Panel = () => {
  const { dai, daiPool, daiPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={dai}
      pool={daiPool}
      options={{
        isPhase1: true,
        newPool: daiPhase2Pool,
      }}
    >
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const USDTPhase1Panel = () => {
  const { usdt, usdtPool, usdtPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={usdt}
      pool={usdtPool}
      options={{
        isPhase1: true,
        newPool: usdtPhase2Pool,
      }}
    >
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const USDCPhase1Panel = () => {
  const { usdc, usdcPool, usdcPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={usdc}
      pool={usdcPool}
      options={{
        isPhase1: true,
        newPool: usdcPhase2Pool,
      }}
    >
      <FarmPanel />
    </StakeOptionProvider>
  );
};

// PHASE 2 Panels
const SLPPhase2Panel = () => {
  const {
    bankEthSLP: slp,
    bankEthSLPPhase2Pool: slpPhase2Pool,
  } = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={slp}
      pool={slpPhase2Pool}
      options={{
        displayName: "BANK/ETH sLP",
        description: (
          <>
            <p>
              Add BANK + ETH liquidity in{" "}
              <ExternalLink href="https://app.sushi.com/add/ETH/0x24A6A37576377F63f194Caa5F518a60f45b42921">
                Sushiswap
              </ExternalLink>{" "}
              for sLP.
            </p>
            <p>Stake sLP to earn BANK tokens.</p>
          </>
        ),
      }}
    >
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const ETHPhase2Panel = () => {
  const { eth, ethPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={eth}
      pool={ethPhase2Pool}
      options={{
        expired: true,
      }}
    >
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const DAIPhase2Panel = () => {
  const { dai, daiPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider token={dai} pool={daiPhase2Pool}>
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const USDTPhase2Panel = () => {
  const { usdt, usdtPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider token={usdt} pool={usdtPhase2Pool}>
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const USDCPhase2Panel = () => {
  const { usdc, usdcPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider token={usdc} pool={usdcPhase2Pool}>
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const YFIPhase2Panel = () => {
  const { yfi, yfiPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider token={yfi} pool={yfiPhase2Pool}>
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const YAMPhase2Panel = () => {
  const { yam, yamPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider token={yam} pool={yamPhase2Pool}>
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const SUSHIPhase2Panel = () => {
  const { sushi, sushiPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider token={sushi} pool={sushiPhase2Pool}>
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const WBTCPhase2Panel = () => {
  const { wbtc, wbtcPhase2Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider token={wbtc} pool={wbtcPhase2Pool}>
      <FarmPanel />
    </StakeOptionProvider>
  );
};

const WhitelistedTitle = () => {
  const { account } = useWallet();
  const { tree } = useWhitelist();
  const icon = account && tree?.hasProof(account) ? "✓" : "✗";
  return (
    <>
      <h4>Phase 1 (over)</h4>
      <span className="whitelist-badge">{icon} Whitelisted</span>
    </>
  );
};

// Phase 4 Panels

const Phase4SubText = ({
  displayName,
  extra,
}: {
  displayName: string;
  extra?: React.ReactNode;
}) => (
  <>
    <p>
      If you request to withdraw from the {displayName} pool, your <b>entire</b>{" "}
      stake is locked for 8 days and you will no longer receive rewards during
      this period.
      {extra}
    </p>
  </>
);

const BANKETHSLPPhase4Panel = () => {
  const { bank, bankEthSLP, bankEthSLPPhase4Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={bankEthSLP}
      pool={bankEthSLPPhase4Pool}
      options={{
        displayName: "BANK/ETH sLP",
        description: (
          <>
            <p>
              Add BANK + ETH liquidity in{" "}
              <ExternalLink
                href={`https://app.sushi.com/add/ETH/${bank.contract.address}`}
              >
                Sushiswap
              </ExternalLink>{" "}
              for sLP.
            </p>
            <p>Stake sLP to earn BANK tokens.</p>
          </>
        ),
        subText: <Phase4SubText displayName="BANK/ETH sLP" />,
      }}
    >
      <GenesisStakePanel showAPR />
    </StakeOptionProvider>
  );
};

const FLOATETHSLPPhase4Panel = () => {
  const { float, floatEthSLP, floatEthSLPPhase4Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={floatEthSLP}
      pool={floatEthSLPPhase4Pool}
      options={{
        displayName: "FLOAT/ETH sLP",
        description: (
          <>
            <p>
              Add FLOAT + ETH liquidity in{" "}
              <ExternalLink
                href={`https://app.sushi.com/add/ETH/${float.contract.address}`}
              >
                Sushiswap
              </ExternalLink>{" "}
              for sLP.
            </p>
            <p>Stake sLP to earn BANK tokens.</p>
          </>
        ),
        subText: <Phase4SubText displayName="FLOAT/ETH sLP" />,
      }}
    >
      <GenesisStakePanel showAPR />
    </StakeOptionProvider>
  );
};

const FLOATPhase4Panel = () => {
  const { float, floatPhase4Pool } = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={float}
      pool={floatPhase4Pool}
      options={{
        subText: <Phase4SubText displayName="FLOAT" />,
      }}
    >
      <GenesisStakePanel showAPR />
    </StakeOptionProvider>
  );
};

const ExpiredPoolsView: React.FC = () => {
  return (
    <>
      <StatPanel />
      <PhaseInfo title={<h4>Phase 4</h4>}>
        <p>
          New Multiplier-based Pools (BANK, FLOAT/USDC, FLOAT/ETH, BANK/ETH) are
          on their way according to{" "}
          <ExternalLink href="https://snapshot.org/#/snapshot.floatprotocol.eth/">
            {" "}
            governance vote
          </ExternalLink>
        </p>
        <p>
          As we are transitioning to new pools, the 8 day lock as been removed
          from 00:00 UTC 19th July 2021. You may now exit / withdraw from any of
          these pools as desired.
        </p>
      </PhaseInfo>
      <BANKPhase4Panel />
      <FLOATETHSLPPhase4Panel />
      <FLOATPhase4Panel />
      <BANKETHSLPPhase4Panel />
      <PhaseInfo title={<h4>Phase 3 (OVER)</h4>}>
        <p>
          Phase 3 will last 6 weeks, starting Sunday 28th of March at 10pm UTC
          and finishing on the 9th of May at 10pm UTC. The goal of Phase 3 is to
          incentivise a fair distribution of BANK and allow liquidity to enter
          the system.
        </p>
        <p>
          Unlike Phase 1, anyone can participate in Phase 3 and there are no
          deposit limits.
        </p>
        <p>
          Phase 3 will only contain the incentivised BANK/ETH sLP pool from
          Phase 2. 10,500 BANK are to be distributed over the 6 weeks of Phase 3
          via the following distribution schedule:
        </p>
        <ul>
          <li>Week 1 (commencing Sunday 28th March) - 3000 BANK</li>
          <li>Week 2 (commencing Sunday 4th April) - 2500 BANK</li>
          <li>Week 3 (commencing Sunday 11th April) - 2000 BANK</li>
          <li>Week 4 (commencing Sunday 18th April) - 1500 BANK</li>
          <li>Week 5 (commencing Sunday 25th April) - 1000 BANK</li>
          <li>Week 6 (commencing Sunday 2nd May) - 500 BANK</li>
        </ul>
        <p>
          The move to Phase 3 was decided by a{" "}
          <ExternalLink href="https://scattershot.page/#/snapshot.floatprotocol.eth/proposal/QmUyLoX5M96FeV1Axjp99AXFgJXCXGPEMwyG4u45VG7x8H">
            governance vote
          </ExternalLink>{" "}
          on Scattershot.
        </p>
        <p>
          You do not need to re-stake in Phase 3 if you already staked in the
          Phase 2 BANK/ETH sLP pool (as it is the same pool/contract).
        </p>
      </PhaseInfo>
      <SLPPhase2Panel />

      <PhaseInfo title={<h4>Phase 2 (OVER)</h4>}>
        <p>
          Phase 2 ended one week early on Sunday the 28th of March at 10pm UTC
          by{" "}
          <ExternalLink href="https://scattershot.page/#/snapshot.floatprotocol.eth/proposal/QmUyLoX5M96FeV1Axjp99AXFgJXCXGPEMwyG4u45VG7x8H">
            governance vote
          </ExternalLink>{" "}
          on Scattershot (our Snapshot fork){" "}
        </p>
        <p>
          The goal of Phase 2 was to offer a wider distribution of the BANK
          token and enable vital liquidity to enter the system.
        </p>
        <p>
          The pools were chosen based on{" "}
          <ExternalLink href="https://scattershot.page/#/snapshot.floatprotocol.eth/proposal/Qmf3rzXtVTvox5QoJ7LEgR7cXAqL2PetuAFGihZXiRNXFp">
            a vote on Scattershot
          </ExternalLink>
        </p>
        <p>In total 10,500.00 BANK was distributed throughout Phase 2.</p>
      </PhaseInfo>

      <SLPPhase2Panel />
      <ETHPhase2Panel />
      <DAIPhase2Panel />
      <USDCPhase2Panel />
      <USDTPhase2Panel />
      <WBTCPhase2Panel />
      <YFIPhase2Panel />
      <SUSHIPhase2Panel />
      <YAMPhase2Panel />

      <PhaseInfo title={<WhitelistedTitle />}>
        <p>
          As a reminder, Phase 1 ended on Sunday 21st March at 10pm UTC. The
          goal of the Phase 1 was to distribute BANK tokens to a wide range of
          smaller, very active participants as part of our{" "}
          <ExternalLink href="https://medium.com/float-protocol/announcing-float-protocol-and-its-democratic-launch-d1c27bc21230">
            democratic launch
          </ExternalLink>
        </p>
        <p>
          To those who participated, thank you. You are key members of the Float
          Protocol community.
        </p>
        <p>
          If you wish, you may restake into the Phase 2 pools via the user
          interface below. Your BANK rewards will be automatically claimed if
          you withdraw your maximum stake in each pool.
        </p>
      </PhaseInfo>

      <DAIPhase1Panel />
      <USDCPhase1Panel />
      <USDTPhase1Panel />
    </>
  );
};

export default ExpiredPoolsView;
