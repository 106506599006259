import React, { ReactElement } from "react";

import { ExternalIcon } from "components/ExternalLink/ExternalLink";

import "./FarmPanel.css";
import { useStakeOption } from "contexts/StakeOption";
import { etherscanAddress, formatPrefix, formatUnits } from "web3/utils";
import BankEarnedCard from "components/BankEarnedCard/BankEarnedCard";
import FarmStatementCard from "components/FarmStatementCard/FarmStatementCard";
import { BANKTokenMeta } from "web3/contracts/TokenContractWithSupply";
import { mulPrice, scaleUnit, toPrice } from "web3/prices";

function FarmPanel(): ReactElement {
  const { token, pool, options } = useStakeOption();
  const { maximumContribution, totalReward, duration, totalStaked } = pool;
  const { name, decimals } = token.tokenMeta;
  const etherscanLink = etherscanAddress(pool.contract.address);
  const dailyReward = formatPrefix(
    totalReward?.div(duration ? duration / 60 / 60 / 24 : 1),
    BANKTokenMeta.decimals,
    0
  );

  const totalStakedPrefixed = formatPrefix(totalStaked, decimals);
  const tokenPriceInUsd = toPrice(token.priceInUSD ?? 0);
  const totalStakedDollar = formatPrefix(
    mulPrice(scaleUnit(totalStaked, decimals), tokenPriceInUsd)
  );

  const maximumContributionFormatted = formatUnits(
    maximumContribution,
    decimals
  );

  return (
    <div className="farm-panel-container">
      <div className="farm-panel-header">
        <div className="farm-panel-instructions">
          <h2>
            {options?.displayName ?? name}
            <ExternalIcon href={etherscanLink} />
          </h2>
          {options?.description ?? <p>Deposit {name} to earn BANK tokens</p>}
          {maximumContribution && (
            <p className="farm-subtitle">
              Limit per whitelisted account is {maximumContributionFormatted}{" "}
              {name}
            </p>
          )}
        </div>

        <div className="smallColumnDivider"></div>

        <div className="farm-deposit-stats">
          <p className="farm-secondary-title">Total Deposited</p>
          <p className="farm-stat">{totalStakedPrefixed}</p>
          {totalStaked && (
            <p className="farm-subtitle">
              {totalStakedDollar ? `$${totalStakedDollar}` : "-"}
            </p>
          )}
        </div>

        <div className="smallColumnDivider"></div>

        <div className="farm-deposit-stats">
          <p className="farm-secondary-title">Daily Distribution</p>
          <p className="farm-stat">{dailyReward}</p>
          <p className="farm-subtitle">BANK per day</p>
        </div>
      </div>

      <div className="farm-panel-body">
        <div className="left-box">
          <BankEarnedCard />
        </div>

        <div className="columnDivider"></div>

        <div className="right-box">
          <FarmStatementCard />
        </div>
      </div>
    </div>
  );
}

export default FarmPanel;
