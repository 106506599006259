/* eslint-disable @typescript-eslint/no-explicit-any */
import Web3 from "web3";
import { Eth } from "web3-eth";
import { Contract } from "web3-eth-contract";
import { AbiItem } from "web3-utils";

import EventEmitter from "wolfy87-eventemitter";

import { getHttpRpcUrl } from "web3/utils";
import { overrideExecuteCall } from "./BatchRequest";

export interface ContractProps {
  abi: AbiItem[];
  addr: string;
  name: string;
}

export type BatchContractMethod = {
  method: string;
  methodArgs?: any[];
  callArgs?: Record<string, any>;
  defaultBlock?: number | string;
  transform?: (value: any) => any;
};

export interface TransactionInfo {
  method: string;
  methodArgs?: unknown[];
  callArgs?: Record<string, unknown>;
}

export const DEFAULT_CONTRACT_PROVIDER = new Web3.providers.HttpProvider(
  getHttpRpcUrl()
);

const WEB3_ERROR_VALUE = 3.9638773911973445e75;
const web3 = new Web3(DEFAULT_CONTRACT_PROVIDER);

class Web3Contract extends EventEmitter {
  readonly abi: AbiItem[];
  readonly address: string;
  readonly name: string;
  readonly ethContract: Contract & Eth;

  constructor(abi: AbiItem[], address: string, name: string) {
    super();

    this.abi = abi;
    this.address = address;
    this.name = name;
    this.ethContract = new web3.eth.Contract(abi, address) as any;
  }

  setProvider(provider: any = DEFAULT_CONTRACT_PROVIDER): void {
    if (this.ethContract.currentProvider !== provider) {
      this.ethContract.setProvider(provider);
    }
  }

  batch(methods: BatchContractMethod[], defaultValue: any = undefined): Promise<any[]> {
    const batch = new web3.BatchRequest();
    overrideExecuteCall(batch);

    const promises = methods.map((method: BatchContractMethod) => {
      return new Promise((resolve) => {
        const {
          method: methodName,
          methodArgs = [],
          callArgs = {},
          defaultBlock = "latest",
          transform = (value: any) => value,
        } = method;

        const contractMethod = this.ethContract.methods[methodName];
        if (!contractMethod) {
          return resolve(defaultValue);
        }

        try {
          const request = contractMethod(...methodArgs).call.request(
            callArgs,
            defaultBlock,
            (err: Error, value: string) => {
              if (err) {
                if (defaultValue === undefined) {
                  console.error(`${this.name}:${methodName}.call`, err);
                }
                return resolve(defaultValue);
              }

              if (+value === WEB3_ERROR_VALUE) {
                console.error(
                  `${this.name}:${methodName}.call`,
                  "Contract call failure!"
                );
                return resolve(defaultValue);
              }

              resolve(transform(value));
            }
          );

          batch.add(request);
        } catch (e) {
          return resolve(defaultValue);
        }
      });
    });

    batch.execute();

    return Promise.all(promises);
  }

  async send(
    method: string,
    methodArgs: any[] = [],
    sendArgs: Record<string, any> = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const contractMethod = this.ethContract.methods[method];

      if (!contractMethod) {
        return resolve(undefined);
      }

      const onError = (err: Error) => {
        this.emit("error", err, this, {
          method,
          methodArgs,
          sendArgs,
        });
        reject(err);
      };

      const onTransactionHash = (transactionHash: string) => {
        this.emit("transactionHash", transactionHash, this, {
          method,
          methodArgs,
          sendArgs,
        });
      };

      const onReceipt = (receipt: Record<string, any>) => {
        this.emit("receipt", receipt, this, {
          method,
          methodArgs,
          sendArgs,
        });
      };

      console.log(method, methodArgs, Object.assign({}, sendArgs), sendArgs);

      contractMethod(...methodArgs)
        ?.send(sendArgs)
        .once("transactionHash", onTransactionHash)
        .once("receipt", onReceipt)
        .on("error", reject)
        .then(resolve)
        .catch(onError);
    });
  }
}

export default Web3Contract;
