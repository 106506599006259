import { useState } from "react";

interface UseInputResult {
  value: string;
  setValue(val: string): void;
  reset(): void;
  bind: {
    value: string,
    onChange(event: unknown): void;
  }
}

export const useInput = (initialValue: string): UseInputResult => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event: { target: { value: string } }) => {
        setValue(event.target.value);
      },
    },
  };
};
