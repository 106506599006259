import React from "react";

import "./SummaryCard.css";

interface SummaryCardProps {
  title: string;
  content?: string;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  title,
  content,
}: SummaryCardProps) => {
  return (
    <div className="summary-card">
      <span className="summary-card-title">{title}</span>
      <span className="summary-card-content">{content}</span>
    </div>
  );
};

export default SummaryCard;
