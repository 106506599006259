import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import BankLogo from "assets/float/bank_thumbprint.svg";
import FloatLogo from "assets/float/float_thumbprint.svg";

import CryptoNative from "assets/landing/crypto_native.svg";
import ExcessDemand from "assets/landing/excess_demand.svg";
import Decentralized from "assets/landing/decentralized.svg";
import Governance from "assets/landing/governance.svg";
import PerfectCollateral from "assets/landing/perfect_collateral.svg";
import PurchasingPower from "assets/landing/purchasing_power.svg";
import SupportPrice from "assets/landing/support_price.svg";

import "./Landing.css";

const slogans = ["stability without a peg", "DeFi Money"];

const Intro = () => {
  const [slogan, setSlogan] = React.useState<string>();

  React.useEffect(() => {
    setSlogan(slogans[Math.floor(slogans.length * Math.random())]);
  }, []);

  return (
    <div className="intro">
      <h1 className="landing-heading">
        <span className="gradient-text">Float Protocol</span>, {slogan}.
      </h1>
      <h2 className="landing-subheading">
        <p>
          Decentralised Finance needs stability, but we can do better than the
          dollar.
        </p>
        <p>
          FLOAT tracks a basket of digital assets instead of trying to exactly
          match the price of a dollar. It is designed to{" "}
          <b>change value over time</b> corresponding to your digital purchasing
          power.
        </p>
      </h2>
      <div className="landing-ctas">
        <motion.div
          className="cta-button-wrapper"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <a
            className="cta-button"
            href="https://docs.floatprotocol.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div>Read the Docs</div>
          </a>
        </motion.div>
        <motion.div
          className="cta-button-wrapper"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Link className="cta-button" to="/pools">
            <div>Stake &amp; Earn</div>
          </Link>
        </motion.div>
        <motion.div
          className="cta-button-wrapper"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Link className="cta-button" to="/auction">
            <div>Head to the Auction</div>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

const Tokens = () => {
  return (
    <div className="section">
      <h2>The Tokens</h2>
      <div className="tokens-cta">
        <div className="token-cta">
          <img className="token-image" src={FloatLogo} alt="Float Token" />
          <div className="token-details">
            <h2 className="details-heading">Float</h2>
            <span className="details-subheading">a floating currency</span>
          </div>
        </div>
        <div className="token-cta">
          <img className="token-image" src={BankLogo} alt="Bank Token" />
          <div className="token-details">
            <h2 className="details-heading">Bank</h2>
            <span className="details-subheading">utility and governance</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Float = () => {
  return (
    <div className="section">
      <div className="float-token-highlights">
        <div className="token-highlight-r">
          <div className="highlight-details">
            <div className="details-heading">Crypto Native</div>
            <div className="details-subheading">
              Supported openly by a basket of crypto assets. The price is
              stabilised via smart contract Dutch Auctions allowing for supply
              to be contracted or expanded as necessary.
            </div>
          </div>
          <img
            className="highlight-image"
            src={CryptoNative}
            alt="CryptoNative"
          />
        </div>
        <div className="token-highlight-l">
          <img
            className="highlight-image"
            src={PerfectCollateral}
            alt="PerfectCollateral"
          />
          <div className="highlight-details">
            <div className="details-heading">The Perfect Collateral</div>
            <div className="details-subheading">
              FLOAT will always be less volatile than ETH. Using it as
              collateral will result in less liquidations while still remaining
              capital efficient.
            </div>
          </div>
        </div>
        <div className="token-highlight-r">
          <div className="highlight-details">
            <div className="details-heading">Protect your Purchasing Power</div>
            <div className="details-subheading">
              Escape the great monetary inflation of fiat currencies and
              dollar-pegged stablecoins.
            </div>
          </div>
          <img
            className="highlight-image"
            src={PurchasingPower}
            alt="PurchasingPower"
          />
        </div>
        <div className="token-highlight-l">
          <img
            className="highlight-image"
            src={Decentralized}
            alt="Decentralised"
          />
          <div className="highlight-details">
            <div className="details-heading">Decentralised</div>
            <div className="details-subheading">
              No need to trust third parties or rely on a single state’s
              monetary policy.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Bank = () => {
  return (
    <div className="section">
      <div className="bank-token">
        <div className="float-token-highlights">
          <div className="token-highlight-r">
            <div className="highlight-details">
              <div className="details-heading">Two Token System</div>
              <div className="details-subheading">
                <i>BANK</i> captures the volatility of <i>FLOAT</i>, as well as
                capturing any speculative demand. In return, it supports the
                price of FLOAT in times of reduced demand.
              </div>
            </div>
            <img
              className="highlight-image"
              src={SupportPrice}
              alt="SupportPrice"
            />
          </div>
          <div className="token-highlight-l">
            <img
              className="highlight-image"
              src={Governance}
              alt="Governance"
            />
            <div className="highlight-details">
              <div className="details-heading">Open Governance</div>
              <div className="details-subheading">
                The initial supply of BANK was issued by a democratic launch
                designed to achieve wide distribution across different crypto
                communities and to incentivise ownership by users who are active
                participants in other decentralised protocols.
              </div>
            </div>
          </div>
          <div className="token-highlight-r">
            <div className="highlight-details">
              <div className="details-heading">Profit through deflation</div>
              <div className="details-subheading">
                Any excess demand causing the basket to overflow is used to burn
                BANK.
              </div>
            </div>
            <img
              className="highlight-image"
              src={ExcessDemand}
              alt="ExcessDemand"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Landing: React.FC = () => {
  return (
    <>
      <div className="landing">
        <Intro />
        <Tokens />
        <Float />
        <Bank />
      </div>
    </>
  );
};

export default Landing;
