import React from "react";
import { BigNumber } from "@ethersproject/bignumber";

import { useAsyncEffect } from "hooks/useAsyncEffect";
import BankLogo from "assets/float/bank_thumbprint.svg";
import FloatLogo from "assets/float/float_thumbprint.svg";
import { TokenMeta } from "web3/TokenMeta";
import { ABIS, getDeployment } from "web3/utils";
import {
  TokenContractWithAllowance,
  TokenContractWithAllowanceData,
  useTokenContract,
  UseTokenContractProps,
} from "./StakeTokens";

export const BANKTokenMeta: TokenMeta = {
  name: "BANK",
  abi: getDeployment().contracts.BANK.abi ?? ABIS.ERC20,
  address: getDeployment().contracts.BANK.address,
  decimals: 18,
  icon: <img src={BankLogo} className="coin-logo" alt="BANK logo" />,
};

export const FLOATTokenMeta: TokenMeta = {
  name: "FLOAT",
  abi: getDeployment().contracts.FLOAT.abi ?? ABIS.ERC20,
  address: getDeployment().contracts.FLOAT.address,
  decimals: 18,
  icon: (
    <img
      src={FloatLogo}
      className="coin-logo"
      alt="Float Protocol: FLOAT logo"
    />
  ),
};

interface TokenContractWithSupplyData extends TokenContractWithAllowanceData {
  totalSupply?: BigNumber;
}

export interface TokenContractWithSupply
  extends TokenContractWithAllowance,
    TokenContractWithSupplyData {}

export function useTokenContractWithSupplyAndAllowance({
  tokenMeta,
  allowanceAddresses,
}: UseTokenContractProps): TokenContractWithSupply {
  const tokenContract = useTokenContract({
    tokenMeta,
    allowanceAddresses,
  });

  const [totalSupply, setTotalSupply] = React.useState<BigNumber>();

  useAsyncEffect(async () => {
    const [totalSupply] = await tokenContract.contract.batch([
      {
        method: "totalSupply",
        transform: (value: string) => BigNumber.from(value),
      },
    ]);

    setTotalSupply(totalSupply);
  }, [tokenContract.reload]);

  return React.useMemo<TokenContractWithSupply>(
    () => ({
      ...tokenContract,
      totalSupply,
    }),
    [tokenContract, totalSupply]
  );
}
