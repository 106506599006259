import React, { useEffect } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { MaxUint256, AddressZero } from "@ethersproject/constants";

import { useReload } from "hooks/useReload";
import { useWallet } from "wallets/wallet";
import { useAsyncEffect } from "hooks/useAsyncEffect";

import Web3Contract from "web3/Web3Contract";

import { useWeb3React } from "@web3-react/core";
import {
  ETHTokenMeta,
  TokenContractData,
  TokenContractWithAllowance,
  TokenContractWithAllowanceData,
} from "./StakeTokens";
import { usePrices } from "contexts/PriceContext";

const initialData: TokenContractData = {
  balance: undefined,
  priceInUSD: "0",
  tokenMeta: ETHTokenMeta,
};

interface UseEthTokenProps {
  allowanceAddresses: string[];
}

export function useETHToken({
  allowanceAddresses,
}: UseEthTokenProps): TokenContractWithAllowance {
  const [reload] = useReload();
  const wallet = useWallet();
  const { fetched, getPrice } = usePrices();
  const { library } = useWeb3React();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract([], AddressZero, "FAKE_ETH_TOKEN");
  }, []);

  const [data, setData] = React.useState<TokenContractWithAllowanceData>({
    ...initialData,
    allowance: Object.fromEntries(
      allowanceAddresses.map((addr) => [addr, MaxUint256])
    ),
  });

  useAsyncEffect(async () => {
    let balance: BigNumber | undefined;

    if (wallet.account) {
      balance = await library.getBalance(wallet.account);
    }

    setData((prevState) => ({
      ...prevState,
      balance,
    }));
  }, [reload, wallet.account]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      priceInUSD: getPrice("ETH"),
    }));
  }, [fetched, getPrice]);

  const approveSend = React.useCallback((): Promise<void> => {
    return Promise.reject();
  }, []);

  return React.useMemo<TokenContractWithAllowance>(
    () => ({
      ...data,
      reload,
      contract, // This is a hack.
      approveSend,
    }),
    [data, contract, reload, approveSend]
  );
}
