import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import {
  parseUnits,
  formatUnits as etherFormatUnits,
} from "@ethersproject/units";
import { keccak256 } from "@ethersproject/keccak256";

import { AbiItem } from "web3-utils";

import erc20abi from "web3/abi/erc20.json";
import weth9abi from "web3/abi/weth9.json";
import lpabi from "web3/abi/lp.json";
import uniswapv2routerv1abi from "web3/abi/uniswapv2routerv1.json";
import guniabi from "web3/abi/guniabi.json";

import mainnet from "web3/deployment.json";
import rinkeby from "web3/deployment-rinkeby.json";

export const NODE_CHAIN_ID = Number(process.env.REACT_APP_NODE_CHAIN_ID);
export const NODE_URI = String(process.env.REACT_APP_NODE_URI);

export const ETHERSCAN_URI = String(process.env.REACT_APP_ETHERSCAN_URI);

export function contextualiseUri(
  uri: string,
  chainId: number = NODE_CHAIN_ID
): string {
  const network = getNetworkName(chainId).toLowerCase();
  const networkSub = chainId !== 1 ? `${network}.` : "";

  return uri
    .replace("{{networkName}}", network)
    .replace("{{networkSub}}", networkSub);
}

export function getHttpRpcUrl(chainId?: number): string {
  return contextualiseUri(NODE_URI, chainId);
}

export function etherscanAddress(address?: string): string | undefined {
  if (!ETHERSCAN_URI || !address) {
    return undefined;
  }

  return `${contextualiseUri(ETHERSCAN_URI)}address/${address}`;
}

export function etherscanTransaction(hash?: string): string | undefined {
  if (!ETHERSCAN_URI || !hash) {
    return undefined;
  }

  return `${contextualiseUri(ETHERSCAN_URI)}tx/${hash}`;
}

export function etherscanBlock(block?: string): string | undefined {
  if (!ETHERSCAN_URI || !block) {
    return undefined;
  }

  return `${contextualiseUri(ETHERSCAN_URI)}block/${block}`;
}

export function getStorageLocationOfMapping(
  slot: number,
  address: string
): string {
  const slotHex = slot.toString(16).padStart(64, "0");
  const addressHex = BigNumber.from(address)
    .toHexString()
    .slice(2)
    .padStart(64, "0");
  const hexPositionBeforeHash = "0x" + addressHex + slotHex;
  return keccak256(hexPositionBeforeHash);
}

export function shortenString(str: string, chars = 4): string {
  return `${str.slice(0, chars + 2)}...${str.slice(-chars)}`;
}

export function getNetworkName(chainId?: number): string {
  switch (chainId) {
    case 1:
      return "Mainnet";
    case 4:
      return "Rinkeby";
    default:
      return "-";
  }
}

export interface DeployedContract {
  address: string;
  abi: AbiItem[];
}

export interface Deployment {
  name: string;
  chainId: string;
  contracts: {
    // Core
    BANK: DeployedContract;
    FLOAT: DeployedContract;
    MintingCeremony: DeployedContract;
    BasketV1?: DeployedContract;
    AuctionHouse?: DeployedContract;
    Twap?: DeployedContract;
    MonetaryPolicyV1?: DeployedContract;
    BASKET?: DeployedContract;

    // Aux
    ZapInFloatMintingCeremony: DeployedContract;

    // Utils
    MerkleWhitelist: DeployedContract;
    TimeLock: DeployedContract;
    ChainlinkEthUsdConsumer: DeployedContract;

    // Phase 1
    DAIPool: DeployedContract;
    USDCPool: DeployedContract;
    USDTPool: DeployedContract;
    // Phase 2 / 3
    sLPPhase2Pool: DeployedContract;
    DAIPhase2Pool: DeployedContract;
    USDCPhase2Pool: DeployedContract;
    USDTPhase2Pool: DeployedContract;
    ETHPhase2Pool: DeployedContract;
    SUSHIPhase2Pool: DeployedContract;
    wBTCPhase2Pool: DeployedContract;
    YAMPhase2Pool: DeployedContract;
    YFIPhase2Pool: DeployedContract;

    // Phase 4
    BANKPhase4aPool: DeployedContract;
    BankEthLPPhase4Pool: DeployedContract;
    FloatEthLPPhase4Pool: DeployedContract;
    FLOATPhase4Pool: DeployedContract;

    // Multiplier Pools
    BankEthLP_MultiplierPool: DeployedContract;
    Bank_MultiplierPool: DeployedContract;
    FloatEthLP_MultiplierPool: DeployedContract;
    GUNI_FLOAT_USDC_MultiplierPool: DeployedContract;

    // Stake Tokens
    DAI?: DeployedContract;
    USDC?: DeployedContract;
    USDT?: DeployedContract;
    BankEthLP?: DeployedContract;
    FloatEthLP?: DeployedContract;
    SUSHI?: DeployedContract;
    wBTC?: DeployedContract;
    YAM?: DeployedContract;
    YFI?: DeployedContract;
    FEI?: DeployedContract;
    GUNI_FLOAT_USDC?: DeployedContract;
  };
}

export function getDeployment(chainId: number = NODE_CHAIN_ID): Deployment {
  switch (chainId) {
    case 4:
      return rinkeby as unknown as Deployment;
    case 1:
    default:
      return mainnet as unknown as Deployment;
  }
}

export interface LPS {
  floatEth: string;
  bankEth: string;
  guniFloatUsdc: string;
}

export function getLPs(chainId: number = NODE_CHAIN_ID): LPS {
  switch (chainId) {
    case 4:
      return {
        bankEth: "0x132b383B56A88A458EE22D32A7f844AD9490FE07",
        floatEth: "0xFE5823701cF283c14E4988700Cb2b6678748d95f",
        guniFloatUsdc: "0x962D653D54610d54d601b59Cef84805EB4815Cd9",
      };
    case 1:
    default:
      return {
        bankEth: "0x938625591ADb4e865b882377e2c965F9f9b85E34",
        floatEth: "0x481ddaf90c59d91f3e480e6793122e62612ca5a9",
        guniFloatUsdc: "0x4f38892c16bfbb4f4f7424eefaa9767f4e922073",
      };
  }
}

export function getWETH9(chainId: number = NODE_CHAIN_ID): string {
  switch (chainId) {
    case 4:
      return "0xc778417E063141139Fce010982780140Aa0cD5Ab";
    case 1:
    default:
      return "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";
  }
}

export interface DEXS {
  sushi: string;
  uni: string;
}

export function getRouters(chainId: number = NODE_CHAIN_ID): DEXS {
  switch (chainId) {
    case 4:
      return {
        sushi: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
        uni: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      };
    case 1:
    default:
      return {
        sushi: "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
        uni: "0x7a250d5630b4cf539739df2c5dacb4c659f2488d",
      };
  }
}

export const ABIS = {
  ERC20: erc20abi as AbiItem[],
  LP_TOKEN: lpabi as AbiItem[],
  UNISWAP_V2_ROUTER: uniswapv2routerv1abi as AbiItem[],
  WETH9: weth9abi as AbiItem[],
  GUNI_TOKEN: guniabi as AbiItem[],
};

export function now(): number {
  return Math.floor(new Date().getTime() / 1e3);
}

export function toNumber(value?: string): number | undefined {
  return value ? Number(value) : undefined;
}

export function toBigNumber(value?: string): BigNumber | undefined {
  return value ? BigNumber.from(value) : undefined;
}

export function percentageFromBigNumbers(
  balance?: BigNumber,
  total?: BigNumber
): string {
  let percentageOfTotalPool = "0";
  if (total && balance && total.gt(0)) {
    percentageOfTotalPool = (
      balance
        .mul(100 * 1e4)
        .div(total)
        .toNumber() / 1e4
    ).toString();
  }
  return percentageOfTotalPool;
}

interface Range {
  divider: number;
  suffix: string;
}

const DEFAULT_RANGES: Range[] = [
  { divider: 1e9, suffix: "B" },
  { divider: 1e6, suffix: "M" },
];

export function safeParseUnits(
  value: BigNumberish,
  decimals = 18
): BigNumber | undefined {
  try {
    return parseUnits(value.toString(), decimals);
  } catch (err) {
    return undefined;
  }
}

export function formatUnits(
  number?: BigNumber,
  decimals = 18,
  displayDecimals = 2
): string | undefined {
  if (number === undefined) {
    return undefined;
  }

  // Add a rounding factor as we only format to `displayDecimals` places
  // Hence we can round up with a small additive factor
  const factor = decimals - displayDecimals - 2;
  if (factor > 0) {
    number = number.add(BigNumber.from(10).pow(factor));
  }

  const baseFormatting = etherFormatUnits(number, decimals);
  const [unit, fraction] = baseFormatting.split(".");
  const decimalisedFraction = fraction
    .slice(0, displayDecimals)
    .replace(/0+$/, "");

  const unitWithCommas = unit.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${unitWithCommas}${decimalisedFraction ? `.${decimalisedFraction}` : ""
    }`;
}

export function formatPrefix(
  number?: BigNumber,
  decimals = 18,
  displayDecimals = 2,
  ranges: Range[] = DEFAULT_RANGES
): string | undefined {
  for (let i = 0; i < ranges.length; i++) {
    const multiplier = BigNumber.from(ranges[i].divider).mul(
      BigNumber.from(10).pow(decimals)
    );
    if (number?.gt(multiplier)) {
      const byDivider = number
        .mul(10 ** displayDecimals)
        .div(ranges[i].divider);

      return (
        formatUnits(byDivider, decimals + displayDecimals, displayDecimals) +
        ranges[i].suffix
      );
    }
  }
  return number && formatUnits(number, decimals, displayDecimals);
}

export function formatNumberPrefix(
  number?: number,
  displayDecimals = 2,
  ranges: Range[] = DEFAULT_RANGES
): string | undefined {
  if (!number) {
    return undefined;
  }

  for (let i = 0; i < ranges.length; i++) {
    const multiplier = ranges[i].divider;
    if (number >= multiplier) {
      const byDivider = number / ranges[i].divider;
      return byDivider.toFixed(displayDecimals) + ranges[i].suffix;
    }
  }
  return number.toFixed(displayDecimals);
}
