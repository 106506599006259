import React, { ReactElement } from "react";
import "./Auction.css";

interface AuctionProps {
  dial: React.ReactElement;
  sideSection?: React.ReactElement;
}

const Auction = ({ dial, sideSection }: AuctionProps): ReactElement => {
  return (
    <div className="auction-container">
      <div>{dial}</div>
      <div className="right-panel">{sideSection}</div>
    </div>
  );
};

export default Auction;
