import React from "react";
import Loader from "react-spinners/CircleLoader";
import { Play } from "react-feather";

import Ticker from "components/Ticker/Ticker";
import "./AuctionCountdown.css";

interface AuctionCountdownProps {
  canStart?: boolean;
  starting?: boolean;
  waiting?: boolean;
  title?: string;
  timeToGo?: string;
  blockDelta?: number;
  style?: React.CSSProperties;
  onStart?: () => void;
}

const START_RED_VALUE = 70;
const END_RED_VALUE = 255;
const HEX_MAX = 256;
const RANGE = 10;

export const AuctionCountdown: React.FC<AuctionCountdownProps> = ({
  canStart,
  starting,
  waiting,
  title,
  timeToGo,
  blockDelta,
  style,
  onStart,
}: AuctionCountdownProps) => {
  const LaunchIcon = () =>
    !starting ? <Play /> : <Loader color="#000" size={80} />;

  const [blues, setBlues] = React.useState("46");

  React.useEffect(() => {
    if (blockDelta === undefined) {
      setBlues("46");
      return;
    }

    const step = blockDelta % (RANGE * 2);
    let blueHue = 255;
    // Descend colors
    if (step > RANGE) {
      blueHue =
        (START_RED_VALUE +
          ((END_RED_VALUE - START_RED_VALUE) * (step - RANGE)) / RANGE) %
        HEX_MAX;
    }
    // Ascend Colors
    else {
      blueHue =
        (END_RED_VALUE - ((END_RED_VALUE - START_RED_VALUE) * step) / RANGE) %
        HEX_MAX;
    }

    setBlues(blueHue.toString(16).slice(0, 2));
  }, [blockDelta]);

  const needToUpdateTWAP = timeToGo?.charAt(0) === "-";

  if (needToUpdateTWAP) {
    return (
      <div className="auction-countdown-container">
        <div className="countdown-outer-ring"></div>

        <button
          disabled={true}
          onClick={onStart}
          className="countdown-inner-ring"
        >
          {!starting && (
            <p className="countdown-timer-title">Need to update TWAP</p>
          )}
        </button>
      </div>
    );
  } else {
    return (
      <div className="auction-countdown-container">
        <div className="countdown-outer-ring"></div>

        <button
          disabled={!canStart || waiting}
          onClick={onStart}
          className="countdown-inner-ring"
        >
          {canStart && !waiting && <LaunchIcon />}
          {!starting && <p className="countdown-timer-title">{title}</p>}
          <Ticker
            value={timeToGo?.replace(" ", "") ?? ""}
            style={
              style
                ? style
                : {
                    color: `#${blues}00FF`,
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "2.25rem",
                  }
            }
          />
          {!canStart && !waiting && <p>{blockDelta} blocks</p>}
        </button>
      </div>
    );
  }
};

export default AuctionCountdown;
