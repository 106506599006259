import LoadingButton from "components/LoadingButton/LoadingButton";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

import "./PurchaseConfirm.css";
import { AutoRow } from "components/Row";
import { ArrowRight } from "react-feather";
import { useExchange } from "contexts/ExchangeContext";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";

interface PurchaseConfirmProps {
  onSubmit(): void;
  loading: boolean;
  floatAmount: string;
  floatAmountMin: string;
  tokenAmount: string;
  onCancel?: VoidFunction;
}

const PurchaseConfirm: React.FC<PurchaseConfirmProps> = ({
  loading,
  floatAmount,
  tokenAmount,
  floatAmountMin,
  onSubmit,
  onCancel,
}: PurchaseConfirmProps) => {
  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    onSubmit();
  };
  const { float } = useWeb3Contracts();
  const { spendToken } = useExchange();

  return (
    <form onSubmit={handleSubmit}>
      <div className="summary">
        <span className="menu-item">
          {spendToken.tokenMeta.icon}
          <p>
            {tokenAmount} {spendToken.tokenMeta.name}
          </p>
        </span>
        <AutoRow justify="center" padding="0px 32px">
          <ArrowRight />
        </AutoRow>
        <span className="menu-item">
          {float.tokenMeta.icon}
          <p>
            {floatAmount} {float.tokenMeta.name}
          </p>
        </span>
      </div>
      <div>
        <i>
          Output is estimated. You will receive at least{" "}
          <b>
            {floatAmountMin} {float.tokenMeta.name}
          </b>{" "}
          or the transaction will revert.
        </i>
      </div>
      <div className="cancel-confirm-container">
        <button type="button" onClick={onCancel}>
          Cancel
        </button>
        <LoadingButton loading={loading} text="Confirm" />
      </div>
    </form>
  );
};

export default PurchaseConfirm;
