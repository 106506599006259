import Web3Contract from "web3/Web3Contract";
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";

export enum PoolType {
  ONE,
  TWO,
  THREE,
  FOUR,
  GENESIS,
  MULTIPLIER,
}

interface Stake {
  amount: BigNumber;
  timestamp: number;
}

export interface PoolData {
  poolType: PoolType;
  periodFinish?: number;
  rewardPerToken?: number;
  totalStaked?: BigNumber;
  earned?: BigNumber;
  totalReward?: BigNumber;
  duration?: number;
  staked?: BigNumber;
  proportionOfPool?: string;

  // Phase 1 Only:
  maximumContribution?: BigNumber;

  // Phase 4 Only:
  lockPeriod?: number;

  // Multiplier Pool Only:
  stakes?: Stake[];
}

export type Pool<T> = T & {
  contract: Web3Contract;
  reload: VoidFunction;
  stake(amount: BigNumberish): Promise<void>;
  withdraw(amount: BigNumberish): Promise<void>;
  claim(): Promise<void>;
  exit(): Promise<void>;
};
