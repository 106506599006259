import React, { ReactElement } from "react";
import Modal from "react-modal";

import "../FarmStatementCard/FarmStatementCard.css";

import { useWallet } from "wallets/wallet";
import ConnectWalletButton from "components/ConnectWallet/ConnectWalletButton";
import { useStakeOption } from "contexts/StakeOption";
import LoadingButton from "components/LoadingButton/LoadingButton";
import { formatUnits, now } from "web3/utils";
import { MultiplierPool } from "web3/contracts/MultiplierPool";
import { useCountdown } from "hooks/useCountdown";

Modal.setAppElement("#root");

function StatementCard(): ReactElement {
  const { isActive } = useWallet();

  const {
    pool,
    token,
    startApprove,
    startWithdraw,
    startDeposit,
    approving,
    withdrawing,
    depositing,
  } = useStakeOption();
  const {
    staked,
    proportionOfPool,
    hardLockPeriod,
    userData,
  } = pool as MultiplierPool;
  const { allowance } = token;
  const { icon, decimals } = token.tokenMeta;
  const deposit = staked ? formatUnits(staked, decimals) : "-";

  const poolAllowance = allowance[pool.contract.address];
  const hasNoAllowance = poolAllowance && poolAllowance.lte(0);
  const lastStakeTimestamp =
    userData?.stakes && userData.stakes.length
      ? userData.stakes[userData.stakes.length - 1].timestamp
      : undefined;

  const epochNow = now();
  const unlockTime = (lastStakeTimestamp ?? 0) + (hardLockPeriod ?? 0);
  const unlockNeeded =
    hardLockPeriod &&
    userData?.stakes &&
    lastStakeTimestamp &&
    lastStakeTimestamp + hardLockPeriod > epochNow;

  const [timeLeftTillUnlock] = useCountdown(
    unlockNeeded ? unlockTime : undefined
  );

  const ApproveOrDeposit = () => (
    <>
      {hasNoAllowance ? (
        <LoadingButton
          className="medium-button loading-button"
          text="Approve"
          loading={approving}
          onClick={startApprove}
        />
      ) : (
        <>
          {
            <LoadingButton
              className="medium-button loading-button"
              text="Deposit"
              loading={depositing}
              onClick={startDeposit}
            />
          }
        </>
      )}
    </>
  );

  const Withdraw = () => (
    <>
      <LoadingButton
        className="medium-button loading-button"
        text={unlockNeeded ? timeLeftTillUnlock : "Withdraw"}
        loading={withdrawing}
        onClick={unlockNeeded ? undefined : startWithdraw}
      />
    </>
  );

  const DepositDescriptionOrTimer = () => (
    <>
      {hardLockPeriod ? (
        <>
          <p>Your Stake</p>
          <div className="deposit-value-container">
            <div className="deposit-container">
              <h2 className="user-deposit">{deposit}</h2>
            </div>
            <p className="percentage-container">
              {" "}
              (<span className="percentage-value">{proportionOfPool}%</span> of
              total)
            </p>
          </div>
        </>
      ) : (
        <>
          <p>Your Deposit</p>
          <div className="deposit-value-container">
            <div className="deposit-container">
              <h2 className="user-deposit">{deposit}</h2>
            </div>

            <p className="percentage-container">
              {" "}
              (<span className="percentage-value">{proportionOfPool}%</span> of
              total)
            </p>
          </div>
        </>
      )}
    </>
  );

  return (
    <>
      <div className="farm-statement-card-container">
        {icon}

        <DepositDescriptionOrTimer />
        {isActive && (
          <div className="buttons-container">
            <Withdraw />
            <ApproveOrDeposit />
          </div>
        )}
        {!isActive && (
          <>
            <div className="buttons-container">
              <ConnectWalletButton />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default StatementCard;
