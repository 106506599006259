import React, { PropsWithChildren } from "react";
import {
  SendableTransactionType,
  useTransactionType,
} from "hooks/useTransactionType";

interface TransactionType {
  type: SendableTransactionType;
  setType: (type: SendableTransactionType) => void;
}

interface Preferences {
  txn: TransactionType;
}

const PreferencesContext = React.createContext<Preferences>({} as Preferences);

export function usePreferences(): Preferences {
  return React.useContext(PreferencesContext);
}

const PreferencesProvider: React.FC = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [type, setType] = useTransactionType();

  return (
    <PreferencesContext.Provider
      value={{
        txn: {
          type,
          setType,
        },
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export default PreferencesProvider;
