import React, { useEffect } from "react";

import { AbiItem } from "web3-utils";

import { useReload } from "hooks/useReload";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useWallet } from "wallets/wallet";
import Web3Contract from "web3/Web3Contract";
import { usePreferences } from "contexts/PreferencesContext";

import { BigNumber } from "@ethersproject/bignumber";
import { getDeployment, now, percentageFromBigNumbers } from "web3/utils";
import { Pool, PoolData, PoolType } from "./Pool";

const initialData: PoolData = {
  poolType: PoolType.TWO,
  periodFinish: undefined,
  rewardPerToken: undefined,
  totalStaked: undefined,
  earned: undefined,
  totalReward: undefined,
  duration: undefined,
  maximumContribution: undefined,
  staked: undefined,
  proportionOfPool: "-",
};

interface UsePhase2PoolProps {
  abi: AbiItem[];
  addr: string;
  name: string;
}

export type Phase2Pool = Pool<PoolData>;

function usePhase2Pool({ abi, addr, name }: UsePhase2PoolProps): Phase2Pool {
  const [reload] = useReload();
  const wallet = useWallet();
  const { txn } = usePreferences();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  const [data, setData] = React.useState<PoolData>(initialData);

  useAsyncEffect(async () => {
    const [
      totalReward,
      periodFinish,
      rewardPerToken,
      totalStaked,
      duration,
      maximumContribution,
    ] = await contract.batch([
      {
        method: "getRewardForDuration",
        transform: (value: string) => BigNumber.from(value),
      },
      {
        method: "periodFinish",
        transform: (value: string) => Number(value),
      },
      {
        method: "rewardPerToken",
        transform: (value: string) => Number(value),
      },
      {
        method: "totalSupply",
        transform: (value: string) => BigNumber.from(value),
      },
      {
        method: "DURATION",
        transform: (value: string) => Number(value),
      },
      {
        method: "maximumContribution",
        transform: (value: string) => BigNumber.from(value),
      },
    ]);

    setData((prevState) => ({
      ...prevState,
      periodFinish,
      rewardPerToken,
      totalStaked,
      totalReward: periodFinish >= now() ? totalReward : BigNumber.from(0),
      duration,
      maximumContribution,
    }));
  }, [reload]);

  useAsyncEffect(async () => {
    let earned: BigNumber | undefined;
    let staked: BigNumber | undefined;

    if (wallet.account) {
      [earned, staked] = await contract.batch([
        {
          method: "earned",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
        {
          method: "balanceOf",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
      ]);
    }

    setData((prevState) => ({
      ...prevState,
      earned,
      staked,
    }));
  }, [reload, wallet.account]);

  useEffect(() => {
    const proportionOfPool = percentageFromBigNumbers(
      data.staked,
      data.totalStaked
    );

    setData((prevState) => ({
      ...prevState,
      proportionOfPool,
    }));
  }, [data.staked, data.totalStaked]);

  const stake = React.useCallback(
    (amount) => {
      if (!wallet.account) {
        return Promise.reject();
      }
      return contract
        .send("stake", [amount], {
          from: wallet.account,
          type: txn.type,
        })
        .then(reload);
    },
    [wallet.account, contract, txn.type, reload]
  );

  const withdraw = React.useCallback(
    (amount) => {
      if (!wallet.account) {
        return Promise.reject();
      }
      return contract
        .send("withdraw", [BigNumber.from(amount)], {
          from: wallet.account,
          type: txn.type,
        })
        .then(reload);
    },
    [contract, reload, txn.type, wallet.account]
  );

  const claim = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject();
    }
    return contract
      .send("getReward", [], {
        from: wallet.account,
        type: txn.type,
      })
      .then(reload);
  }, [wallet.account, contract, txn.type, reload]);

  const exit = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject();
    }
    return contract
      .send("exit", [], {
        from: wallet.account,
        type: txn.type,
      })
      .then(reload);
  }, [wallet.account, contract, txn.type, reload]);

  return React.useMemo<Phase2Pool>(
    () => ({
      ...data,
      contract,
      reload,
      stake,
      withdraw,
      claim,
      exit,
    }),
    [data, contract, reload, stake, withdraw, claim, exit]
  );
}

export function useSLPPhase2Pool(): Phase2Pool {
  return usePhase2Pool({
    abi: getDeployment().contracts.sLPPhase2Pool.abi,
    addr: getDeployment().contracts.sLPPhase2Pool.address,
    name: "SLP_PHASE2_POOL",
  });
}

export function useDAIPhase2Pool(): Phase2Pool {
  return usePhase2Pool({
    abi: getDeployment().contracts.DAIPhase2Pool.abi,
    addr: getDeployment().contracts.DAIPhase2Pool.address,
    name: "DAI_PHASE2_POOL",
  });
}

export function useUSDCPhase2Pool(): Phase2Pool {
  return usePhase2Pool({
    abi: getDeployment().contracts.USDCPhase2Pool.abi,
    addr: getDeployment().contracts.USDCPhase2Pool.address,
    name: "USDC_PHASE2_POOL",
  });
}

export function useUSDTPhase2Pool(): Phase2Pool {
  return usePhase2Pool({
    abi: getDeployment().contracts.USDTPhase2Pool.abi,
    addr: getDeployment().contracts.USDTPhase2Pool.address,
    name: "USDT_PHASE2_POOL",
  });
}

export function useYFIPhase2Pool(): Phase2Pool {
  return usePhase2Pool({
    abi: getDeployment().contracts.YFIPhase2Pool.abi,
    addr: getDeployment().contracts.YFIPhase2Pool.address,
    name: "YFI_PHASE2_POOL",
  });
}

export function useYAMPhase2Pool(): Phase2Pool {
  return usePhase2Pool({
    abi: getDeployment().contracts.YAMPhase2Pool.abi,
    addr: getDeployment().contracts.YAMPhase2Pool.address,
    name: "YAM_PHASE2_POOL",
  });
}
export function useSUSHIPhase2Pool(): Phase2Pool {
  return usePhase2Pool({
    abi: getDeployment().contracts.SUSHIPhase2Pool.abi,
    addr: getDeployment().contracts.SUSHIPhase2Pool.address,
    name: "SUSHI_PHASE2_POOL",
  });
}

export function useWBTCPhase2Pool(): Phase2Pool {
  return usePhase2Pool({
    abi: getDeployment().contracts.wBTCPhase2Pool.abi,
    addr: getDeployment().contracts.wBTCPhase2Pool.address,
    name: "wBTC_PHASE2_POOL",
  });
}
