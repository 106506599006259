import React, { ReactElement } from "react";

import { ExternalIcon } from "components/ExternalLink/ExternalLink";

import "./GenesisStakePanel.css";
import { useStakeOption } from "contexts/StakeOption";
import { etherscanAddress, formatPrefix, formatUnits, now } from "web3/utils";
import BankEarnedCard from "components/BankEarnedCard/BankEarnedCard";
import GenesisStatementCard from "components/GenesisStatementCard/GenesisStatementCard";
import { BANKTokenMeta } from "web3/contracts/TokenContractWithSupply";
import { Phase4aPool } from "web3/contracts/Phase4Pool";
import { useCountdownQuick } from "hooks/useCountdown";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";
import { mulPrice, scaleUnit, toApr, toPrice } from "web3/prices";

interface GenesisStakePanelProps {
  showAPR?: boolean;
}

function GenesisStakePanel({ showAPR }: GenesisStakePanelProps): ReactElement {
  const { token, pool, options } = useStakeOption();
  const { maximumContribution, totalReward, duration, totalStaked, endWindow } =
    pool as Phase4aPool;
  const { bank } = useWeb3Contracts();
  const { name, decimals } = token.tokenMeta;
  const etherscanLink = etherscanAddress(pool.contract.address);

  const tokenPriceInUsd = toPrice(token.priceInUSD ?? 0);
  const totalStakedUnified = scaleUnit(totalStaked, decimals);
  const totalStakedDollar = mulPrice(totalStakedUnified, tokenPriceInUsd);
  const bankPrice = toPrice(bank.priceInUSD ?? 0);
  const weeklyBank = totalReward?.div(
    duration ? Math.floor(duration / 60 / 60 / 24 / 7) : 1
  );
  const weeklyRewardInUsd = mulPrice(weeklyBank, bankPrice);

  const formattedWeeklyReward = weeklyBank
    ? formatPrefix(weeklyBank, BANKTokenMeta.decimals, 0)
    : "-";
  const totalStakedPrefixed = formatPrefix(totalStaked, decimals);
  const totalStakedDollarPrefixed = formatPrefix(totalStakedDollar);

  const canStillStake = endWindow ? endWindow > now() : true;

  const [timeRemaining] = useCountdownQuick(
    canStillStake ? endWindow : undefined
  );

  const maximumContributionFormatted = formatUnits(
    maximumContribution,
    decimals
  );

  const calcAPR = toApr(totalStakedDollar, weeklyRewardInUsd);

  return (
    <div className="genesis-panel-container">
      <div className="genesis-panel-header">
        <div className="genesis-panel-instructions">
          <h2>
            {options?.displayName ?? name}
            <ExternalIcon href={etherscanLink} />
          </h2>
          {options?.description ?? <p>Deposit {name} to earn BANK tokens</p>}
          {maximumContribution && (
            <p className="genesis-subtitle">
              Limit per whitelisted account is {maximumContributionFormatted}{" "}
              {name}
            </p>
          )}
          <div>
            {endWindow && canStillStake && (
              <p className="genesis-subtitle">
                Only {timeRemaining} left before staking closes
              </p>
            )}
          </div>
        </div>

        <div className="smallColumnDivider"></div>

        {showAPR && (
          <>
            <div className="genesis-deposit-stats">
              <p className="genesis-secondary-title">{name} APR</p>
              <p className="genesis-stat">
                {calcAPR && formatUnits(calcAPR, 16)}%
              </p>
            </div>

            <div className="smallColumnDivider"></div>
          </>
        )}

        <div className="genesis-deposit-stats">
          <p className="genesis-secondary-title">Total Earning</p>
          <p className="genesis-stat">{totalStakedPrefixed}</p>
          {totalStaked && (
            <p className="genesis-subtitle">
              {totalStakedDollarPrefixed
                ? `$${totalStakedDollarPrefixed}`
                : "-"}
            </p>
          )}
        </div>

        <div className="smallColumnDivider"></div>

        <div className="genesis-deposit-stats">
          <p className="genesis-secondary-title">Weekly Distribution</p>
          <p className="genesis-stat">{formattedWeeklyReward}</p>
          <p className="genesis-subtitle">BANK per week</p>
        </div>
      </div>

      <div className="genesis-panel-body">
        <div className="left-box">
          <BankEarnedCard />
        </div>

        <div className="columnDivider"></div>

        <div className="right-box">
          <GenesisStatementCard />
        </div>
      </div>

      <div>{options?.subText}</div>
    </div>
  );
}

export default GenesisStakePanel;
