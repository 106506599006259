import React from "react";

import { AbiItem } from "web3-utils";

import Web3Contract from "web3/Web3Contract";
import { BigNumber } from "@ethersproject/bignumber";
import { useReload } from "hooks/useReload";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { getDeployment, toBigNumber } from "web3/utils";

export interface MonetaryData {
  targetPriceInEth?: BigNumber;
  targetPriceInUsd?: BigNumber;
  targetPriceInEthBeforeAuctionStart?: BigNumber;
  targetPriceInEthAtAuctionStart?: BigNumber;
}

const initialData: MonetaryData = {
  targetPriceInEth: undefined,
  targetPriceInUsd: undefined,
};

interface ContractProps {
  abi: AbiItem[];
  addr: string;
  name: string;
}

interface MonetaryContractProps extends ContractProps {
  lastAuctionBlock?: number;
}

export interface MonetaryContract extends MonetaryData {
  contract: Web3Contract;
  reload: VoidFunction;
}

function useMonetaryContract({
  abi,
  addr,
  name,
  lastAuctionBlock,
}: MonetaryContractProps): MonetaryContract {
  const [reload] = useReload();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  const [data, setData] = React.useState<MonetaryData>({
    ...initialData,
  });

  useAsyncEffect(async () => {
    const [
      targetPriceInUsd,
      targetPriceInEth,
      targetPriceInEthBeforeAuctionStart,
      targetPriceInEthAtAuctionStart,
    ] = await contract.batch([
      {
        method: "targetPrice",
        transform: toBigNumber,
      },
      {
        method: "consult",
        transform: toBigNumber,
      },
      {
        method: "consult",
        defaultBlock: lastAuctionBlock ? lastAuctionBlock - 1 : undefined,
        transform: toBigNumber,
      },
      {
        method: "consult",
        defaultBlock: lastAuctionBlock ? lastAuctionBlock : undefined,
        transform: toBigNumber,
      },
    ]);

    setData((prev) => ({
      ...prev,
      targetPriceInEth,
      targetPriceInUsd,
      targetPriceInEthBeforeAuctionStart,
      targetPriceInEthAtAuctionStart,
    }));
  }, [reload, lastAuctionBlock]);

  return React.useMemo<MonetaryContract>(
    () => ({
      ...data,
      contract,
      reload,
    }),
    [data, contract, reload]
  );
}

export function useMonetary({
  lastAuctionBlock,
}: {
  lastAuctionBlock?: number;
}): MonetaryContract {
  return useMonetaryContract({
    abi: getDeployment().contracts.MonetaryPolicyV1?.abi ?? [],
    addr: getDeployment().contracts.MonetaryPolicyV1?.address ?? "",
    name: "MONETARY_POLICY_V1",
    lastAuctionBlock,
  });
}
