import React from "react";
import { Contract } from "web3-eth-contract";
import Web3Contract, { TransactionInfo } from "web3/Web3Contract";
import { createFilter, FilterSpec } from "./useReceipt";

type TransactionEffect = (transactionHash: string) => void;

export function useTransactionHash(
  effect: TransactionEffect,
  contract: Web3Contract,
  filterSpec: FilterSpec
): void {
  React.useEffect(() => {
    const filter = createFilter(filterSpec);

    const listener = (
      txnHash: string,
      _contract: Contract,
      transactionInfo: TransactionInfo
    ) => {
      if (filter(transactionInfo)) {
        effect(txnHash);
      }
    };
    contract.addListener("transactionHash", listener);
    return () => {
      contract.removeListener("transactionHash", listener);
    };
  }, [contract, effect, filterSpec]);
}
