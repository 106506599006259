import React, { ReactElement } from "react";

import SummaryCard from "components/SummaryCard/SummaryCard";

import { formatPrefix, formatUnits } from "web3/utils";
import { useAuctionContext } from "contexts/AuctionContext";

import "./SummaryPanel.css";
import { stabilisationCaseToAuctionType } from "views/Auction";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";

const SummaryPanel = (): ReactElement => {
  const { isExpansion, latestAuction } = useAuctionContext();
  const { basket, float, bank } = useWeb3Contracts();
  const { startingBasketFactor, endingBasketFactor } = basket;

  return (
    <div className="summary-panel">
      <h1 className="summary-title">Last Auction</h1>
      <h3>
        {latestAuction?.stabilisationCase !== undefined &&
          stabilisationCaseToAuctionType(latestAuction?.stabilisationCase)}
      </h3>
      <div className="summary-grid">
        {startingBasketFactor && endingBasketFactor && (
          <SummaryCard
            title={"Basket Factor Change"}
            content={`${formatUnits(
              startingBasketFactor,
              25,
              3
            )}% → ${formatUnits(endingBasketFactor, 25, 3)}%`}
          />
        )}
        <SummaryCard
          title={"24hr TWAP Price @ Auction"}
          content={
            latestAuction?.marketFloatInEth
              ? `${formatUnits(latestAuction?.marketFloatInEth, 27, 8)}Ξ`
              : "-"
          }
        />
        <SummaryCard
          title={isExpansion ? "Float Sold" : "Float Bought"}
          content={formatUnits(latestAuction?.delta, 18, 2)}
        />
        <SummaryCard
          title={"Float Offered"}
          content={formatUnits(latestAuction?.allowance, 18, 2)}
        />
        <SummaryCard
          title={"Float Total Supply"}
          content={formatPrefix(float?.totalSupply)}
        />
        <SummaryCard
          title={"Bank Total Supply"}
          content={formatPrefix(bank?.totalSupply)}
        />
      </div>
    </div>
  );
};

export default SummaryPanel;
