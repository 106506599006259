import React from "react";

import ThemeProvider from "theme";
import Auction from "components/Auction/Auction";
import AuctionPanel from "components/AuctionPanel/AuctionPanel";
import TradePanel from "components/TradePanel/TradePanel";
import AuctionProvider, { useAuctionContext } from "contexts/AuctionContext";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";
import { Cases, Stage } from "web3/contracts/AuctionHouse";
import { PreAuctionPanel } from "components/PreAuctionPanel/PreAuctionPanel";
import SummaryPanel from "components/SummaryPanel/SummaryPanel";

export function stabilisationCaseToAuctionType(stabCase: Cases): string {
  switch (stabCase) {
    case Cases.Up:
      return "Expansion";
    case Cases.Restock:
      return "Expansion / Restock";
    case Cases.Confidence:
      return "Contraction / Confidence";
    case Cases.Down:
      return "Contraction";
  }
}

const AuctionPanelFromContext: React.FC = () => {
  const { stabilisationCase } = useAuctionContext();
  if (stabilisationCase === undefined) {
    return null;
  }

  const auctionType = stabilisationCaseToAuctionType(stabilisationCase);

  return <AuctionPanel auctionType={auctionType} />;
};

const TradePanelFromContext: React.FC = () => {
  const { isExpansion, stabilisationCase } = useAuctionContext();

  if (stabilisationCase === undefined) {
    return null;
  }

  return (
    <TradePanel
      tradeType={isExpansion ? "Buy" : "Sell"}
      stabilisationCase={stabilisationCase}
    />
  );
};

const AuctionStage: React.FC = () => {
  const { auctionHouse } = useWeb3Contracts();
  const { stage, step, AUCTION_DURATION } = auctionHouse;
  const active = stage !== undefined && stage === Stage.AuctionActive;

  const auctionOver =
    step && AUCTION_DURATION ? step > AUCTION_DURATION : false;

  // TODO investigate why it is still rendering
  if (active && !auctionOver) {
    return (
      <Auction
        dial={<AuctionPanelFromContext />}
        sideSection={<TradePanelFromContext />}
      />
    );
  } else {
    return (
      <Auction dial={<PreAuctionPanel />} sideSection={<SummaryPanel />} />
    );
  }
};
const AuctionView: React.FC = () => {
  return (
    <>
      <ThemeProvider>
        <AuctionProvider>
          <AuctionStage />
        </AuctionProvider>
      </ThemeProvider>
    </>
  );
};

export default AuctionView;
