import React from "react";

import { AbiItem } from "web3-utils";

import Web3Contract from "web3/Web3Contract";
import { BigNumber } from "@ethersproject/bignumber";
import { useReload } from "hooks/useReload";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { getDeployment, now, toBigNumber } from "web3/utils";
import { toPrice } from "web3/prices";

interface Observation {
  slot: number;
  timestamp: number;
  price0Cumulative: BigNumber;
  price1Cumulative: BigNumber;
  slotActivelyUsedFrom: number;
}

export interface TwapData {
  bankEthTwapPrice?: BigNumber;
  floatEthTwapPrice?: BigNumber;
  bankEthObservations?: Observation[];
  floatEthObservations?: Observation[];
  currentSlot: number;
  firstInWindowSlot: number;
  oldestValidObservation?: Observation;
  waitingForTwap: boolean;
}

const initialData: Omit<Omit<TwapData, "currentSlot">, "firstInWindowSlot"> = {
  bankEthTwapPrice: undefined,
  floatEthTwapPrice: undefined,
  bankEthObservations: undefined,
  floatEthObservations: undefined,
  waitingForTwap: false,
};

interface ContractProps {
  abi?: AbiItem[];
  addr?: string;
  name?: string;
}

interface SLPDefinition {
  token0: string;
  token1: string;
  pair: string;
}

interface TwapContractProps extends ContractProps {
  bankEthPair: SLPDefinition;
  floatEthPair: SLPDefinition;
}

export interface TwapContract extends TwapData {
  contract: Web3Contract;
  reload: VoidFunction;
}

const PERIOD_SIZE = 21600;
const GRANULARITY = 4;
const WINDOW_SIZE = PERIOD_SIZE * GRANULARITY;

export function useTwapContract({
  bankEthPair,
  floatEthPair,
  abi = getDeployment().contracts.Twap?.abi ?? [],
  addr = getDeployment().contracts.Twap?.address ?? "",
  name = "TWAP",
}: TwapContractProps): TwapContract {
  const [reload] = useReload();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  const periodNumber = Math.floor(now() / PERIOD_SIZE);
  const currentSlot = periodNumber % GRANULARITY;
  const firstInWindowSlot = (currentSlot + 1) % GRANULARITY;

  const [data, setData] = React.useState<TwapData>({
    ...initialData,
    currentSlot,
    firstInWindowSlot,
  });

  useAsyncEffect(async () => {
    const [bankEthTwapPrice, floatEthTwapPrice] = await contract.batch(
      [
        {
          method: "consult",
          methodArgs: [bankEthPair.token0, toPrice(1), bankEthPair.token1],
          transform: toBigNumber,
        },
        {
          method: "consult",
          methodArgs: [floatEthPair.token0, toPrice(1), floatEthPair.token1],
          transform: toBigNumber,
        },
      ],
      "missingPastObsr"
    );

    if (
      bankEthTwapPrice === "missingPastObsr" ||
      floatEthTwapPrice === "missingPastObsr"
    ) {
      setData((prev) => ({
        ...prev,
        waitingForTwap: true,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        bankEthTwapPrice,
        floatEthTwapPrice,
      }));
    }
  }, [reload]);

  useAsyncEffect(async () => {
    const activeSlotOffset = GRANULARITY - 1 - currentSlot;

    const observations = await contract.batch([
      ...Array.from({ length: GRANULARITY }, (_, idx) => ({
        method: "pairObservations",
        methodArgs: [bankEthPair.pair, idx],
        transform: (val: Record<string, string>) => {
          const periodInUseFrom =
            periodNumber + ((idx + activeSlotOffset) % GRANULARITY);
          return {
            timestamp: Number(val.timestamp),
            price0Cumulative: BigNumber.from(val.price0Cumulative),
            price1Cumulative: BigNumber.from(val.price1Cumulative),
            slot: idx,
            slotActivelyUsedFrom: periodInUseFrom * PERIOD_SIZE,
          };
        },
      })),
      ...Array.from({ length: GRANULARITY }, (_, idx) => ({
        method: "pairObservations",
        methodArgs: [floatEthPair.pair, idx],
        transform: (val: Record<string, string>) => {
          const periodInUseFrom =
            periodNumber + ((idx + activeSlotOffset) % GRANULARITY);
          return {
            timestamp: Number(val.timestamp),
            price0Cumulative: BigNumber.from(val.price0Cumulative),
            price1Cumulative: BigNumber.from(val.price1Cumulative),
            slot: idx,
            slotActivelyUsedFrom: periodInUseFrom * PERIOD_SIZE,
          };
        },
      })),
    ]);

    const bankEthObservations = observations.slice(0, GRANULARITY);
    const floatEthObservations = observations.slice(
      GRANULARITY,
      GRANULARITY * 2
    );

    const oldestValidObservation = floatEthObservations.reduce((prev, curr) => {
      if (!curr) {
        return prev;
      }
      // If too old (older than window size), then this means we failed to update.
      if (curr.slotActivelyUsedFrom - curr.timestamp >= WINDOW_SIZE) {
        return prev;
      }

      // Check we don't have an invalid timestamp
      if (curr.slotActivelyUsedFrom - curr.timestamp <= PERIOD_SIZE * 2) {
        return prev;
      }

      // Now pick the oldest defined
      return prev && prev.timestamp <= curr.timestamp ? prev : curr;
    }, undefined);

    setData((prev) => ({
      ...prev,
      bankEthObservations: bankEthObservations,
      floatEthObservations: floatEthObservations,
      oldestValidObservation,
    }));
  }, [reload, bankEthPair.pair, floatEthPair.pair]);

  return React.useMemo<TwapContract>(
    () => ({
      ...data,
      contract,
      reload,
    }),
    [data, contract, reload]
  );
}
