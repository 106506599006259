import React, { ReactElement } from "react";
import Modal from "react-modal";
import { formatPrefix, formatUnits } from "web3/utils";

import CloseIcon from "assets/modal/close.png";
import "../GeneralModal/GeneralModal.css";
import { useTransactionHash } from "hooks/useTransactionHash";
import { useReceipt } from "hooks/useReceipt";
import { TransactionBlock } from "components/GeneralModal/GeneralModal.TransactionBlock";
import { toast } from "react-toastify";
import PurchaseConfirm from "components/PurchaseConfirm/PurchaseConfirm";
import { useExchange } from "contexts/ExchangeContext";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";

Modal.setAppElement("#root");

interface PurchaseModalProps {
  isOpen: boolean;
  onCancel: VoidFunction;
}
function PurchaseModal({ isOpen, onCancel }: PurchaseModalProps): ReactElement {
  const {
    fromAmount,
    toAmount,
    spendToken,
    purchasing,
    purchase,
  } = useExchange();
  const { floatMintingCeremony, zapInFloatMinting, float } = useWeb3Contracts();

  const { balance } = spendToken;
  const { name } = spendToken.tokenMeta;

  const [txn, setTxn] = React.useState<string>();

  const balanceFormatted =
    formatPrefix(balance, spendToken.tokenMeta.decimals) ?? "-";

  useTransactionHash(setTxn, floatMintingCeremony.contract, ["commit"]);
  useReceipt(() => setTxn(""), floatMintingCeremony.contract, ["commit"]);
  useReceipt(
    (receipt) => {
      setTxn("");
      toast.success(`${name} Commitment confirmed #${receipt.transactionHash}`);
      onCancel();
    },
    floatMintingCeremony.contract,
    ["commit"]
  );

  useTransactionHash(setTxn, zapInFloatMinting.contract, ["ZapIn"]);
  useReceipt(() => setTxn(""), zapInFloatMinting.contract, ["ZapIn"]);
  useReceipt(
    (receipt) => {
      setTxn("");
      toast.success(`${name} Commitment confirmed #${receipt.transactionHash}`);
      onCancel();
    },
    zapInFloatMinting.contract,
    ["ZapIn"]
  );

  return (
    <Modal
      className="general-modal"
      overlayClassName="general-overlay"
      isOpen={isOpen}
      onRequestClose={onCancel}
    >
      <div className="general-modal-container">
        <input
          className="close-modal-button"
          type="image"
          src={CloseIcon}
          alt="Close window"
          onClick={onCancel}
        />

        <div className="modal-header">
          <div className="title-wrapper">
            <p className="modal-title">Purchase Float with {name}</p>
            <p>
              You will receive your FLOAT tokens at the end of the minting
              ceremony.
            </p>
          </div>

          <div className="smallColumnDivider"></div>

          <div className="modal-key-info">
            <p>My Wallet Balance: </p>
            <p className="modal-numeric-value">
              {balanceFormatted} {name}
            </p>
          </div>
        </div>

        <TransactionBlock txn={txn} onCancel={onCancel}>
          <PurchaseConfirm
            tokenAmount={
              formatUnits(fromAmount, spendToken.tokenMeta.decimals, 5) ?? "-"
            }
            floatAmount={
              formatUnits(toAmount, float.tokenMeta.decimals, 5) ?? "-"
            }
            floatAmountMin={
              formatUnits(
                toAmount?.mul(995).div(1e3),
                float.tokenMeta.decimals,
                5
              ) ?? "-"
            }
            loading={purchasing}
            onSubmit={purchase}
            onCancel={onCancel}
          />
        </TransactionBlock>
      </div>
    </Modal>
  );
}

export default PurchaseModal;
