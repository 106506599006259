import React, { ReactElement } from "react";
import Modal from "react-modal";
import MaxForm from "components/MaxForm/MaxForm";
import { useStakeOption } from "contexts/StakeOption";
import { formatUnits as ethersFormatUnits } from "@ethersproject/units";
import { formatUnits } from "web3/utils";

import CloseIcon from "assets/modal/close.png";
import "../GeneralModal/GeneralModal.css";
import { useTransactionHash } from "hooks/useTransactionHash";
import { useReceipt } from "hooks/useReceipt";
import { TransactionBlock } from "components/GeneralModal/GeneralModal.TransactionBlock";
import { toast } from "react-toastify";

Modal.setAppElement("#root");

interface DepositModalProps {
  isOpen: boolean;
  onCancel: VoidFunction;
}
function DepositModal({ isOpen, onCancel }: DepositModalProps): ReactElement {
  const { pool, token, depositing, deposit } = useStakeOption();
  const { maximumContribution, staked } = pool;
  const { balance } = token;
  const { name, icon, decimals } = token.tokenMeta;

  const remainingContribution = maximumContribution?.sub(staked ?? 0);
  const balanceFormatted = balance
    ? formatUnits(balance, token.tokenMeta.decimals)
    : "-";

  const [maximum, setMaximum] = React.useState<string>();
  const [txn, setTxn] = React.useState<string>();

  React.useEffect(() => {
    if (!balance) {
      setMaximum("0");
      return;
    }
    if (remainingContribution && balance.gt(remainingContribution)) {
      setMaximum(
        ethersFormatUnits(remainingContribution, decimals).replace(",", "")
      );
      return;
    }
    setMaximum(ethersFormatUnits(balance, decimals)?.replace(",", ""));
  }, [balance, remainingContribution, decimals]);

  useTransactionHash(setTxn, pool.contract, ["stake", "stakeWithProof"]);
  useReceipt(() => setTxn(""), pool.contract, ["stake", "stakeWithProof"]);
  useReceipt(
    (receipt) => {
      setTxn("");
      toast.success(`${name} Deposit confirmed #${receipt.transactionHash}`);
    },
    pool.contract,
    ["stake", "stakeWithProof"]
  );

  return (
    <Modal
      className="general-modal"
      overlayClassName="general-overlay"
      isOpen={isOpen}
      onRequestClose={onCancel}
    >
      <div className="general-modal-container">
        <input
          className="close-modal-button"
          type="image"
          src={CloseIcon}
          alt="Close window"
          onClick={onCancel}
        />

        <div className="modal-header with-icon">
          {icon}
          <div className="title-wrapper">
            <p className="modal-title">Deposit {name}</p>
            {maximumContribution && (
              <p className="modal-subtitle">
                Limit per whitelisted account is{" "}
                {maximumContribution
                  ? formatUnits(maximumContribution, token.tokenMeta.decimals)
                  : "-"}{" "}
                {name}
              </p>
            )}
          </div>

          <div className="smallColumnDivider"></div>

          <div className="modal-key-info">
            <p>My Wallet Balance: </p>
            <p className="modal-numeric-value">
              {balanceFormatted} {name}
            </p>
          </div>
        </div>

        <TransactionBlock txn={txn} onCancel={onCancel}>
          <MaxForm
            max={maximum}
            loading={depositing}
            onSubmit={deposit}
            onCancel={onCancel}
          />
        </TransactionBlock>
      </div>
    </Modal>
  );
}

export default DepositModal;
