import React from "react";
import { Contract } from "web3-eth-contract";
import Web3Contract, { TransactionInfo } from "web3/Web3Contract";

type ReceiptEffect = (receipt: Record<string, unknown>) => void;

type TransactionFilter = (transactionInfo: TransactionInfo) => boolean;

export type FilterSpec = string | string[] | undefined;

export function createFilter(filterSpec: FilterSpec): TransactionFilter {
  if (!filterSpec) {
    return () => true;
  }

  const methods = filterSpec instanceof Array ? filterSpec : [filterSpec];

  return (transactionInfo: TransactionInfo) =>
    methods.includes(transactionInfo.method);
}

export function useReceipt(
  effect: ReceiptEffect,
  contract: Web3Contract,
  filterSpec?: FilterSpec
): void {
  React.useEffect(() => {
    const filter = createFilter(filterSpec);
    const listener = (
      receipt: Record<string, unknown>,
      _contract: Contract,
      transactionInfo: TransactionInfo
    ) => {
      if (filter(transactionInfo)) {
        effect(receipt);
      }
    };
    contract.addListener("receipt", listener);
    return () => {
      contract.removeListener("receipt", listener);
    };
  }, [contract, effect, filterSpec]);
}
