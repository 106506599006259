import React from "react";
import Web3Contract, { ContractProps } from "web3/Web3Contract";
import { BigNumber } from "@ethersproject/bignumber";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { getDeployment, toBigNumber } from "web3/utils";
import { useReload } from "hooks/useReload";

export interface EthUsdOracleData {
  ethPriceInUsd?: BigNumber;
}

const initialData: EthUsdOracleData = {
  ethPriceInUsd: undefined,
};

export interface EthUsdOracle extends EthUsdOracleData {
  contract: Web3Contract;
  reload: VoidFunction;
}

function useEthUsdOracle({ abi, addr, name }: ContractProps): EthUsdOracle {
  const [reload] = useReload();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  const [data, setData] = React.useState<EthUsdOracleData>(initialData);

  useAsyncEffect(async () => {
    const [ethPriceInUsd] = await contract.batch([
      {
        method: "consult",
        transform: toBigNumber,
      },
    ]);

    setData((prev) => ({
      ...prev,
      ethPriceInUsd,
    }));
  }, [reload]);

  return React.useMemo<EthUsdOracle>(
    () => ({
      ...data,
      contract,
      reload,
    }),
    [data, contract, reload]
  );
}

export function useChainlinkOracle(): EthUsdOracle {
  return useEthUsdOracle({
    abi: getDeployment().contracts.ChainlinkEthUsdConsumer?.abi ?? [],
    addr: getDeployment().contracts.ChainlinkEthUsdConsumer?.address ?? "",
    name: "CHAINLINK_ETH_USD_CONSUMER",
  });
}
