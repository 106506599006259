import React, { useRef, useState, useEffect } from "react";

import Roller from "./Ticker.Roller";

interface TickerProps {
  value: string | number;
  direction?: "up" | "down";
  speed?: number;
  dictionary?: string[];
  constantKeys?: string[];
  colors?: string[];
  style?: React.CSSProperties;
}

const Ticker: React.FC<TickerProps> = ({
  value: valueFromProps,
  direction,
  speed = 500,
  constantKeys,
  dictionary,
  colors,
  style,
}: TickerProps) => {
  const initialRender = useRef(true);
  const [height, setHeight] = useState<number>(0);
  const rollers = useRef<HTMLDivElement[]>([]);
  const [currentWidths, setCurrentWidths] = useState<number[]>([]);
  const value = valueFromProps.toString().split("");

  const characterDictionary = dictionary || "9876543210$-.,".split("");
  const constants = constantKeys || ["-", "$", "h", "m", "s"];

  const transitionColors = colors || [];

  // Sets the transition speed of animations to 0 if initially rendered value
  // hasn't been updated yet.
  const transitionSpeedZeroIfInitialRender = initialRender.current ? 0 : speed;

  const tickerStyle: React.CSSProperties = {
    display: "flex",
    width: currentWidths.reduce((total, current) => total + current, 0),
    height,
    transition: `width ${transitionSpeedZeroIfInitialRender}ms ease-in-out`,
    fontSize: "64px",
    fontWeight: "bold",
    ...style,
  };

  useEffect(() => {
    rollers.current = rollers.current.slice(0, value.length);
  }, [value.length, valueFromProps]);

  useEffect(() => {
    // Non-existent DOM
    if (typeof window === "undefined") {
      return;
    }

    // Do this to force an update that reflects the widths of the
    // elements in the `rollers` ref collection.
    setCurrentWidths(
      rollers.current.map((element) => {
        const { width } = element.getBoundingClientRect();

        return width;
      })
    );
  }, [valueFromProps]);

  useEffect(() => {
    if (initialRender.current && currentWidths.length) {
      initialRender.current = false;
    }
  }, [currentWidths]);

  return (
    <div style={tickerStyle}>
      {value.map((character, i) => {
        const constantIndex = constants.findIndex(
          (constant) => constant === character
        );
        const leftRespectiveKey = `${character}-${constantIndex}`;
        const placeRespectiveKey = value.length - i - 1;

        const key = constantIndex >= 0 ? leftRespectiveKey : placeRespectiveKey;

        const reducedWidth = currentWidths.reduce((total, current, index) => {
          if (index > i - 1) {
            return total;
          }

          return total + (current || 0);
        }, 0);

        const offsettedRollerStyle: React.CSSProperties = {
          position: "absolute",
          transform: `translate(${reducedWidth}px, 0px)`,
          transition: `transform ${transitionSpeedZeroIfInitialRender}ms ease-in-out`,
        };

        const heightSetter = (height: number) => {
          setHeight((prevHeight) => Math.max(height, prevHeight));
        };

        const widthRef = (r: HTMLDivElement) => {
          if (!r) {
            return;
          }

          rollers.current[i] = r;
        };

        return (
          <div key={key} style={offsettedRollerStyle}>
            <Roller
              isConstant={constantIndex > 0}
              widthRef={widthRef}
              setHeight={heightSetter}
              direction={direction}
              value={character}
              speed={transitionSpeedZeroIfInitialRender}
              dictionary={characterDictionary}
              transitionColors={transitionColors}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Ticker;
