import React, { ReactElement } from "react";

import "./Panel.css";

interface PanelProps {
  children?: React.ReactNode[];
}

function Panel({ children }: PanelProps): ReactElement {
  return (
    <div>
      <div className="panel-container">
        {children?.map(
          (panel, idx) =>
            panel && (
              <div key={idx} className="panel-box">
                {panel}
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default Panel;
