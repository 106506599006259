import React from "react";
import { Link } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";

import "./TopBarMenu.css";

const transition = { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.9] };

interface TopBarMenuProps {
  setMenuState: any;
  menuState: boolean;
}

function TopBarMenu({
  setMenuState,
  menuState,
}: TopBarMenuProps): React.ReactElement {
  return (
    <>
      <AnimatePresence>
        {menuState && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "100%" }}
            exit={{ height: 0 }}
            transition={{ ...transition, duration: 1 }}
            className="menu"
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ...transition, duration: 1 }}
              onClick={() => setMenuState(!menuState)}
              className="close-button"
            >
              X
            </motion.div>
            <div className="container">
              <motion.div className="menu-inner">
                <motion.ul>
                  <li>
                    <Link to="/auction" className="gradient-text">
                      Auction
                    </Link>
                  </li>
                  <li>
                    <Link to="/pools">Pools</Link>
                  </li>
                  <li>
                    <a
                      href="https://snapshot.org/#/snapshot.floatprotocol.eth"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Governance
                    </a>
                  </li>
                </motion.ul>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default TopBarMenu;
