import { useAuctionContext } from "contexts/AuctionContext";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { formatUnits } from "web3/utils";

import BasicAuctionStat from "components/BasisAuctionStat/BasicAuctionStat";

const AuctionDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;

  @media (max-width: 868px) {
    display: grid;
    grid-template-columns: minmax(0, 3fr) minmax(0, 3fr);
    grid-template-rows: minmax(0, 3fr) minmax(0, 3fr);
    grid-gap: 1rem;
  }
`;

const AuctionDetails = (): ReactElement => {
  const {
    floatMarketPriceInEth,
    floatMarketPriceInUsd,
    floatTargetPriceInEth,
    floatTargetPriceInUsd,
    floatTwapPriceInEth,
    floatTwapPriceInUsd,
    currentPrice,
  } = useAuctionContext();
  const { eth, inUsd, bank } = currentPrice;

  const formatMarketFloatInEth =
    floatMarketPriceInEth && `${formatUnits(floatMarketPriceInEth, 27, 8)} Ξ`;
  const formatMarketFloatInUsd = floatMarketPriceInUsd
    ? `$${formatUnits(floatMarketPriceInUsd, 27, 3)}`
    : "-";

  const formatTargetFloatInEth =
    floatTargetPriceInEth && `${formatUnits(floatTargetPriceInEth, 27, 8)} Ξ`;
  const formatTargetFloatInUsd = floatTargetPriceInUsd
    ? `$${formatUnits(floatTargetPriceInUsd, 27, 3)}`
    : "-";

  const formatTwapFloatInEth =
    floatTwapPriceInEth && `${formatUnits(floatTwapPriceInEth, 27, 8)} Ξ`;
  const formatTwapFloatInUsd = floatTwapPriceInUsd
    ? `$${formatUnits(floatTwapPriceInUsd, 27, 3)}`
    : "-";

  const formatCurrentPriceInUsd = inUsd ? `$${formatUnits(inUsd, 27, 3)}` : "-";

  const formatCurrentEthPrice = formatUnits(eth, 27, 8);
  const formatCurrentBankPrice = bank?.gte(0)
    ? formatUnits(bank, 27, 8)
    : undefined;
  return (
    <AuctionDetailsWrapper>
      <BasicAuctionStat
        title={"24hr TWAP Price"}
        content={formatTwapFloatInUsd}
        footnote={formatTwapFloatInEth}
      />
      <BasicAuctionStat
        title={"Market Price"}
        content={formatMarketFloatInUsd}
        footnote={formatMarketFloatInEth}
      />
      <BasicAuctionStat
        title={"Target Price"}
        content={formatTargetFloatInUsd}
        footnote={formatTargetFloatInEth}
        gradientContent={true}
      />
      <BasicAuctionStat
        title={"Current Auction Price"}
        content={formatCurrentPriceInUsd}
        footnote={
          <>
            <span>{formatCurrentEthPrice} Ξ</span>
            {formatCurrentBankPrice && <span>+ {formatCurrentBankPrice} BANK</span>}
          </>
        }
      />
    </AuctionDetailsWrapper>
  );
};

export default AuctionDetails;
