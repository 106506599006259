import React from "react";
import PhaseInfo from "components/PhaseInfo/PhaseInfo";
import StakeOptionProvider, {
  PoolOptions,
  StakingPool,
} from "contexts/StakeOption";
import ExternalLink from "components/ExternalLink/ExternalLink";

import { useWeb3Contracts, Web3Contracts } from "contexts/Web3ContractsContext";
import { StatPanel } from "components/StatPanel/StatPanel";
import MultiplierPanel from "components/MultiplierPanel/MultiplierPanel";
import { Link } from "react-router-dom";
import { TokenContractWithAllowance } from "web3/contracts/StakeTokens";

type StakeToken = keyof Web3Contracts;
type Pool = keyof Web3Contracts;

interface MultiplierPoolProps {
  stakeToken: StakeToken;
  pool: Pool;
  options?: PoolOptions;
}

const MultiplierPool = ({ stakeToken, pool, options }: MultiplierPoolProps) => {
  const contracts = useWeb3Contracts();
  return (
    <StakeOptionProvider
      token={contracts[stakeToken] as TokenContractWithAllowance}
      pool={contracts[pool] as StakingPool}
      options={options}
    >
      <MultiplierPanel showAPR />
    </StakeOptionProvider>
  );
};

const PoolsView: React.FC = () => {
  return (
    <>
      <StatPanel />
      <PhaseInfo title={<h4>Multiplier Pools</h4>}>
        <p>
          Multiplier-based Pools (BANK, FLOAT/USDC, FLOAT/ETH, BANK/ETH) are
          rewarded according to{" "}
          <ExternalLink href="https://snapshot.org/#/snapshot.floatprotocol.eth/">
            {" "}
            governance vote
          </ExternalLink>
        </p>
        <p>
          <b>How the Multiplier Pools Work: </b>
          <br />
          Multiplier Pools are competitive. All stakers accrue “unit-seconds”
          that correspond to how many units of the tokens they’ve staked and the
          length of time they’re staked for. The rewards are proportional to
          their unit-seconds against the unit-seconds of the entire pool. Unlike
          traditional pools, there are no checkpoints, instead the concept of
          time is directly integrated into the unit-seconds.
        </p>
        <p>
          In addition, you can gain a multiplier by remaining in for longer
          periods of time, this is &quot;spent&quot; at un-stake time. Allowing
          for additional bonus rewards against the entire pool. This gives a
          gamifies the process of un-staking. Each has their own unique
          multiplier and we prioritise unstaking the shortest stakes (with the
          least multiplier) first.
        </p>
      </PhaseInfo>

      <MultiplierPool
        stakeToken="guniFloatUsdc"
        pool="guniFloatUsdc_MultiplierPool"
        options={{
          liquidityURL: "",
          description: (
            <>
              <p>
                Add FLOAT + USDC liquidity on{" "}
                <ExternalLink
                  href={`https://app.zerion.io/invest/asset/G-UNI-0x4f38892c16bfbb4f4f7424eefaa9767f4e922073`}
                >
                  Zerion
                </ExternalLink>{" "}
                or{" "}
                <ExternalLink
                  href={`https://www.sorbet.finance/#/pools/add/0x4f38892c16bfbB4f4f7424EEfAa9767F4E922073`}
                >
                  Sorbet
                </ExternalLink>{" "}
                for G-UNI.
              </p>
              <p>Stake G-UNI to earn BANK tokens.</p>
            </>
          ),
          subText: (
            <>
              <p>Note: Claiming rewards resets the multiplier.</p>
            </>
          ),
        }}
      />

      <MultiplierPool
        stakeToken="floatEthSLP"
        pool="floatEthSLP_MultiplierPool"
        options={{
          description: (
            <>
              <p>
                Add FLOAT + ETH liquidity on{" "}
                <ExternalLink
                  href={`https://app.sushi.com/add/ETH/0xb05097849bca421a3f51b249ba6cca4af4b97cb9`}
                >
                  Sushiswap
                </ExternalLink>{" "}
                for sLP.
              </p>
              <p>Stake sLP to earn BANK tokens.</p>
            </>
          ),
          subText: (
            <>
              <p>Note: Claiming rewards resets the multiplier.</p>
            </>
          ),
        }}
      />

      <MultiplierPool
        stakeToken="bankEthSLP"
        pool="bankEthSLP_MultiplierPool"
        options={{
          description: (
            <>
              <p>
                Add BANK + ETH liquidity on{" "}
                <ExternalLink
                  href={`https://app.sushi.com/add/ETH/0x24A6A37576377F63f194Caa5F518a60f45b42921`}
                >
                  Sushiswap
                </ExternalLink>{" "}
                for sLP.
              </p>
              <p>Stake sLP to earn BANK tokens.</p>
            </>
          ),
          subText: (
            <>
              <p>Note: Claiming rewards resets the multiplier.</p>
            </>
          ),
        }}
      />

      <MultiplierPool
        stakeToken="bank"
        pool="bank_MultiplierPool"
        options={{
          purchaseURL:
            "https://app.sushi.com/swap?inputCurrency=&outputCurrency=0x24A6A37576377F63f194Caa5F518a60f45b42921",
          subText: (
            <>
              <p>
                This Pool has a hardlock of 30 days. Each interaction will cause
                the timer to reset: this includes claiming tokens, withdrawing
                or staking additional BANK tokens.
              </p>
            </>
          ),
        }}
      />

      <PhaseInfo title={<h4>Expired Pools</h4>}>
        <h3>
          Missing your stake? Check{" "}
          <Link to="/pools/expired">the expired pools</Link>.
        </h3>
      </PhaseInfo>
    </>
  );
};

export default PoolsView;
