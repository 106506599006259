import { errors } from "web3-core-helpers";

export function overrideExecuteCall(batchRequest: unknown): void {
  (batchRequest as any).__proto__.execute = function () {
    const requests = this.requests;
    this.requestManager.sendBatch(requests, function (err: any, results: any[]) {
      results = results?.sort((a, b) => (a.id > b.id) ? 1 : -1) ?? [];
      requests.map(function (request: any, index: number) {
        return results[index] || {};
      }).forEach(function (result: any | Error, index: number) {
        if (requests[index].callback) {
          if (result && result.error) {
            return requests[index].callback(errors.ErrorResponse(result));
          }
          if (!isValidResponse(result)) {
            return requests[index].callback(errors.InvalidResponse(result));
          }
          try {
            requests[index].callback(null, requests[index].format ? requests[index].format(result.result) : result.result);
          }
          catch (err) {
            requests[index].callback(err);
          }
        }
      });
    });
  };
}

function isValidResponse(response: any[]) {
  return Array.isArray(response) ? response.every(validateSingleMessage) : validateSingleMessage(response);

  function validateSingleMessage(message: any) {
    return !!message &&
      !message.error &&
      message.jsonrpc === '2.0' &&
      (typeof message.id === 'number' || typeof message.id === 'string') &&
      message.result !== undefined; // only undefined is not valid json object
  }
}