import React, { PropsWithChildren } from "react";
import { ExternalLink as ExternalLinkIcon } from "react-feather";

import "./ExternalLink.css";

export type ExternalLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const ExternalLink: React.FC<ExternalLinkProps> = (
  props: PropsWithChildren<ExternalLinkProps>
) => {
  const { children, ...rest } = props;

  return (
    <a rel="noopener noreferrer" target="_blank" {...rest}>
      {children}
    </a>
  );
};

export const ExternalIcon: React.FC<ExternalLinkProps> = (
  props: PropsWithChildren<ExternalLinkProps>
) => {
  return (
    <ExternalLink {...props}>
      <ExternalLinkIcon className="external-link-icon" />
    </ExternalLink>
  );
};

export default ExternalLink;
