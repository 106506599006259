import React from "react";
import AuctionCountdown from "components/AuctionCountdown/AuctionCountdown";

import BasicAuctionStat from "components/BasisAuctionStat/BasicAuctionStat";

import "./PreAuctionPanel.css";
import { useCountdownQuick } from "hooks/useCountdown";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";
import { etherscanAddress, formatUnits, now } from "web3/utils";
import { useAuctionContext } from "contexts/AuctionContext";
import { ExternalLink as ExternalLinkIcon } from "react-feather";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { toPrice } from "web3/prices";
import { stabilisationCaseToAuctionType } from "views/Auction";
import { Cases } from "web3/contracts/AuctionHouse";

interface PreAuctionProps {
  title?: string;
}

const BLOCK_RATE = 13.25;

export const PreAuctionPanel: React.FC<PreAuctionProps> = ({
  title = "Next Auction",
}: PreAuctionProps) => {
  const { blockNumber, auctionHouse, monetary, basket, twap } =
    useWeb3Contracts();
  const { nextAuctionBlock } = auctionHouse;
  const { targetPriceInEth, targetPriceInUsd } = monetary;
  const { basketFactor } = basket;
  const { oldestValidObservation, floatEthTwapPrice, waitingForTwap } = twap;

  const {
    starting,
    startAuction,
    floatMarketPriceInEth,
    floatMarketPriceInUsd,
  } = useAuctionContext();

  const blockDelta =
    nextAuctionBlock && !waitingForTwap && blockNumber
      ? nextAuctionBlock - blockNumber
      : 0;

  const estimateTime = React.useMemo(() => {
    const blockEstimate = now() + blockDelta * BLOCK_RATE;

    if (!oldestValidObservation?.slotActivelyUsedFrom) {
      return blockEstimate;
    }

    const twapEstimate = oldestValidObservation.slotActivelyUsedFrom;

    return twapEstimate > now() && twapEstimate > blockEstimate
      ? twapEstimate
      : blockEstimate;
  }, [blockDelta, oldestValidObservation?.slotActivelyUsedFrom]);

  const stillCooling = blockDelta > 0;
  const [timeToGo] = useCountdownQuick(
    stillCooling || waitingForTwap ? estimateTime : undefined
  );

  const expectedCase = React.useMemo(() => {
    const marketDemand = floatEthTwapPrice?.gte(targetPriceInEth ?? 0);
    const basketDemand = basketFactor?.gte(toPrice(1));
    if (marketDemand) {
      if (basketDemand) {
        return Cases.Up;
      } else {
        return Cases.Restock;
      }
    } else {
      if (basketDemand) {
        return Cases.Confidence;
      } else {
        return Cases.Down;
      }
    }
  }, [floatEthTwapPrice, targetPriceInEth, basketFactor]);

  return (
    <div className="pre-auction-panel-container">
      <div className="pre-auction-panel-title">
        <h1>
          {title}{" "}
          <ExternalLink href={etherscanAddress(auctionHouse.contract.address)}>
            <ExternalLinkIcon />
          </ExternalLink>
        </h1>
      </div>

      <div>
        <AuctionCountdown
          starting={starting}
          canStart={!stillCooling}
          waiting={waitingForTwap}
          onStart={startAuction}
          title={
            stillCooling || waitingForTwap
              ? "Auction can start in"
              : "Start Auction"
          }
          timeToGo={timeToGo}
          blockDelta={blockDelta}
        />
      </div>

      <div className="pre-auction-panel-bottom">
        <h3>{stabilisationCaseToAuctionType(expectedCase)}</h3>
      </div>
      <div className="pre-auction-panel-bottom">
        <BasicAuctionStat
          title={"Target Price"}
          content={
            targetPriceInUsd ? `$${formatUnits(targetPriceInUsd, 27, 3)}` : "-"
          }
          footnote={
            targetPriceInEth && `${formatUnits(targetPriceInEth, 27, 8)} Ξ`
          }
          gradientContent
          purpleContent
        />
        <BasicAuctionStat
          title={"Basket Factor"}
          content={basketFactor && `${formatUnits(basketFactor, 25, 2)}%`}
          gradientContent
        />
        <BasicAuctionStat
          title={"Market Price"}
          content={
            floatMarketPriceInUsd
              ? `$${formatUnits(floatMarketPriceInUsd, 27, 3)}`
              : "-"
          }
          gradientContent
          footnote={
            floatMarketPriceInEth &&
            `${formatUnits(floatMarketPriceInEth, 27, 8)} Ξ`
          }
        />
      </div>
    </div>
  );
};
