import LoadingButton from "components/LoadingButton/LoadingButton";
import { useInput } from "hooks/useInput";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

import "../MaxForm/MaxForm.css";

interface ConfirmationFormProps {
  onSubmit(value: string): void;
  loading: boolean;
  onCancel?: VoidFunction;
  infoText?: string;
  onMaxText?: string;
}

const ConfirmationForm: React.FC<ConfirmationFormProps> = ({
  loading,
  onSubmit,
  onCancel,
  infoText,
}: ConfirmationFormProps) => {
  const { value } = useInput("0");

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    onSubmit(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="user-input-container"></div>

      <p>{infoText}</p>

      <div className="cancel-confirm-container">
        <button type="button" onClick={onCancel}>
          Cancel
        </button>
        <LoadingButton loading={loading} text="Confirm" />
      </div>
    </form>
  );
};

export default ConfirmationForm;
