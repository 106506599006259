import React from "react";

import { AbiItem } from "web3-utils";

import { useReload } from "hooks/useReload";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useWallet } from "wallets/wallet";
import { usePreferences } from "contexts/PreferencesContext";
import Web3Contract from "web3/Web3Contract";

import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { AddressZero } from "@ethersproject/constants";
import { JsonFragment } from "@ethersproject/abi";
import {
  ABIS,
  DEXS,
  getDeployment,
  getRouters,
  getWETH9,
  now,
} from "web3/utils";
import { Contract, PopulatedTransaction } from "@ethersproject/contracts";
import { FEITokenMeta } from "./StakeTokens";

export interface MintingCeremonyData {
  totalSupply?: BigNumber;
  underlying?: string;

  balance?: BigNumber;
  allowance?: BigNumber;

  floatFromEth?: BigNumber;

  ceremonyStart?: number;
  ceremonyEnd?: number;
}

const initialData: MintingCeremonyData = {
  totalSupply: undefined,
  underlying: undefined,
  balance: undefined,
  allowance: undefined,
  floatFromEth: undefined,
  ceremonyStart: undefined,
  ceremonyEnd: undefined,
};

interface ContractProps {
  abi: AbiItem[];
  addr: string;
  name: string;
}

export interface MintingCeremonyContract extends MintingCeremonyData {
  contract: Web3Contract;
  reload: VoidFunction;
  commit(underlyingIn: BigNumberish, floatOutMin: BigNumberish): Promise<void>;
  mint(): Promise<void>;
}

function useMintingCeremonyContract({
  abi,
  addr,
  name,
}: ContractProps): MintingCeremonyContract {
  const [reload] = useReload();
  const wallet = useWallet();
  const { txn } = usePreferences();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  const [data, setData] = React.useState<MintingCeremonyData>(initialData);

  useAsyncEffect(async () => {
    const [totalSupply, underlying, ceremonyStart, ceremonyEnd, floatFromEth] =
      await contract.batch([
        {
          method: "totalSupply",
          transform: (value: string) => BigNumber.from(value),
        },
        {
          method: "underlying",
          transform: (value: string) => value.toString(),
        },
        {
          method: "startWindow",
          transform: (value: string) => Number(value),
        },
        {
          method: "endWindow",
          transform: (value: string) => Number(value),
        },
        {
          method: "quote",
          methodArgs: [BigNumber.from(10).pow(27)],
          transform: (value: string) => BigNumber.from(value),
        },
      ]);

    setData((prevState) => ({
      ...prevState,
      totalSupply,
      underlying,
      ceremonyStart,
      ceremonyEnd,
      floatFromEth,
    }));
  }, [reload]);

  useAsyncEffect(async () => {
    let balance: BigNumber | undefined;
    let allowance: BigNumber | undefined;

    if (wallet.account) {
      [balance, allowance] = await contract.batch([
        {
          method: "balanceOf",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
        {
          method: "allowance",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
      ]);
    }

    setData((prevState) => ({
      ...prevState,
      balance,
      allowance,
    }));
  }, [reload, wallet.account]);

  const commit = React.useCallback(
    (underlyingIn, floatOutMin) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      return contract
        .send("commit", [wallet.account, underlyingIn, floatOutMin], {
          from: wallet.account,
          type: txn.type,
        })
        .then(reload);
    },
    [wallet.account, contract, txn.type, reload]
  );

  const mint = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject();
    }

    return contract
      .send("mint", [], {
        from: wallet.account,
        type: txn.type,
      })
      .then(reload);
  }, [wallet.account, contract, txn.type, reload]);

  return React.useMemo<MintingCeremonyContract>(
    () => ({
      ...data,
      contract,
      reload,
      mint,
      commit,
    }),
    [data, contract, reload, mint, commit]
  );
}

export interface ZapInMintingCeremony {
  contract: Web3Contract;
  reload: VoidFunction;
  zapIn(
    fromToken: string,
    amountIn: BigNumberish,
    minFloatOut: BigNumberish
  ): Promise<void>;
}

export function useFloatMintingCeremony(): MintingCeremonyContract {
  return useMintingCeremonyContract({
    abi: getDeployment().contracts.MintingCeremony.abi,
    addr: getDeployment().contracts.MintingCeremony.address,
    name: "FLOAT_MINTING_CEREMONY",
  });
}

interface ZapInMintingCeremonyProps extends Partial<ContractProps> {
  ceremonyAddress?: string;
  weth?: string;
  routers?: DEXS;
}

export function useZapInMintingCeremony({
  abi = getDeployment().contracts.ZapInFloatMintingCeremony.abi,
  addr = getDeployment().contracts.ZapInFloatMintingCeremony.address,
  name = "ZAPIN_MINTING_CEREMONY",
  ceremonyAddress = getDeployment().contracts.MintingCeremony.address,
  weth = getWETH9(),
  routers = getRouters(),
}: ZapInMintingCeremonyProps): ZapInMintingCeremony {
  const [reload] = useReload();
  const wallet = useWallet();
  const { txn } = usePreferences();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  const zapIn = React.useCallback(
    async (
      fromToken: string,
      amountIn: BigNumberish,
      minFloatOut: BigNumberish
    ) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      const intermediateToken = weth;

      let swapTxn: PopulatedTransaction;
      if (fromToken === AddressZero) {
        const weth9 = new Contract(weth, ABIS.WETH9 as JsonFragment[]);
        swapTxn = await weth9.populateTransaction.deposit();
      } else if (fromToken === FEITokenMeta.address) {
        const path = [fromToken, intermediateToken];
        const routerContract = new Contract(
          routers.uni,
          ABIS.UNISWAP_V2_ROUTER as JsonFragment[]
        );
        swapTxn =
          await routerContract.populateTransaction.swapExactTokensForTokens(
            amountIn,
            0, // Taken care of by the zap
            path,
            addr,
            now() + 30 * 60
          );
      } else {
        const path = [fromToken, intermediateToken];
        const routerContract = new Contract(
          routers.sushi,
          ABIS.UNISWAP_V2_ROUTER as JsonFragment[]
        );
        swapTxn =
          await routerContract.populateTransaction.swapExactTokensForTokens(
            amountIn,
            0, // Taken care of by the zap
            path,
            addr,
            now() + 30 * 60
          );
      }

      const affiliate = "0x383dF49ad1f0219759a46399fE33Cb7A63cd051c"; // MultiSig, unused
      const max = false;

      return contract
        .send(
          "ZapIn",
          [
            fromToken,
            amountIn,
            ceremonyAddress,
            minFloatOut,
            intermediateToken,
            swapTxn.to,
            swapTxn.data,
            affiliate,
            max,
          ],
          {
            from: wallet.account,
            value: fromToken === AddressZero ? amountIn : undefined,
            type: txn.type,
          }
        )
        .then(reload);
    },
    [
      wallet.account,
      routers,
      weth,
      addr,
      contract,
      ceremonyAddress,
      txn.type,
      reload,
    ]
  );

  return React.useMemo<ZapInMintingCeremony>(
    () => ({
      contract,
      reload,
      zapIn,
    }),
    [contract, reload, zapIn]
  );
}
