import React, { useEffect } from "react";

import { AbiItem } from "web3-utils";

import { useReload } from "hooks/useReload";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useWallet } from "wallets/wallet";
import { usePreferences } from "contexts/PreferencesContext";
import Web3Contract from "web3/Web3Contract";

import { BigNumber } from "@ethersproject/bignumber";
import {
  getDeployment,
  percentageFromBigNumbers,
  toBigNumber,
  toNumber,
} from "web3/utils";
import { Pool, PoolData, PoolType } from "./Pool";

interface BonusScaling {
  min: BigNumber;
  max: BigNumber;
  period: BigNumber;
}

interface Stake {
  amount: BigNumber;
  timestamp: number;
}

interface UserData {
  totalStake: BigNumber;
  stakes: Stake[];
}

interface RewardSchedule {
  amount?: BigNumber;
  amountLocked?: BigNumber;
  duration?: number;
  start?: number;
  updated?: number;
}

interface MultiplierPoolData extends PoolData {
  bonusScaling?: BonusScaling;
  userData?: UserData;
  hardLockPeriod?: number;
  lastUpdatedTime?: number;
  lockedRewardAmount?: BigNumber;
  pendingRewardAmount?: BigNumber;
  unlockedRewardAmount?: BigNumber;
  rewardSchedules?: RewardSchedule[];
}

const initialData: MultiplierPoolData = {
  poolType: PoolType.MULTIPLIER,
  proportionOfPool: "-",
};

interface UseMultiplierPoolProps {
  abi: AbiItem[];
  addr: string;
  name: string;
}

export type MultiplierPool = Pool<MultiplierPoolData>;

function useMultiplierPool({
  abi,
  addr,
  name,
}: UseMultiplierPoolProps): MultiplierPool {
  const [reload] = useReload();
  const wallet = useWallet();
  const { txn } = usePreferences();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  const [data, setData] = React.useState<PoolData>(initialData);

  useAsyncEffect(async () => {
    const [
      totalStaked,
      hardLockPeriod,
      lastUpdatedTime,
      lockedRewardAmount,
      pendingRewardAmount,
      unlockedRewardAmount,
      rewardSchedules,
    ] = await contract.batch([
      {
        method: "totalSupply",
        transform: toBigNumber,
      },
      {
        method: "hardLockPeriod",
        transform: (value: string) => Number(value),
      },
      {
        method: "lastUpdatedTime",
        transform: (value: string) => Number(value),
      },
      {
        method: "lockedRewardAmount",
        transform: toBigNumber,
      },
      {
        method: "pendingRewardAmount",
        transform: toBigNumber,
      },
      {
        method: "unlockedRewardAmount",
        transform: toBigNumber,
      },
      {
        method: "rewardSchedules",
        transform: (rewardSchedules: any[]): RewardSchedule[] =>
          rewardSchedules.map((rs) => ({
            amount: toBigNumber(rs.amount),
            amountLocked: toBigNumber(rs.amountLocked),
            duration: toNumber(rs.duration),
            start: toNumber(rs.start),
            updated: toNumber(rs.updated),
          })),
      },
    ]);

    const rewardSchedulesCast = (rewardSchedules ?? []) as RewardSchedule[];

    const rewardStart = rewardSchedulesCast.reduce<number | undefined>(
      (start, rs) => {
        if (!rs || !rs.amountLocked || !rs.start || rs.amountLocked.eq(0)) {
          return start;
        }

        if (rs.start < (start ?? 1e15)) {
          return rs.start;
        }

        return start;
      },
      undefined
    );

    const rewardEnd = rewardSchedulesCast.reduce<number | undefined>(
      (end, rs) => {
        if (
          !rs ||
          !rs.amountLocked ||
          !rs.start ||
          !rs.duration ||
          rs.amountLocked.eq(0)
        ) {
          return end;
        }

        if (rs.start + rs.duration > (end ?? 0)) {
          return rs.start + rs.duration;
        }

        return end;
      },
      undefined
    );

    const totalReward = rewardSchedulesCast.reduce<BigNumber | undefined>(
      (total, rs) =>
        rs?.amount && rs?.amountLocked?.gt(0)
          ? (total ?? BigNumber.from(0)).add(rs.amount)
          : total,
      undefined
    );

    setData((prevState) => ({
      ...prevState,
      totalStaked,
      totalReward,
      hardLockPeriod,
      lastUpdatedTime,
      lockedRewardAmount,
      pendingRewardAmount,
      unlockedRewardAmount,
      rewardSchedules,
      duration: rewardStart && rewardEnd ? rewardEnd - rewardStart : undefined,
    }));
  }, [reload]);

  useAsyncEffect(async () => {
    if (wallet.account) {
      const [earned, staked, userData] = await contract.batch([
        {
          method: "earned",
          methodArgs: [wallet.account],
          transform: toBigNumber,
        },
        {
          method: "balanceOf",
          methodArgs: [wallet.account],
          transform: toBigNumber,
        },
        {
          method: "getUserData",
          methodArgs: [wallet.account],
          transform: (value: any) => {
            return {
              totalStake: toBigNumber(value.totalStake),
              stakes: value.stakes.map((s: any) => ({
                amount: toBigNumber(s.amount),
                timestamp: toNumber(s.timestamp),
              })),
            };
          },
        },
      ]);

      setData((prevState) => ({
        ...prevState,
        earned,
        staked,
        userData,
      }));
    }
  }, [reload, wallet.account]);

  useEffect(() => {
    const proportionOfPool = percentageFromBigNumbers(
      data.staked,
      data.totalStaked
    );

    setData((prevState) => ({
      ...prevState,
      proportionOfPool,
    }));
  }, [data.staked, data.totalStaked]);

  const stake = React.useCallback(
    (amount) => {
      if (!wallet.account) {
        return Promise.reject();
      }
      return contract
        .send("stake", [amount], {
          from: wallet.account,
          type: txn.type,
        })
        .then(reload);
    },
    [wallet.account, contract, txn.type, reload]
  );

  const withdraw = React.useCallback(
    (amount) => {
      if (!wallet.account) {
        return Promise.reject();
      }
      return contract
        .send("withdraw", [BigNumber.from(amount)], {
          from: wallet.account,
          type: txn.type,
        })
        .then(reload);
    },
    [contract, reload, txn.type, wallet.account]
  );

  const claim = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject();
    }
    return contract
      .send("getReward", [], {
        from: wallet.account,
        type: txn.type,
      })
      .then(reload);
  }, [wallet.account, txn.type, contract, reload]);

  const exit = React.useCallback(() => {
    if (!wallet.account) {
      return Promise.reject();
    }
    return contract
      .send("exit", [], {
        from: wallet.account,
        type: txn.type,
      })
      .then(reload);
  }, [wallet.account, contract, txn.type, reload]);

  return React.useMemo<MultiplierPool>(
    () => ({
      ...data,
      contract,
      reload,
      stake,
      withdraw,
      claim,
      exit,
    }),
    [data, contract, reload, stake, withdraw, claim, exit]
  );
}

export function useBankMultiplierPool(): MultiplierPool {
  return useMultiplierPool({
    abi: getDeployment().contracts.Bank_MultiplierPool.abi,
    addr: getDeployment().contracts.Bank_MultiplierPool.address,
    name: "BANK_MULTIPLIERPOOL",
  });
}

export function useBankEthSlpMultiplierPool(): MultiplierPool {
  return useMultiplierPool({
    abi: getDeployment().contracts.BankEthLP_MultiplierPool.abi,
    addr: getDeployment().contracts.BankEthLP_MultiplierPool.address,
    name: "BANK_ETH_SLP_MULTIPLIERPOOL",
  });
}

export function useFloatEthSlpMultiplierPool(): MultiplierPool {
  return useMultiplierPool({
    abi: getDeployment().contracts.FloatEthLP_MultiplierPool.abi,
    addr: getDeployment().contracts.FloatEthLP_MultiplierPool.address,
    name: "FLOAT_ETH_SLP_MULTIPLIERPOOL",
  });
}

export function useGuniFloatUsdcMultiplierPool(): MultiplierPool {
  return useMultiplierPool({
    abi: getDeployment().contracts.GUNI_FLOAT_USDC_MultiplierPool.abi,
    addr: getDeployment().contracts.GUNI_FLOAT_USDC_MultiplierPool.address,
    name: "GUNI_FLOAT_USDC_MULTIPLIERPOOL",
  });
}
