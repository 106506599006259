import React, { ReactElement } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { useExchange, TradeChoice } from "contexts/ExchangeContext";

import ETHLogo from "assets/tokens/ETH.png";
import DAILogo from "assets/tokens/DAI.png";
import USDCLogo from "assets/tokens/USDC.png";
import FEILogo from "assets/tokens/FEI.png";
import USDTLogo from "assets/tokens/USDT.png";
import wETHLogo from "assets/tokens/wETH.png";

const StyledModal = styled(Modal)`
  height: auto;
  // width: 100%;
  max-width: 50ch;
  margin: auto;
  // max-width: 420px;
  position: absolute;

  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border-radius: 4px;

  outline: none;
  background: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  transform: translate(-50%, -50%);

  border-radius: 4px;
  outline: none;
  max-height: 90vh;
  overflow-y: auto;
`;

const StyledCurrencyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledCurrencyButton = styled.button`
  margin: 1rem 0;
  background-color: transparent;
  color: #000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-width: 0.1rem;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  transition: all 300ms ease 0s;

  &:hover {
    border-color: var(--bg-color-3);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
`;

const StyledImage = styled.img`
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
`;

interface CurrencySelectModalProps {
  isOpen: boolean;
  onCancel: VoidFunction;
  className?: string;
  modalTitle?: React.ReactNode;
  modalSubtitle?: React.ReactNode;
  modalBody?: React.ReactNode;
}

function CurrencySelectModal({
  isOpen,
  onCancel,
  className,
  modalTitle,
  modalSubtitle,
  modalBody,
}: CurrencySelectModalProps): ReactElement {
  const { onCurrencySelect } = useExchange();

  return (
    <StyledModal
      className={`general-modal ${className}`}
      style={{
        overlay: {
          backgroundColor: "rgb(255, 255, 255, 0.4)",
        },
      }}
      isOpen={isOpen}
      onRequestClose={onCancel}
    >
      <div className="general-modal-container">
        <div className="modal-header-center">
          <div className="modal-header-main">
            <p className="modal-title">{modalTitle}</p>
            <p className="modal-subtitle">{modalSubtitle}</p>
          </div>
        </div>

        <div className="modal-body">{modalBody}</div>
        <StyledCurrencyList>
          <StyledCurrencyButton
            type="button"
            onClick={() => onCurrencySelect("ETH", TradeChoice.ETH)}
          >
            <StyledImage src={ETHLogo} alt="ETH" />
            ETH
          </StyledCurrencyButton>
          <StyledCurrencyButton
            type="button"
            onClick={() => onCurrencySelect("DAI", TradeChoice.DAI)}
          >
            <StyledImage src={DAILogo} alt="DAI" />
            DAI
          </StyledCurrencyButton>
          <StyledCurrencyButton
            type="button"
            onClick={() => onCurrencySelect("USDC", TradeChoice.USDC)}
          >
            <StyledImage src={USDCLogo} alt="USDC" />
            USDC
          </StyledCurrencyButton>
          <StyledCurrencyButton
            type="button"
            onClick={() => onCurrencySelect("FEI", TradeChoice.FEI)}
          >
            <StyledImage src={FEILogo} alt="USDC" />
            FEI
          </StyledCurrencyButton>
          <StyledCurrencyButton
            type="button"
            onClick={() => onCurrencySelect("USDT", TradeChoice.USDT)}
          >
            <StyledImage src={USDTLogo} alt="USDT" />
            USDT
          </StyledCurrencyButton>
          <StyledCurrencyButton
            type="button"
            onClick={() => onCurrencySelect("wETH", TradeChoice.WETH)}
          >
            <StyledImage src={wETHLogo} alt="wETH" />
            wETH
          </StyledCurrencyButton>
        </StyledCurrencyList>
      </div>
    </StyledModal>
  );
}

export default CurrencySelectModal;
