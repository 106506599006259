import React, { ReactNode } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import TopBar from "components/TopBar/TopBar";
import TopBarMenu from "components/TopBar/TopBarMenu";
import Footer from "components/Footer/Footer";
import Banner from "components/Banner/Banner";
import SplashView from "views/Splash";

import config from "assets/config.json";
import LandingView from "views/Landing";
import PoolsView from "views/Pools";
import AuctionView from "views/Auction";
import GenesisView from "views/Genesis";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";
import { etherscanBlock } from "web3/utils";

import "./BlockCounter.css";
import ExpiredPoolsView from "views/ExpiredPools";

const BlockCounter: React.FC = () => {
  const { blockNumber } = useWeb3Contracts();
  return (
    <div className="block-counter">
      <ExternalLink href={etherscanBlock(blockNumber?.toString())}>
        {blockNumber}
      </ExternalLink>
      <span className={`dot ${!blockNumber ? "off" : ""}`} />
    </div>
  );
};

const NormalView: React.FC = ({ children }: { children?: ReactNode }) => {
  const [menuState, setMenuState] = React.useState(false);

  return (
    <>
      <div className="App-header">
        <Banner
          content={
            <div>
              <p>
                {" "}
                As always &quot;
                <ExternalLink href="https://floatprotocol.medium.com/did-float-pass-its-audit-report-5aed10ff4daf">
                  Verify, do not trust
                </ExternalLink>
                .&quot;
              </p>
            </div>
          }
        />
        <TopBar
          showConnect={true}
          setMenuState={setMenuState}
          menuState={menuState}
        />
      </div>
      <TopBarMenu menuState={menuState} setMenuState={setMenuState} />
      <div className="App background-wave">
        {children}
        <Footer />
      </div>
      <BlockCounter />
    </>
  );
};

const LandingWrapper: React.FC = ({ children }: { children?: ReactNode }) => {
  const [menuState, setMenuState] = React.useState(false);

  return (
    <>
      <div className="App-header banner-spacing">
        <TopBar
          showConnect={false}
          setMenuState={setMenuState}
          menuState={menuState}
        />
      </div>
      <TopBarMenu menuState={menuState} setMenuState={setMenuState} />
      <div className="App background-gradient">
        {children}
        <Footer />
      </div>
      <BlockCounter />
    </>
  );
};

const LayoutView: React.FC = () => {
  return (
    <>
      <Switch>
        <Redirect exact from="/exchange" to="/genesis" />
        <Redirect exact from="/stake" to="/pools/expired" />
        <Route exact path="/">
          <LandingWrapper>
            <LandingView />
          </LandingWrapper>
        </Route>
        <Route path="/pools/expired">
          <NormalView>
            <ExpiredPoolsView />
          </NormalView>
        </Route>
        <Route path="/pools">
          <NormalView>
            <PoolsView />
          </NormalView>
        </Route>
        <Route path="/splash">
          <SplashView toTime={config.phase_1_start} />
        </Route>
        <Route path="/auction">
          <NormalView>
            <AuctionView />
          </NormalView>
        </Route>
        <Route path="/genesis">
          <NormalView>
            <GenesisView />
          </NormalView>
        </Route>
      </Switch>
    </>
  );
};

export default LayoutView;
