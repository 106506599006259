import React, { useEffect } from "react";

import { AbiItem } from "web3-utils";

import { useReload } from "hooks/useReload";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useWallet } from "wallets/wallet";
import Web3Contract from "web3/Web3Contract";

import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { getDeployment, now, toBigNumber, toNumber } from "web3/utils";

import { usePreferences } from "contexts/PreferencesContext";

export enum Cases {
  Up,
  Restock,
  Confidence,
  Down,
}

export enum Stage {
  AuctionCooldown,
  AuctionPending,
  AuctionActive,
}

export const AUCTION_DURATION = 150 - 1;
export const AUCTION_COOLDOWN = 3250;

export interface AuctionData {
  stabilisationCase: Cases;
  targetFloatInEth: BigNumber;
  marketFloatInEth: BigNumber;
  bankInEth: BigNumber;
  startWethPrice: BigNumber;
  startBankPrice: BigNumber;
  endWethPrice: BigNumber;
  endBankPrice: BigNumber;
  basketFactor: BigNumber;
  delta: BigNumber;
  allowance: BigNumber;
}

export interface AuctionHouseData {
  stage?: Stage;
  lastAuctionBlock?: number;
  nextAuctionBlock?: number;

  AUCTION_DURATION: number;
  AUCTION_COOLDOWN: number;

  wethPrice?: BigNumber;
  bankPrice?: BigNumber;
  step?: number;
  latestAuction?: AuctionData;

  buffer?: BigNumber;
  protocolFee?: BigNumber;
  allowanceCap?: BigNumber;

  round?: BigNumber;
  auctions?: BigNumber;
}

const initialData: AuctionHouseData = {
  stage: undefined,
  lastAuctionBlock: undefined,
  nextAuctionBlock: undefined,

  AUCTION_DURATION,
  AUCTION_COOLDOWN,

  wethPrice: undefined,
  bankPrice: undefined,
  step: undefined,
  latestAuction: undefined,

  buffer: undefined,
  protocolFee: undefined,
  allowanceCap: undefined,

  round: undefined,
  auctions: undefined,
};

interface ContractProps {
  abi: AbiItem[];
  addr: string;
  name: string;
}

export interface AuctionHouseContract extends AuctionHouseData {
  contract: Web3Contract;
  reload: VoidFunction;
  onNewBlock: VoidFunction;
  start(): Promise<void>;
  buy(
    wethInMax: BigNumberish,
    bankInMax: BigNumberish,
    floatOutMin: BigNumberish
  ): Promise<void>;
  sell(
    floatIn: BigNumberish,
    wethOutMin: BigNumberish,
    bankOutMin: BigNumberish
  ): Promise<void>;
}

function useAuctionHouseContract({
  abi,
  addr,
  name,
}: ContractProps): AuctionHouseContract {
  const [reload] = useReload();
  const [onNewBlock] = useReload();

  const wallet = useWallet();
  const { txn } = usePreferences();
  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  const [data, setData] = React.useState<AuctionHouseData>(initialData);

  React.useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      AUCTION_COOLDOWN:
        wallet.networkId === undefined || wallet.networkId === 1
          ? AUCTION_COOLDOWN
          : 0,
    }));
  }, [wallet.networkId]);

  useAsyncEffect(async () => {
    const [stage, lastAuctionBlock, latestAuction] = await contract.batch([
      {
        method: "stage",
        transform: (val: string) => Number(val) as Stage,
      },
      {
        method: "lastAuctionBlock",
        transform: toNumber,
      },
      {
        method: "latestAuction",
        transform: (value: any) => ({
          stabilisationCase: Number(value.stabilisationCase),
          targetFloatInEth: toBigNumber(value.targetFloatInEth),
          marketFloatInEth: toBigNumber(value.marketFloatInEth),
          bankInEth: toBigNumber(value.bankInEth),
          startWethPrice: toBigNumber(value.startWethPrice),
          startBankPrice: toBigNumber(value.startBankPrice),
          endWethPrice: toBigNumber(value.endWethPrice),
          endBankPrice: toBigNumber(value.endBankPrice),
          basketFactor: toBigNumber(value.basketFactor),
          delta: toBigNumber(value.delta),
          allowance: toBigNumber(value.allowance),
        }),
      },
    ]);

    setData((prevState) => ({
      ...prevState,
      stage,
      lastAuctionBlock,
      latestAuction,
    }));
  }, [reload]);

  useEffect(() => {
    const nextAuctionBlock =
      data.lastAuctionBlock &&
      data.lastAuctionBlock + data.AUCTION_DURATION + data.AUCTION_COOLDOWN;

    setData((prevState) => ({
      ...prevState,
      nextAuctionBlock,
    }));
  }, [data.lastAuctionBlock, data.AUCTION_DURATION, data.AUCTION_COOLDOWN]);

  useAsyncEffect(async () => {
    if (data.stage !== Stage.AuctionActive) {
      return;
    }

    const notOver = data.step && data.step < data.AUCTION_DURATION;

    const includeIfNotOver = notOver
      ? [
          {
            method: "price",
            transform: (price: {
              wethPrice: string | undefined;
              bankPrice: string | undefined;
            }) => {
              return [
                toBigNumber(price.wethPrice),
                toBigNumber(price.bankPrice),
              ];
            },
          },
        ]
      : [];

    const [step, latestAuction, price] = await contract.batch([
      {
        method: "step",
        transform: toNumber,
      },
      {
        method: "latestAuction",
        transform: (value: any) => ({
          stabilisationCase: Number(value.stabilisationCase),
          targetFloatInEth: toBigNumber(value.targetFloatInEth),
          marketFloatInEth: toBigNumber(value.marketFloatInEth),
          bankInEth: toBigNumber(value.bankInEth),
          startWethPrice: toBigNumber(value.startWethPrice),
          startBankPrice: toBigNumber(value.startBankPrice),
          endWethPrice: toBigNumber(value.endWethPrice),
          endBankPrice: toBigNumber(value.endBankPrice),
          basketFactor: toBigNumber(value.basketFactor),
          delta: toBigNumber(value.delta),
          allowance: toBigNumber(value.allowance),
        }),
      },
      ...includeIfNotOver,
    ]);

    const [wethPrice, bankPrice] = price ?? [undefined, undefined];

    setData((prevState) => ({
      ...prevState,
      wethPrice,
      bankPrice,
      step,
      latestAuction,
    }));
  }, [reload, onNewBlock, data.stage, data.step]);

  const start = React.useCallback(async () => {
    if (!wallet.account) {
      return Promise.reject();
    }

    return contract
      .send("start", [], {
        from: wallet.account,
        type: txn.type,
      })
      .then(reload);
  }, [contract, reload, txn.type, wallet.account]);

  const buy = React.useCallback(
    async (wethInMax, bankInMax, floatOutMin) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      if (
        data.latestAuction?.stabilisationCase !== Cases.Up &&
        data.latestAuction?.stabilisationCase !== Cases.Restock
      ) {
        return Promise.reject("Can't buy in contraction");
      }

      // 0.05% / 0.9995%
      const max = 10_000;
      const slippage = 5; // basis points
      const floatOutMinWithSlippage = floatOutMin.mul(max - slippage).div(max);

      return contract
        .send(
          "buy",
          [
            wethInMax,
            bankInMax,
            floatOutMinWithSlippage,
            wallet.account,
            now() + 15 * 60,
          ],
          {
            from: wallet.account,
            type: txn.type,
          }
        )
        .then(reload);
    },
    [
      contract,
      data.latestAuction?.stabilisationCase,
      reload,
      txn.type,
      wallet.account,
    ]
  );

  const sell = React.useCallback(
    async (floatIn, wethOutMin, floatOutMin) => {
      if (!wallet.account) {
        return Promise.reject();
      }

      if (
        data.latestAuction?.stabilisationCase !== Cases.Confidence &&
        data.latestAuction?.stabilisationCase !== Cases.Down
      ) {
        return Promise.reject("Can't sell in expansion");
      }

      const max = 10_000;
      const slippage = 5; // basis points
      const wethOutMinWithSlippage = wethOutMin.mul(max - slippage).div(max);
      const floatOutMinWithSlippage = floatOutMin.mul(max - slippage).div(max);

      return contract
        .send(
          "sell",
          [
            floatIn,
            wethOutMinWithSlippage,
            floatOutMinWithSlippage,
            wallet.account,
            now() + 15 * 60,
          ],
          {
            from: wallet.account,
            type: txn.type,
          }
        )
        .then(reload);
    },
    [
      contract,
      data.latestAuction?.stabilisationCase,
      reload,
      txn.type,
      wallet.account,
    ]
  );

  return React.useMemo<AuctionHouseContract>(
    () => ({
      ...data,
      contract,
      reload,
      start,
      buy,
      sell,
      onNewBlock,
    }),
    [data, contract, reload, onNewBlock, start, buy, sell]
  );
}

export function useAuctionHouse(): AuctionHouseContract {
  return useAuctionHouseContract({
    abi: getDeployment().contracts.AuctionHouse?.abi ?? [],
    addr: getDeployment().contracts.AuctionHouse?.address ?? "",
    name: "FLOAT_AUCTION_HOUSE",
  });
}
