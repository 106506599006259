import React, { Fragment, PropsWithChildren } from "react";
import { useWallet } from "wallets/wallet";
import Modal from "react-modal";
import useClipboard from "react-use-clipboard";
import { BigNumber } from "@ethersproject/bignumber";

import "./DropdownMenu.css";
import { etherscanAddress, formatUnits, shortenString } from "web3/utils";
import {
  BANKTokenMeta,
  FLOATTokenMeta,
} from "web3/contracts/TokenContractWithSupply";
import { ETHTokenMeta } from "web3/contracts/StakeTokens";
import {
  bankSLPTokenMeta,
  floatSLPTokenMeta,
} from "web3/contracts/LiquidityProvider";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";
import { TokenMeta } from "web3/TokenMeta";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ExternalLink as ExternalLinkIcon } from "react-feather";
import { SendableTransactionType } from "hooks/useTransactionType";
import { usePreferences } from "contexts/PreferencesContext";

Modal.setAppElement("#root");

interface DropdownMenuProps {
  isOpen?: boolean;
  onClose?: VoidFunction;
}

const BalanceGroup: React.FC = () => {
  const { bank, eth, floatMintingCeremony, float, floatEthSLP, bankEthSLP } =
    useWeb3Contracts();

  const balances: [TokenMeta, BigNumber?][] = [
    [BANKTokenMeta, bank.balance],
    [FLOATTokenMeta, floatMintingCeremony.balance?.add(float.balance ?? 0)],
    [bankSLPTokenMeta, bankEthSLP.balance],
    [floatSLPTokenMeta, floatEthSLP.balance],
    [ETHTokenMeta, eth.balance],
  ];

  return (
    <div className="balances-group">
      {balances.map(([meta, bal], idx) => (
        <Fragment key={idx}>
          {bal?.gt(0) && (
            <DropdownItem>
              {meta.icon}
              <p>
                {formatUnits(bal, meta.decimals, 6)} {meta.name}
              </p>
              {meta.address && (
                <ExternalLink
                  className="contract-link"
                  href={etherscanAddress(meta.address)}
                >
                  <ExternalLinkIcon />
                </ExternalLink>
              )}
            </DropdownItem>
          )}
        </Fragment>
      ))}
    </div>
  );
};

function DropdownItem(props: PropsWithChildren<{ onClick?: VoidFunction }>) {
  return (
    <span className="menu-item" onClick={props.onClick}>
      {props.children}
    </span>
  );
}

const DropdownMenu = React.forwardRef<HTMLDivElement, DropdownMenuProps>(
  ({ isOpen, onClose }: DropdownMenuProps, ref) => {
    const { account, networkName, disconnect } = useWallet();
    const onDisconnect = () => {
      disconnect();
      onClose?.();
    };
    const [isCopied, setCopied] = useClipboard(account ?? "", {
      successDuration: 5000,
    });

    const { txn } = usePreferences();

    return (
      <div ref={ref} className={`dropdown ${isOpen ? "open" : ""}`}>
        <div className="dropdown-header">
          <p className="dropdown-header-text">
            Balance ({account && shortenString(account)})
          </p>
          <p className="dropdown-header-badge">{networkName}</p>
        </div>
        <BalanceGroup />

        <div className="dropdown-spacer" />

        <p className="dropdown-group-text">Transaction type</p>
        <div className="dropdown-group">
          <button
            className={`dropdown-toggle${
              txn.type === SendableTransactionType.LEGACY ? " active" : ""
            }`}
            onClick={() => txn.setType(SendableTransactionType.LEGACY)}
          >
            Legacy
          </button>
          <button
            className={`dropdown-toggle${
              txn.type === SendableTransactionType.EIP1559 ? " active" : ""
            }`}
            onClick={() => txn.setType(SendableTransactionType.EIP1559)}
          >
            EIP1559
          </button>
        </div>

        <div className="dropdown-spacer" />

        <DropdownItem onClick={setCopied}>
          <button className="text">
            {isCopied ? "Address copied to clipboard" : "Copy Address"}
          </button>
        </DropdownItem>
        <DropdownItem onClick={onDisconnect}>
          <button className="text">Disconnect</button>
        </DropdownItem>
      </div>
    );
  }
);

export default DropdownMenu;
