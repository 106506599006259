import React, { ReactElement } from "react";
import Modal from "react-modal";

import "./FarmStatementCard.css";
import { useWallet } from "wallets/wallet";
import ConnectWalletButton from "components/ConnectWallet/ConnectWalletButton";
import { useStakeOption } from "contexts/StakeOption";
import LoadingButton from "components/LoadingButton/LoadingButton";
import { formatUnits } from "web3/utils";

Modal.setAppElement("#root");

function FarmStatementCard(): ReactElement {
  const { isActive } = useWallet();

  const {
    pool,
    token,
    startApprove,
    startWithdraw,
    startDeposit,
    approving,
    withdrawing,
    depositing,
  } = useStakeOption();
  const { staked, proportionOfPool } = pool;
  const { allowance } = token;
  const { icon, decimals } = token.tokenMeta;

  const deposit = staked ? formatUnits(staked, decimals) : "-";

  const poolAllowance = allowance[pool.contract.address];
  const hasNoAllowance = poolAllowance && poolAllowance.lte(0);
  const hasNoStake = staked && staked.lte(0);

  const ApproveOnlyButtonGroup = (
    <div className="buttons-container">
      <LoadingButton
        className="medium-button loading-button"
        text="Approve"
        loading={approving}
        onClick={startApprove}
      />
    </div>
  );

  const WithdrawAndApproveButtonGroup = (
    <div className="buttons-container">
      <LoadingButton
        className="medium-button loading-button"
        text="Withdraw"
        loading={withdrawing}
        onClick={startWithdraw}
      />
      <LoadingButton
        className="medium-button loading-button"
        text="Approve"
        loading={approving}
        onClick={startApprove}
      />
    </div>
  );

  const WithdrawAndDepositButtonGroup = (
    <div className="buttons-container">
      <LoadingButton
        className="medium-button loading-button"
        text="Withdraw"
        loading={withdrawing}
        onClick={startWithdraw}
      />
      <LoadingButton
        className="medium-button loading-button"
        text="Deposit"
        loading={depositing}
        onClick={startDeposit}
      />
    </div>
  );

  return (
    <>
      <div className="farm-statement-card-container">
        {icon}
        <p>Your Deposit</p>
        <div className="deposit-value-container">
          <div className="deposit-container">
            <h2 className="user-deposit">{deposit}</h2>
          </div>

          <p className="percentage-container">
            {" "}
            (<span className="percentage-value">{proportionOfPool}%</span> of
            total)
          </p>
        </div>

        {isActive && !hasNoAllowance && WithdrawAndDepositButtonGroup}
        {isActive && hasNoAllowance && hasNoStake && ApproveOnlyButtonGroup}
        {isActive &&
          hasNoAllowance &&
          !hasNoStake &&
          WithdrawAndApproveButtonGroup}

        {!isActive && (
          <>
            <div className="buttons-container">
              <ConnectWalletButton />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default FarmStatementCard;
