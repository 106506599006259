import React, { PropsWithChildren } from "react";
import {
  Phase1Pool,
  useDAIPhase1Pool,
  useUSDCPhase1Pool,
  useUSDTPhase1Pool,
} from "web3/contracts/Phase1Pool";
import {
  useYFIPhase2Pool,
  useYAMPhase2Pool,
  useSUSHIPhase2Pool,
  useWBTCPhase2Pool,
  useUSDTPhase2Pool,
  useUSDCPhase2Pool,
  useDAIPhase2Pool,
  useSLPPhase2Pool,
  Phase2Pool,
} from "web3/contracts/Phase2Pool";
import { useETHToken } from "web3/contracts/EthToken";
import { useETHPhase2Pool } from "web3/contracts/EthPhase2Pool";
import {
  TokenContractWithAllowance,
  useDAIContract,
  useUSDCContract,
  useUSDTContract,
  useYFIContract,
  useYAMContract,
  useSUSHIContract,
  useWBTCContract,
  useFEIContract,
  useWETHContract,
  WETH9,
} from "web3/contracts/StakeTokens";
import {
  LPToken,
  useBankEthSLPContract,
  useFloatEthSLPContract,
} from "web3/contracts/LiquidityProvider";
import {
  BANKTokenMeta,
  FLOATTokenMeta,
  TokenContractWithSupply,
  useTokenContractWithSupplyAndAllowance,
} from "web3/contracts/TokenContractWithSupply";
import { useWallet } from "wallets/wallet";
import {
  Phase4aPool,
  Phase4Pool,
  useBankEthSLPPhase4Pool,
  useBANKPhase4aPool,
  useFloatEthSLPPhase4Pool,
  useFloatPhase4Pool,
} from "web3/contracts/Phase4Pool";
import {
  MintingCeremonyContract,
  useFloatMintingCeremony,
  useZapInMintingCeremony,
  ZapInMintingCeremony,
} from "web3/contracts/MintingCeremony";
import {
  AuctionHouseContract,
  useAuctionHouse,
} from "web3/contracts/AuctionHouse";
import { useWeb3React } from "@web3-react/core";
import { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { getHttpRpcUrl } from "web3/utils";
import { MonetaryContract, useMonetary } from "web3/contracts/Monetary";
import { BasketContract, useBasketContract } from "web3/contracts/Basket";
import {
  EthUsdOracle,
  useChainlinkOracle,
} from "web3/contracts/ChainlinkEthUsdConsumer";
import { TwapContract, useTwapContract } from "web3/contracts/Twap";
import {
  MultiplierPool,
  useBankEthSlpMultiplierPool,
  useBankMultiplierPool,
  useFloatEthSlpMultiplierPool,
  useGuniFloatUsdcMultiplierPool,
} from "web3/contracts/MultiplierPool";
import { useGuniFloatUsdcContract } from "web3/contracts/GuniToken";

export interface Web3Contracts {
  blockNumber?: number;

  // Core Tokens
  bank: TokenContractWithSupply;
  float: TokenContractWithSupply;

  floatMintingCeremony: MintingCeremonyContract;
  zapInFloatMinting: ZapInMintingCeremony;

  // Auction House
  auctionHouse: AuctionHouseContract;
  monetary: MonetaryContract;
  basket: BasketContract;

  // Aux
  ethUsdOracle: EthUsdOracle;
  twap: TwapContract;

  // Staking Tokens
  eth: TokenContractWithAllowance;
  dai: TokenContractWithAllowance;
  usdt: TokenContractWithAllowance;
  usdc: TokenContractWithAllowance;
  yfi: TokenContractWithAllowance;
  yam: TokenContractWithAllowance;
  sushi: TokenContractWithAllowance;
  wbtc: TokenContractWithAllowance;
  fei: TokenContractWithAllowance;
  guniFloatUsdc: TokenContractWithAllowance;
  weth: WETH9;

  bankEthSLP: LPToken;
  floatEthSLP: LPToken;

  // Phase 1 Pools
  daiPool: Phase1Pool;
  usdtPool: Phase1Pool;
  usdcPool: Phase1Pool;

  // Phase 2 Pools
  bankEthSLPPhase2Pool: Phase2Pool;
  daiPhase2Pool: Phase2Pool;
  usdtPhase2Pool: Phase2Pool;
  usdcPhase2Pool: Phase2Pool;
  yfiPhase2Pool: Phase2Pool;
  yamPhase2Pool: Phase2Pool;
  sushiPhase2Pool: Phase2Pool;
  ethPhase2Pool: Phase2Pool;
  wbtcPhase2Pool: Phase2Pool;

  // Phase 4 Pools
  bankPhase4aPool: Phase4aPool;
  bankEthSLPPhase4Pool: Phase4Pool;
  floatEthSLPPhase4Pool: Phase4Pool;
  floatPhase4Pool: Phase4Pool;

  // Multiplier Pools
  bank_MultiplierPool: MultiplierPool;
  bankEthSLP_MultiplierPool: MultiplierPool;
  floatEthSLP_MultiplierPool: MultiplierPool;
  guniFloatUsdc_MultiplierPool: MultiplierPool;
}

const Web3ContractsContext = React.createContext<Web3Contracts>({} as any);

export function useWeb3Contracts(): Web3Contracts {
  return React.useContext(Web3ContractsContext);
}

const DEFAULT_JSON_PROVIDER = new JsonRpcProvider(getHttpRpcUrl());

const Web3ContractsProvider: React.FC = ({
  children,
}: PropsWithChildren<unknown>) => {
  const wallet = useWallet();
  const { chainId, library: signerLibrary } = useWeb3React();

  const library: JsonRpcProvider = React.useMemo(() => {
    if (signerLibrary) {
      return signerLibrary as Web3Provider;
    } else {
      return DEFAULT_JSON_PROVIDER as JsonRpcProvider;
    }
  }, [signerLibrary]);

  const [blockNumber, setBlockNumber] = React.useState<number>();

  React.useEffect((): any => {
    if (library) {
      let stale = false;

      library
        .getBlockNumber()
        .then((blockNumber: number) => {
          if (!stale) {
            setBlockNumber(blockNumber);
          }
        })
        .catch(() => {
          if (!stale) {
            setBlockNumber(undefined);
          }
        });

      const updateBlockNumber = (blockNumber: number) => {
        setBlockNumber(blockNumber);
      };
      library.on("block", updateBlockNumber);

      return () => {
        stale = true;
        library.removeListener("block", updateBlockNumber);
        setBlockNumber(undefined);
      };
    }
  }, [library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds

  const wethContract = useWETHContract();

  // LPs
  const bankEthSLPContract = useBankEthSLPContract();
  const floatEthSLPContract = useFloatEthSLPContract();

  const daiPhase2PoolContract = useDAIPhase2Pool();
  const ethPhase2PoolContract = useETHPhase2Pool();
  const bankEthSLPPhase2PoolContract = useSLPPhase2Pool();
  const sushiPhase2PoolContract = useSUSHIPhase2Pool();
  const usdcPhase2PoolContract = useUSDCPhase2Pool();
  const usdtPhase2PoolContract = useUSDTPhase2Pool();
  const wbtcPhase2PoolContract = useWBTCPhase2Pool();
  const yamPhase2PoolContract = useYAMPhase2Pool();
  const yfiPhase2PoolContract = useYFIPhase2Pool();

  const bankPhase4aPoolContract = useBANKPhase4aPool();
  const bankEthSLPPhase4PoolContract = useBankEthSLPPhase4Pool();
  const floatEthSLPPhase4PoolContract = useFloatEthSLPPhase4Pool();
  const floatPhase4PoolContract = useFloatPhase4Pool();

  const floatEthSLPMultiplierPool = useFloatEthSlpMultiplierPool();
  const bankEthSlpMultiplierPool = useBankEthSlpMultiplierPool();
  const bankMultiplierPool = useBankMultiplierPool();
  const guniFloatUsdcMultiplierPool = useGuniFloatUsdcMultiplierPool();

  const floatMintingCeremonyContract = useFloatMintingCeremony();
  const zapInFloatMintingContract = useZapInMintingCeremony({});
  const ethUsdOracleContract = useChainlinkOracle();

  const auctionHouseContract = useAuctionHouse();

  const monetaryContract = useMonetary({
    lastAuctionBlock: auctionHouseContract.lastAuctionBlock,
  });
  const basketContract = useBasketContract({
    targetPriceInEth: monetaryContract.targetPriceInEth,
    targetPriceInEthAtAuctionStart:
      monetaryContract.targetPriceInEthAtAuctionStart,
    targetPriceInEthBeforeAuctionStart:
      monetaryContract.targetPriceInEthBeforeAuctionStart,
    lastAuctionBlock: auctionHouseContract.lastAuctionBlock,
  });

  const bankContract = useTokenContractWithSupplyAndAllowance({
    tokenMeta: BANKTokenMeta,
    allowanceAddresses: [
      bankPhase4aPoolContract.contract.address,
      auctionHouseContract.contract.address,
      bankMultiplierPool.contract.address,
    ],
  });
  const floatContract = useTokenContractWithSupplyAndAllowance({
    tokenMeta: FLOATTokenMeta,
    allowanceAddresses: [
      floatPhase4PoolContract.contract.address,
      auctionHouseContract.contract.address,
    ],
  });

  const twapContract = useTwapContract({
    bankEthPair: {
      token0: bankContract.contract.address,
      token1: wethContract.contract.address,
      pair: bankEthSLPContract.contract.address,
    },
    floatEthPair: {
      token0: floatContract.contract.address,
      token1: wethContract.contract.address,
      pair: floatEthSLPContract.contract.address,
    },
  });

  const daiContract = useDAIContract();
  const ethContract = useETHToken({
    allowanceAddresses: [ethPhase2PoolContract.contract.address],
  });

  const sushiContract = useSUSHIContract();
  const usdcContract = useUSDCContract();
  const usdtContract = useUSDTContract();
  const wbtcContract = useWBTCContract();
  const yamContract = useYAMContract();
  const yfiContract = useYFIContract();
  const feiContract = useFEIContract();
  const guniFloatUsdcContract = useGuniFloatUsdcContract();

  const daiPoolContract = useDAIPhase1Pool();
  const usdcPoolContract = useUSDCPhase1Pool();
  const usdtPoolContract = useUSDTPhase1Pool();

  // Set Providers for contracts
  React.useEffect(() => {
    const contracts = [
      bankContract,
      floatContract,

      floatMintingCeremonyContract,
      zapInFloatMintingContract,

      auctionHouseContract,
      monetaryContract,
      basketContract,

      ethUsdOracleContract,

      twapContract,

      ethContract,
      daiContract,
      usdtContract,
      usdcContract,
      yfiContract,
      yamContract,
      sushiContract,
      wbtcContract,
      feiContract,
      wethContract,
      guniFloatUsdcContract,

      bankEthSLPContract,
      floatEthSLPContract,

      daiPoolContract,
      usdtPoolContract,
      usdcPoolContract,

      daiPhase2PoolContract,
      ethPhase2PoolContract,
      bankEthSLPPhase2PoolContract,
      sushiPhase2PoolContract,
      usdcPhase2PoolContract,
      usdtPhase2PoolContract,
      wbtcPhase2PoolContract,
      yamPhase2PoolContract,
      yfiPhase2PoolContract,

      bankEthSLPPhase4PoolContract,
      bankPhase4aPoolContract,
      floatEthSLPPhase4PoolContract,
      floatPhase4PoolContract,

      floatEthSLPMultiplierPool,
      bankEthSlpMultiplierPool,
      bankMultiplierPool,
      guniFloatUsdcMultiplierPool,
    ];
    contracts.forEach((contract) => {
      contract.contract.setProvider(wallet.provider);
    });
  }, [wallet.provider]); // eslint-disable-line react-hooks/exhaustive-deps

  const value = {
    blockNumber,
    bank: bankContract,
    float: floatContract,
    dai: daiContract,
    eth: ethContract,
    bankEthSLP: bankEthSLPContract,
    floatEthSLP: floatEthSLPContract,

    ethUsdOracle: ethUsdOracleContract,

    sushi: sushiContract,
    usdc: usdcContract,
    usdt: usdtContract,
    wbtc: wbtcContract,
    yam: yamContract,
    yfi: yfiContract,
    fei: feiContract,
    weth: wethContract,
    guniFloatUsdc: guniFloatUsdcContract,

    // Phase 1 Pools
    daiPool: daiPoolContract,
    usdcPool: usdcPoolContract,
    usdtPool: usdtPoolContract,

    // Phase 2 Pools
    daiPhase2Pool: daiPhase2PoolContract,
    ethPhase2Pool: ethPhase2PoolContract,
    sushiPhase2Pool: sushiPhase2PoolContract,
    usdcPhase2Pool: usdcPhase2PoolContract,
    usdtPhase2Pool: usdtPhase2PoolContract,
    wbtcPhase2Pool: wbtcPhase2PoolContract,
    yamPhase2Pool: yamPhase2PoolContract,
    yfiPhase2Pool: yfiPhase2PoolContract,
    bankEthSLPPhase2Pool: bankEthSLPPhase2PoolContract,

    // Phase 4 Pools
    bankPhase4aPool: bankPhase4aPoolContract,
    bankEthSLPPhase4Pool: bankEthSLPPhase4PoolContract,
    floatPhase4Pool: floatPhase4PoolContract,
    floatEthSLPPhase4Pool: floatEthSLPPhase4PoolContract,

    // Multiplier Pools
    floatEthSLP_MultiplierPool: floatEthSLPMultiplierPool,
    bankEthSLP_MultiplierPool: bankEthSlpMultiplierPool,
    bank_MultiplierPool: bankMultiplierPool,
    guniFloatUsdc_MultiplierPool: guniFloatUsdcMultiplierPool,

    floatMintingCeremony: floatMintingCeremonyContract,
    zapInFloatMinting: zapInFloatMintingContract,

    auctionHouse: auctionHouseContract,
    monetary: monetaryContract,
    basket: basketContract,

    twap: twapContract,
  };

  return (
    <Web3ContractsContext.Provider value={value}>
      {children}
    </Web3ContractsContext.Provider>
  );
};

export default Web3ContractsProvider;
