import React from "react";

import { AbiItem } from "web3-utils";

import Web3Contract from "web3/Web3Contract";
import { BigNumber } from "@ethersproject/bignumber";
import { useReload } from "hooks/useReload";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { getDeployment, toBigNumber } from "web3/utils";
import { AUCTION_DURATION } from "./AuctionHouse";

export interface BasketData {
  basketFactor?: BigNumber;
  targetRatio?: BigNumber;
  startingBasketFactor?: BigNumber;
  endingBasketFactor?: BigNumber;
}

const initialData: BasketData = {
  basketFactor: undefined,
  targetRatio: undefined,
};

interface ContractProps {
  abi: AbiItem[];
  addr: string;
  name: string;
}

interface BasketProps extends ContractProps {
  targetPriceInEth?: BigNumber;
  targetPriceInEthBeforeAuctionStart?: BigNumber;
  targetPriceInEthAtAuctionStart?: BigNumber;
  lastAuctionBlock?: number;
}

export interface BasketContract extends BasketData {
  contract: Web3Contract;
  reload: VoidFunction;
}

function useBasket({
  abi,
  addr,
  name,
  targetPriceInEth,
  targetPriceInEthBeforeAuctionStart,
  targetPriceInEthAtAuctionStart,
  lastAuctionBlock,
}: BasketProps): BasketContract {
  const [reload] = useReload();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(abi, addr, name);
  }, [abi, addr, name]);

  const [data, setData] = React.useState<BasketData>({
    ...initialData,
  });

  useAsyncEffect(async () => {
    if (!targetPriceInEth) {
      return;
    }

    const [
      basketFactor,
      startingBasketFactor,
      endingBasketFactor,
      targetRatio,
    ] = await contract.batch([
      {
        method: "getBasketFactor",
        methodArgs: [targetPriceInEth],
        transform: toBigNumber,
      },
      {
        method: "getBasketFactor",
        methodArgs: [targetPriceInEthBeforeAuctionStart],
        defaultBlock: lastAuctionBlock ? lastAuctionBlock - 1 : undefined,
        transform: toBigNumber,
      },
      {
        method: "getBasketFactor",
        methodArgs: [targetPriceInEthAtAuctionStart],
        defaultBlock: lastAuctionBlock
          ? lastAuctionBlock + AUCTION_DURATION
          : undefined,
        transform: toBigNumber,
      },
      {
        method: "targetRatio",
        transform: toBigNumber,
      },
    ]);

    setData((prev) => ({
      ...prev,
      basketFactor,
      targetRatio,
      startingBasketFactor,
      endingBasketFactor,
    }));
  }, [
    reload,
    targetPriceInEth,
    targetPriceInEthBeforeAuctionStart,
    targetPriceInEthAtAuctionStart,
    lastAuctionBlock,
  ]);

  return React.useMemo<BasketContract>(
    () => ({
      ...data,
      contract,
      reload,
    }),
    [data, contract, reload]
  );
}

interface UseBasketContractProps {
  targetPriceInEth?: BigNumber;
  targetPriceInEthBeforeAuctionStart?: BigNumber;
  targetPriceInEthAtAuctionStart?: BigNumber;
  lastAuctionBlock?: number;
}

export function useBasketContract({
  targetPriceInEth,
  targetPriceInEthBeforeAuctionStart,
  targetPriceInEthAtAuctionStart,
  lastAuctionBlock,
}: UseBasketContractProps): BasketContract {
  return useBasket({
    abi: getDeployment().contracts.BASKET?.abi ?? [],
    addr: getDeployment().contracts.BASKET?.address ?? "",
    name: "BASKET",
    targetPriceInEth,
    targetPriceInEthBeforeAuctionStart,
    targetPriceInEthAtAuctionStart,
    lastAuctionBlock,
  });
}
