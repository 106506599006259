import React, { ReactElement } from "react";
import styled from "styled-components";
import AuctionProgress from "components/AuctionProgress/AuctionProgress";

import AuctionDetails from "components/AuctionDetails/AuctionDetails";
import Ticker from "components/Ticker/Ticker";
import { useAuctionContext } from "contexts/AuctionContext";
import { formatUnits } from "web3/utils";

const StyledAuctionPanel = styled.div`
  display: grid;
  row-gap: 12px;
  margin: 0 2rem;
  justify-content: center;

  @media only screen and (min-width: 769px) {
    text-align: left;

`;

const StyledAuctionTitle = styled.span`
  background: linear-gradient(var(--linear-gradient-1));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const AuctionDialWrapper = styled.div`
  margin: 4rem 0 1rem 0;
`;

const TickerRing = styled.div`
  position: absolute;
  height: 16.125rem;
  width: 16.125rem;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0rem 1rem 2.5rem rgba(112, 144, 176, 0.2);
`;

const CurrentEthPrice = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #aaa;
`;

const CurrentPriceLabel = styled.div`
  font-weight: bold;
  color: #aaa;
`;

interface AuctionPanelProps {
  auctionType: string;
}

interface TickerState {
  price: string;
  direction: "up" | "down";
}

const AuctionPanel = ({ auctionType }: AuctionPanelProps): ReactElement => {
  const { step, currentPrice } = useAuctionContext();
  const { inEth, inUsd } = currentPrice;

  const [value, setValue] = React.useState<TickerState>({
    price: "1.618",
    direction: "up",
  });

  React.useEffect(() => {
    setValue({
      price: Number(formatUnits(inUsd, 27, 3)).toFixed(3) ?? "-",
      direction: "up",
    });
  }, [inUsd]);

  return (
    <StyledAuctionPanel>
      <h1>
        Auction / <StyledAuctionTitle>{auctionType}</StyledAuctionTitle>
      </h1>
      <AuctionDialWrapper>
        <AuctionProgress value={step} totalBlocks={149}>
          <TickerRing>
            <Ticker value={value.price} direction={value.direction} />
            <CurrentEthPrice>{formatUnits(inEth, 27, 8)} Ξ</CurrentEthPrice>
            <CurrentPriceLabel>Current Auction Price</CurrentPriceLabel>
          </TickerRing>
        </AuctionProgress>
      </AuctionDialWrapper>
      <CurrentPriceLabel style={{ textAlign: "center" }}>
        Block: {step}/150
      </CurrentPriceLabel>
      <AuctionDetails />
    </StyledAuctionPanel>
  );
};

export default AuctionPanel;
