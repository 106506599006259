import React, { ReactElement } from "react";
import "./App.css";

import { HashRouter } from "react-router-dom";
import LayoutView from "views/Layout";
import Web3WalletProvider from "wallets/wallet";
import { ToastContainer } from "react-toastify";
import WhitelistProvider from "contexts/WhitelistContext";
import Web3ContractsProvider from "contexts/Web3ContractsContext";
import PricesProvider from "contexts/PriceContext";
import PreferencesProvider from "contexts/PreferencesContext";

const App = (): ReactElement => (
  <Web3WalletProvider>
    <PreferencesProvider>
      <WhitelistProvider>
        <PricesProvider>
          <Web3ContractsProvider>
            <HashRouter>
              <LayoutView />
              <ToastContainer style={{ zIndex: 0 }} />
            </HashRouter>
          </Web3ContractsProvider>
        </PricesProvider>
      </WhitelistProvider>
    </PreferencesProvider>
  </Web3WalletProvider>
);

export default App;
