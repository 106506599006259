import DepositModal from "components/DepositModal/DepositModal";
import WithdrawModal from "components/WithdrawalModal/WithdrawModal";
import RequestWithdrawalModal from "components/RequestWithdrawalModal/RequestWithdrawalModal";
import React, { PropsWithChildren } from "react";
import { Pool, PoolData } from "web3/contracts/Pool";
import { TokenContractWithAllowance } from "web3/contracts/StakeTokens";
import { MaxUint256 } from "@ethersproject/constants";
import { parseUnits } from "@ethersproject/units";
import { useWallet } from "wallets/wallet";
import GeneralModal from "components/GeneralModal/GeneralModal";
import { useReceipt } from "hooks/useReceipt";
import { toast } from "react-toastify";
import { useWhitelist } from "./WhitelistContext";
import WhitelistModal from "components/WhitelistModal/WhitelistModal";
import { useWeb3Contracts } from "./Web3ContractsContext";
import { Phase1Pool } from "web3/contracts/Phase1Pool";
import { Phase2Pool } from "web3/contracts/Phase2Pool";
import { Phase4aPool, Phase4Pool } from "web3/contracts/Phase4Pool";
import { MultiplierPool } from "web3/contracts/MultiplierPool";

export interface PoolOptions {
  displayName?: string;
  isPhase1?: boolean;
  expired?: boolean;
  description?: React.ReactNode;
  subText?: React.ReactNode;
  newPool?: Pool<PoolData>;
  weeklyBank?: any;

  purchaseURL?: string;
  tokenPair?: {
    token0: string;
    token1: string;
  };
  liquidityProvider?: string;
  liquidityURL?: string;
}

export type StakingPool =
  | Phase1Pool
  | Phase2Pool
  | Phase4Pool
  | Phase4aPool
  | MultiplierPool;

interface StakeOption {
  pool: StakingPool;
  token: TokenContractWithAllowance;
  options?: PoolOptions;

  // Controllers
  approving: boolean;
  startApprove: VoidFunction;

  requestWithdrawing: boolean;
  startRequestWithdraw: VoidFunction;
  requestWithdraw: VoidFunction;

  unlocking: boolean;
  startUnlock: VoidFunction;

  withdrawing: boolean;
  startWithdraw: VoidFunction;
  withdraw(val: string): void;

  depositing: boolean;
  startDeposit: VoidFunction;
  deposit(val: string): void;

  claiming: boolean;
  startClaim: VoidFunction;

  startRestake: VoidFunction;

  exit: VoidFunction;
}

const StakeOptionContext = React.createContext<StakeOption>({} as StakeOption);

export function useStakeOption(): StakeOption {
  return React.useContext(StakeOptionContext);
}

interface StakeOptionProviderProps {
  pool: StakingPool;
  token: TokenContractWithAllowance;
  options?: PoolOptions;
}

const StakeOptionProvider: React.FC<StakeOptionProviderProps> = ({
  pool,
  token,
  options,
  children,
}: PropsWithChildren<StakeOptionProviderProps>) => {
  // Modal Controllers
  const [withdrawModalOpen, setWithdrawModalOpen] = React.useState(false);
  const [requestWithdrawModalOpen, setRequestWithdrawModalOpen] =
    React.useState(false);
  const [unlockModalOpen, setUnlockModalOpen] = React.useState(false);
  const [depositModalOpen, setDepositModalOpen] = React.useState(false);
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);
  const [whitelistModalOpen, setWhitelistModalOpen] = React.useState(false);

  // Status Controllers
  const [claiming, setClaiming] = React.useState(false);
  const [approving, setApproving] = React.useState(false);
  const [depositing, setDepositing] = React.useState(false);
  const [requestWithdrawing, setRequestWithdrawing] = React.useState(false);
  const [unlocking, setUnlocking] = React.useState(false);
  const [withdrawing, setWithdrawing] = React.useState(false);

  // Error Controller
  const [error, setError] = React.useState("");

  const { account } = useWallet();
  const { tree } = useWhitelist();
  const { bank } = useWeb3Contracts();

  useReceipt(
    (receipt) =>
      toast.success(
        `${token.tokenMeta.name} approved #${receipt.transactionHash}`
      ),
    token.contract,
    "approve"
  );

  const startApprove = async () => {
    if (options?.isPhase1 && (!tree || !account || !tree.hasProof(account))) {
      setWhitelistModalOpen(true);
      return;
    }

    setApproving(true);
    try {
      await token.approveSend(pool.contract.address, MaxUint256);
    } catch (err) {
      setError(`Approve failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("claim.claim.err", err);
    }
    setApproving(false);
  };

  const startRestake = async () => {
    if (!options?.expired || !pool?.staked || !options?.newPool) {
      setError(`Cannot Re-stake as there is no corresponding pool.`);
      return;
    }
    setWithdrawing(true);
    const unitAmount = pool.staked;
    try {
      await pool.withdraw(unitAmount);
      token.reload();
    } catch (err) {
      setError(`Withdraw failed: ${err?.message}`);
      setErrorModalOpen(true);
      console.error("withdraw.withdraw.err", err);
    }
    setDepositing(true);
    setWithdrawing(false);
    try {
      await options.newPool.stake(unitAmount);
      token.reload();
    } catch (err) {
      setError(`Deposit failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("deposit.stake.err", err);
    }
    setDepositing(false);
  };

  //TODO: Add in error details with contracts hook up
  const startRequestWithdraw = async () => {
    setRequestWithdrawModalOpen(true);
    return;
  };

  // Mock up Request to withdraw/ unlock
  const requestWithdraw = async () => {
    const phase4Pool = pool as Phase4Pool;

    if (!phase4Pool.unlock) {
      setError(`Cannot unlock a Pool that doesn't have a lock`);
      setErrorModalOpen(true);
      return;
    }

    setRequestWithdrawing(true);
    try {
      await phase4Pool.unlock();
      token.reload();
    } catch (err) {
      setError(`Unlock failed: ${err?.message}`);
      setErrorModalOpen(true);
      console.error("requestWithdraw.unlock.err", err);
    }
    setRequestWithdrawing(false);
  };

  const startUnlock = async () => {
    setUnlocking(true);

    setUnlockModalOpen(true);
    return;
  };

  const startClaim = async () => {
    if (options?.isPhase1 && (!tree || !account || !tree.hasProof(account))) {
      setWhitelistModalOpen(true);
      return;
    }

    setClaiming(true);
    try {
      await pool.claim();
      bank.reload();
    } catch (err) {
      setError(`Claim failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("claim.claim.err", err);
    }
    setClaiming(false);
  };

  const withdraw = async (amount: string) => {
    setWithdrawing(true);
    const unitAmount = parseUnits(amount, token.tokenMeta.decimals);
    try {
      await pool.withdraw(unitAmount);
      token.reload();
    } catch (err) {
      setError(`Withdraw failed: ${err?.message}`);
      setErrorModalOpen(true);
      console.error("withdraw.withdraw.err", err);
    }
    setWithdrawing(false);
  };

  const startDeposit = () => {
    setDepositModalOpen(true);
  };

  const deposit = async (amount: string) => {
    setDepositing(true);
    const unitAmount = parseUnits(amount, token.tokenMeta.decimals);
    try {
      await pool.stake(unitAmount);
      token.reload();
    } catch (err) {
      setError(`Deposit failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("deposit.stake.err", err);
    }
    setDepositing(false);
  };

  const exit = async () => {
    setClaiming(true);
    setWithdrawing(true);
    try {
      await pool.exit();
      token.reload();
      bank.reload();
    } catch (err) {
      setError(`Exit failed > ${err?.message}`);
      setErrorModalOpen(true);
      console.error("exit.err", err);
    }
    setWithdrawing(false);
    setClaiming(false);
  };

  const value = {
    pool,
    token,
    options,
    approving,
    requestWithdrawing,
    unlocking,
    withdrawing,
    depositing,
    claiming,
    startApprove,
    startRequestWithdraw,
    startUnlock,
    startWithdraw: () => setWithdrawModalOpen(true),
    withdraw,
    startDeposit,
    deposit,
    requestWithdraw,
    startClaim,
    startRestake,
    exit,
  };
  return (
    <StakeOptionContext.Provider value={value}>
      <WithdrawModal
        isOpen={withdrawModalOpen}
        onCancel={() => setWithdrawModalOpen(false)}
      />
      <DepositModal
        isOpen={depositModalOpen}
        onCancel={() => setDepositModalOpen(false)}
      />
      <WhitelistModal
        isOpen={whitelistModalOpen}
        onCancel={() => setWhitelistModalOpen(false)}
      />
      <GeneralModal
        className="error-modal"
        isOpen={errorModalOpen}
        onCancel={() => setErrorModalOpen(false)}
        modalTitle={"Here's the issue."}
        modalBody={error}
      />
      <RequestWithdrawalModal
        isOpen={requestWithdrawModalOpen}
        onCancel={() => setRequestWithdrawModalOpen(false)}
      />
      <GeneralModal
        className="error-modal"
        isOpen={unlockModalOpen}
        onCancel={() => setUnlockModalOpen(false)}
        modalTitle={"Your stake is still locked!"}
        modalBody={<p> Your stake is locked.</p>}
      />

      {children}
    </StakeOptionContext.Provider>
  );
};

export default StakeOptionProvider;
