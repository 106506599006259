import { useState } from "react";

export function useLocalStorage<S = unknown>(key: string, initialState: S | (() => S)): [S, React.Dispatch<React.SetStateAction<S>>] {
  return useState<S>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialState
      return item !== null ? JSON.parse(item) : initialState;
    } catch (error) {
      // If error also return initialState
      console.log(error);
      return initialState;
    }
  });
}