import React from "react";

import ThemeProvider from "theme";
import Landing from "components/Landing/Landing";

const LandingView: React.FC = () => {
  return (
    <ThemeProvider>
      <Landing />
    </ThemeProvider>
  );
};

export default LandingView;
