import React from "react";
import { ArrowDown } from "react-feather";
import { formatUnits as ethersFormatUnits } from "@ethersproject/units";

import { AutoRow } from "../Row";
import { useWallet } from "wallets/wallet";
import LoadingButton from "components/LoadingButton/LoadingButton";
import ConnectWalletButton from "components/ConnectWallet/ConnectWalletButton";
import { Cases } from "web3/contracts/AuctionHouse";
import { formatUnits } from "web3/utils";
import PriceInputPanel from "components/PriceInputPanel/PriceInputPanel";
import {
  useAuctionContext,
  // TradeChoice,
  Target,
} from "contexts/AuctionContext";

import "./TradePanel.css";
import { useWeb3Contracts } from "contexts/Web3ContractsContext";

interface InputPanelProps {
  label: string;
  value: string;
  onUserInput(value: string): void;
}

interface TradePanelProps {
  tradeType: string;
  stabilisationCase: Cases;
}

const WethInputPanel = ({ label, value, onUserInput }: InputPanelProps) => {
  const { weth } = useWeb3Contracts();

  return (
    <PriceInputPanel
      label={label}
      value={value}
      onUserInput={onUserInput}
      token={weth}
    />
  );
};
const BankInputPanel = ({ label, value, onUserInput }: InputPanelProps) => {
  const { bank } = useWeb3Contracts();
  return (
    <PriceInputPanel
      label={label}
      value={value}
      onUserInput={onUserInput}
      token={bank}
    />
  );
};

const FloatInputPanel = ({ label, value, onUserInput }: InputPanelProps) => {
  const { float } = useWeb3Contracts();

  return (
    <PriceInputPanel
      label={label}
      value={value}
      onUserInput={onUserInput}
      token={float}
    />
  );
};

const ExpansionPanels = () => {
  const {
    target,
    stabilisationCase,
    handleTypeInput,
    inputAmount,
    wethAmount,
    bankAmount,
    floatAmount,
  } = useAuctionContext();

  const formattedWethAmount = wethAmount
    ? ethersFormatUnits(wethAmount, 18)
    : "";
  const formattedBankAmount = bankAmount
    ? ethersFormatUnits(bankAmount, 18)
    : "";
  const formattedFloatAmount = floatAmount
    ? ethersFormatUnits(floatAmount, 18)
    : "";

  const fromLabel = "From";
  const toLabel = "To";
  return (
    <>
      <WethInputPanel
        label={fromLabel}
        value={target === Target.WETH ? inputAmount : formattedWethAmount}
        onUserInput={(value: string) => handleTypeInput(Target.WETH, value)}
      />
      {stabilisationCase !== undefined && stabilisationCase === Cases.Up && (
        <BankInputPanel
          label={fromLabel}
          value={target === Target.BANK ? inputAmount : formattedBankAmount}
          onUserInput={(value: string) => handleTypeInput(Target.BANK, value)}
        />
      )}
      <AutoRow justify="center" className="arrow">
        <ArrowDown />
      </AutoRow>
      <FloatInputPanel
        label={toLabel}
        value={target === Target.FLOAT ? inputAmount : formattedFloatAmount}
        onUserInput={(value: string) => handleTypeInput(Target.FLOAT, value)}
      />
    </>
  );
};

const ContractionPanels = () => {
  const {
    target,
    stabilisationCase,
    handleTypeInput,
    inputAmount,
    wethAmount,
    bankAmount,
    floatAmount,
  } = useAuctionContext();

  const formattedWethAmount = wethAmount
    ? ethersFormatUnits(wethAmount, 18)
    : "";
  const formattedBankAmount = bankAmount
    ? ethersFormatUnits(bankAmount, 18)
    : "";
  const formattedFloatAmount = floatAmount
    ? ethersFormatUnits(floatAmount, 18)
    : "";

  const fromLabel = "From";
  const toLabel = "To";

  return (
    <>
      <FloatInputPanel
        label={fromLabel}
        value={target === Target.FLOAT ? inputAmount : formattedFloatAmount}
        onUserInput={(value: string) => handleTypeInput(Target.FLOAT, value)}
      />
      <AutoRow justify="center">
        <ArrowDown />
      </AutoRow>
      <WethInputPanel
        label={toLabel}
        value={target === Target.WETH ? inputAmount : formattedWethAmount}
        onUserInput={(value: string) => handleTypeInput(Target.WETH, value)}
      />
      {stabilisationCase !== undefined && stabilisationCase === Cases.Down && (
        <BankInputPanel
          label={toLabel}
          value={target === Target.BANK ? inputAmount : formattedBankAmount}
          onUserInput={(value: string) => handleTypeInput(Target.BANK, value)}
        />
      )}
    </>
  );
};

const TradePanel = ({ tradeType }: TradePanelProps): React.ReactElement => {
  const auction = useAuctionContext();
  const {
    isExpansion,
    stabilisationCase,
    latestAuction,
    buying,
    buy,
    inputAmount,
    floatAmount,
    approving,
    approve,
    wrap,
    wrapping,
    selling,
    sell,
  } = auction;
  const contracts = useWeb3Contracts();
  const { auctionHouse, weth } = contracts;
  const { isActive } = useWallet();

  const fromTokens: ("weth" | "bank" | "float")[] =
    stabilisationCase === Cases.Up
      ? ["weth", "bank"]
      : stabilisationCase === Cases.Restock
      ? ["weth"]
      : ["float"];

  // const allowance = BigNumber.from(0);
  const empty = inputAmount === "";
  const exceedAuctionAllowance = latestAuction?.allowance
    ? floatAmount?.gt(latestAuction.allowance.sub(latestAuction.delta))
    : false;
  const exceedBalance = fromTokens.map((token) => {
    const balance = contracts[token]?.balance;
    return balance
      ? balance.lt(
          auction[
            `${token}Amount` as "wethAmount" | "bankAmount" | "floatAmount"
          ] ?? 0
        )
      : false;
  });

  const wethExceedBalance = weth?.balance
    ? weth?.balance?.lt(auction.wethAmount ?? 0)
    : false;
  const anyExceedBalance = exceedBalance.some((x) => x);
  const exceedApproval = fromTokens.map((token) => {
    const allowance =
      contracts[token]?.allowance[auctionHouse.contract.address];
    return allowance
      ? allowance.lte(
          auction[
            `${token}Amount` as "wethAmount" | "bankAmount" | "floatAmount"
          ] ?? 0
        )
      : false;
  });
  const needsApproval = exceedApproval.some((x) => x);

  const disabled = anyExceedBalance || exceedAuctionAllowance || empty;

  const BuyOnlyButtonGroup = () => {
    if (
      fromTokens.includes("weth") &&
      wethExceedBalance &&
      !empty &&
      !exceedAuctionAllowance
    ) {
      return (
        <>
          <div className="buttons-container">
            <LoadingButton
              className={`max-width-button loading-button gradient`}
              text={"Wrap"}
              loading={wrapping}
              onClick={wrap}
            />
          </div>
          <p>
            This will wrap your ETH to wETH in a separate transaction since you
            do not have enough wETH in your account.
          </p>
        </>
      );
    }
    return (
      <div className="buttons-container">
        <LoadingButton
          className={`max-width-button loading-button gradient ${
            disabled ? " -disabled" : ""
          }`}
          text={
            exceedAuctionAllowance
              ? "Not enough FLOAT on offer"
              : anyExceedBalance
              ? "Insufficient balance"
              : empty
              ? "Enter an amount"
              : "Buy"
          }
          loading={buying}
          onClick={disabled ? console.warn : buy}
        />
      </div>
    );
  };

  const SellOnlyButtonGroup = () => {
    return (
      <div className="buttons-container">
        <LoadingButton
          className={`max-width-button loading-button gradient ${
            disabled ? " -disabled" : ""
          }`}
          text={
            exceedAuctionAllowance
              ? "Not enough FLOAT on offer"
              : anyExceedBalance
              ? "Insufficient balance"
              : empty
              ? "Enter an amount"
              : "Sell"
          }
          loading={selling}
          onClick={disabled ? console.warn : sell}
        />
      </div>
    );
  };

  const ApproveButtonGroup = () => (
    <div className="buttons-container">
      {fromTokens.map((tokenName) => {
        return (
          <LoadingButton
            key={tokenName}
            className="max-width-button loading-button gradient"
            text={`Approve ${tokenName.toUpperCase()}`}
            loading={approving[tokenName]}
            onClick={() => approve(tokenName)}
          />
        );
      })}
    </div>
  );

  return (
    <div className="trade-panel">
      <h1>{tradeType}</h1>
      {isExpansion ? <ExpansionPanels /> : <ContractionPanels />}
      <br />
      <AutoRow justify="space-between">
        <h3 className="trade-detail">Available Float for Purchase</h3>
        <h3 className="trade-detail">
          {formatUnits(latestAuction?.allowance)}
        </h3>
      </AutoRow>
      <br />
      <AutoRow justify="space-between">
        <h3 className="trade-detail">Float Purchased This Auction</h3>
        <h3 className="trade-detail">{formatUnits(latestAuction?.delta)}</h3>
      </AutoRow>
      <br />
      {isActive && !needsApproval && !isExpansion && <SellOnlyButtonGroup />}
      {isActive && !needsApproval && isExpansion && <BuyOnlyButtonGroup />}
      {isActive && needsApproval && <ApproveButtonGroup />}
      {!isActive && (
        <>
          <div className="buttons-container">
            <ConnectWalletButton />
          </div>
        </>
      )}
    </div>
  );
};

export default TradePanel;
