import React from "react";

import "./PriceInput.css";

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`); // match escaped "." characters via in a non-capturing group

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

const PriceInput = React.memo(function InnerInput({
  value,
  onUserInput,
  disabled,
  error,
}: {
  value: string | number;
  onUserInput?: (input: string) => void;
  error?: boolean;
} & Omit<React.HTMLProps<HTMLInputElement>, "ref" | "onChange" | "as">) {
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput?.(nextUserInput);
    }
  };

  return (
    <input
      className={`price-input${error ? " -error" : ""}`}
      value={value}
      onChange={(event) => {
        // replace commas with periods, because uniswap exclusively uses period as the decimal separator
        enforcer(event.target.value.replace(/,/g, "."));
      }}
      disabled={disabled}
      // universal input options
      inputMode="decimal"
      title="Token Amount"
      autoComplete="off"
      autoCorrect="off"
      // text-specific options
      type="text"
      pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder="0.0"
    />
  );
});

export default PriceInput;
