import React, { useEffect } from "react";
import { BigNumber } from "@ethersproject/bignumber";

import { useReload } from "hooks/useReload";
import { useWallet } from "wallets/wallet";
import { useAsyncEffect } from "hooks/useAsyncEffect";

import Web3Contract, { BatchContractMethod } from "web3/Web3Contract";
import { TokenMeta } from "web3/TokenMeta";
import { ABIS, getDeployment, getWETH9 } from "web3/utils";

import ethLogo from "assets/tokens/ETH.png";
import USDCLogo from "assets/tokens/USDC.png";
import USDTLogo from "assets/tokens/USDT.png";
import DAILogo from "assets/tokens/DAI.png";
import YFILogo from "assets/tokens/YFI.png";
import YAMLogo from "assets/tokens/YAM.png";
import SUSHILogo from "assets/tokens/SUSHI.png";
import wBTCLogo from "assets/tokens/wBTC.png";
import FEILogo from "assets/tokens/FEI.png";
import wETHLogo from "assets/tokens/wETH.png";

import { usePrices } from "contexts/PriceContext";
import { usePreferences } from "contexts/PreferencesContext";

export interface TokenContractData {
  tokenMeta: TokenMeta;
  balance?: BigNumber;
  priceInUSD?: string;
}

export interface TokenContractWithAllowanceData extends TokenContractData {
  allowance: {
    [address: string]: BigNumber | undefined;
  };
}

export interface Contract {
  contract: Web3Contract;
  reload(): void;
}

export interface TokenContractWithAllowance
  extends Contract,
    TokenContractWithAllowanceData {
  approveSend(address: string, value: BigNumber): Promise<void>;
}

const initialData: Omit<TokenContractWithAllowanceData, "tokenMeta"> = {
  balance: undefined,
  allowance: {},
  priceInUSD: "0",
};

export interface UseTokenContractProps {
  tokenMeta: TokenMeta;
  allowanceAddresses?: string[];
}

export function useTokenContract({
  tokenMeta,
  allowanceAddresses = [],
}: UseTokenContractProps): TokenContractWithAllowance {
  const [reload] = useReload();
  const wallet = useWallet();
  const { fetched, getPrice } = usePrices();
  const { txn } = usePreferences();

  const contract = React.useMemo<Web3Contract>(() => {
    return new Web3Contract(tokenMeta.abi, tokenMeta.address, tokenMeta.name);
  }, [tokenMeta.abi, tokenMeta.name, tokenMeta.address]);

  const [data, setData] = React.useState<TokenContractWithAllowanceData>({
    ...initialData,
    tokenMeta,
  });

  useAsyncEffect(async () => {
    let balance: BigNumber | undefined;
    let allowanceResults: (BigNumber | undefined)[] = [];

    if (wallet.account) {
      [balance, ...allowanceResults] = await contract.batch([
        {
          method: "balanceOf",
          methodArgs: [wallet.account],
          transform: (value: string) => BigNumber.from(value),
        },
        ...allowanceAddresses.map<BatchContractMethod>((address) => ({
          method: "allowance",
          methodArgs: [wallet.account, address],
          transform: (value: string) => BigNumber.from(value),
        })),
      ]);
    }

    const allowance = Object.fromEntries(
      allowanceAddresses.map((addr, i) => {
        return [addr, allowanceResults[i]];
      })
    );

    setData((prevState) => ({
      ...prevState,
      balance,
      allowance,
    }));
  }, [reload, wallet.account]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      priceInUSD: getPrice(tokenMeta.name),
    }));
  }, [fetched, getPrice, tokenMeta.name]);

  const approveSend = React.useCallback(
    (address: string, value: BigNumber): Promise<void> => {
      if (!wallet.account) {
        return Promise.reject();
      }

      if (!allowanceAddresses.includes(address)) {
        return Promise.reject("Unmonitored address");
      }

      return contract
        .send("approve", [address, value], {
          from: wallet.account,
          type: txn.type,
        })
        .then(reload);
    },
    [wallet.account, contract, allowanceAddresses, txn.type, reload]
  );

  return React.useMemo<TokenContractWithAllowance>(
    () => ({
      ...data,
      contract,
      reload,
      approveSend,
    }),
    [data, contract, reload, approveSend]
  );
}

// Mainnet Token Addresses
const DAI_MAINNET = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
const USDC_MAINNET = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
const USDT_MAINNET = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
const SUSHI_MAINNET = "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2";
const wBTC_MAINNET = "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599";
const YAM_MAINNET = "0x0AaCfbeC6a24756c20D41914F2caba817C0d8521";
const YFI_MAINNET = "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e";
const FEI_MAINNET = "0x956f47f50a910163d8bf957cf5846d573e7f87ca";

// DAI

export const DAITokenMeta: TokenMeta = {
  abi: getDeployment().contracts.DAI?.abi ?? ABIS.ERC20,
  address: getDeployment().contracts.DAI?.address ?? DAI_MAINNET,
  decimals: 18,
  name: "DAI",
  icon: <img src={DAILogo} className="coin-logo" alt="logo" />,
};

export function useDAIContract(): TokenContractWithAllowance {
  return useTokenContract({
    tokenMeta: DAITokenMeta,
    allowanceAddresses: [
      getDeployment().contracts.DAIPool.address,
      getDeployment().contracts.DAIPhase2Pool.address,
      getDeployment().contracts.ZapInFloatMintingCeremony.address,
    ],
  });
}

// USDC

export const USDCTokenMeta: TokenMeta = {
  abi: getDeployment().contracts.USDC?.abi ?? ABIS.ERC20,
  address: getDeployment().contracts.USDC?.address ?? USDC_MAINNET,
  decimals: 6,
  name: "USDC",
  icon: <img src={USDCLogo} className="coin-logo" alt="logo" />,
};

export function useUSDCContract(): TokenContractWithAllowance {
  return useTokenContract({
    tokenMeta: USDCTokenMeta,
    allowanceAddresses: [
      getDeployment().contracts.USDCPool.address,
      getDeployment().contracts.USDCPhase2Pool.address,
      getDeployment().contracts.ZapInFloatMintingCeremony.address,
    ],
  });
}

// USDT
export const USDTTokenMeta: TokenMeta = {
  abi: getDeployment().contracts.USDT?.abi ?? ABIS.ERC20,
  address: getDeployment().contracts.USDT?.address ?? USDT_MAINNET,
  decimals: 6,
  name: "USDT",
  icon: <img src={USDTLogo} className="coin-logo" alt="logo" />,
};

export function useUSDTContract(): TokenContractWithAllowance {
  return useTokenContract({
    tokenMeta: USDTTokenMeta,
    allowanceAddresses: [
      getDeployment().contracts.USDTPool.address,
      getDeployment().contracts.USDTPhase2Pool.address,
      getDeployment().contracts.ZapInFloatMintingCeremony.address,
    ],
  });
}
// Phase 2

// YFI
export const YFITokenMeta: TokenMeta = {
  abi: getDeployment().contracts.YFI?.abi ?? ABIS.ERC20,
  address: getDeployment().contracts.YFI?.address ?? YFI_MAINNET,
  decimals: 18,
  name: "YFI",
  icon: <img src={YFILogo} className="coin-logo" alt="logo" />,
};

export function useYFIContract(): TokenContractWithAllowance {
  return useTokenContract({
    tokenMeta: YFITokenMeta,
    allowanceAddresses: [getDeployment().contracts.YFIPhase2Pool.address],
  });
}

// ETH
export const ETHTokenMeta: TokenMeta = {
  abi: [],
  address: "",
  decimals: 18,
  name: "ETH",
  icon: <img src={ethLogo} className="coin-logo" alt="logo" />,
};

// wETH
export const wETHTokenMeta: TokenMeta = {
  abi: ABIS.WETH9,
  address: getWETH9(),
  decimals: 18,
  name: "wETH",
  icon: <img src={wETHLogo} className="coin-logo" alt="logo" />,
};

export interface WETH9 extends TokenContractWithAllowance {
  wrap(val: BigNumber): Promise<void>;
}

export function useWETHContract(): WETH9 {
  const tokenWithAllowance = useTokenContract({
    tokenMeta: wETHTokenMeta,
    allowanceAddresses: [
      getDeployment().contracts.MintingCeremony.address,
      getDeployment().contracts.ZapInFloatMintingCeremony.address,
      getDeployment().contracts.AuctionHouse?.address ?? "0x0",
    ],
  });

  const wallet = useWallet();
  const { txn } = usePreferences();

  const wrap = React.useCallback(
    (val: BigNumber) => {
      if (!wallet.account) {
        return Promise.reject();
      }
      return tokenWithAllowance.contract
        .send("deposit", [], {
          from: wallet.account,
          value: val,
          type: txn.type,
        })
        .then(tokenWithAllowance.reload);
    },
    [
      wallet.account,
      tokenWithAllowance.contract,
      txn.type,
      tokenWithAllowance.reload,
    ]
  );

  return React.useMemo<WETH9>(
    () => ({
      ...tokenWithAllowance,
      wrap,
    }),
    [tokenWithAllowance, wrap]
  );
}

// YAM
export const YAMTokenMeta: TokenMeta = {
  abi: getDeployment().contracts.YAM?.abi ?? ABIS.ERC20,
  address: getDeployment().contracts.YAM?.address ?? YAM_MAINNET,
  decimals: 18,
  name: "YAM",
  icon: <img src={YAMLogo} className="coin-logo" alt="logo" />,
};

export function useYAMContract(): TokenContractWithAllowance {
  return useTokenContract({
    tokenMeta: YAMTokenMeta,
    allowanceAddresses: [getDeployment().contracts.YAMPhase2Pool.address],
  });
}

// SUSHI
export const SUSHITokenMeta: TokenMeta = {
  abi: getDeployment().contracts.SUSHI?.abi ?? ABIS.ERC20,
  address: getDeployment().contracts.SUSHI?.address ?? SUSHI_MAINNET,
  decimals: 18,
  name: "SUSHI",
  icon: <img src={SUSHILogo} className="coin-logo" alt="logo" />,
};

export function useSUSHIContract(): TokenContractWithAllowance {
  return useTokenContract({
    tokenMeta: SUSHITokenMeta,
    allowanceAddresses: [getDeployment().contracts.SUSHIPhase2Pool.address],
  });
}

// wBTC
export const wBTCTokenMeta: TokenMeta = {
  abi: getDeployment().contracts.wBTC?.abi ?? ABIS.ERC20,
  address: getDeployment().contracts.wBTC?.address ?? wBTC_MAINNET,
  decimals: 8,
  name: "wBTC",
  icon: <img src={wBTCLogo} className="coin-logo" alt="logo" />,
};

export function useWBTCContract(): TokenContractWithAllowance {
  return useTokenContract({
    tokenMeta: wBTCTokenMeta,
    allowanceAddresses: [getDeployment().contracts.wBTCPhase2Pool.address],
  });
}

// FEI
export const FEITokenMeta: TokenMeta = {
  abi: getDeployment().contracts.FEI?.abi ?? ABIS.ERC20,
  address: getDeployment().contracts.FEI?.address ?? FEI_MAINNET,
  decimals: 18,
  name: "FEI",
  icon: <img src={FEILogo} className="coin-logo" alt="logo" />,
};

export function useFEIContract(): TokenContractWithAllowance {
  return useTokenContract({
    tokenMeta: FEITokenMeta,
    allowanceAddresses: [
      getDeployment().contracts.ZapInFloatMintingCeremony.address,
    ],
  });
}
