import React, { ReactElement } from "react";
import ExternalLink from "components/ExternalLink/ExternalLink";
import "./Footer.css";

function Footer(): ReactElement {
  return (
    <div className="footer-container">
      <div className="footer-col">
        <ExternalLink href="https://medium.com/@floatprotocol">
          Medium
        </ExternalLink>
      </div>
      <div className="footer-col">
        <span>&#8226;</span>
      </div>
      <div className="footer-col">
        <ExternalLink href="https://twitter.com/FloatProtocol">
          Twitter
        </ExternalLink>
      </div>
      <div className="footer-col">
        <span>&#8226;</span>
      </div>
      <div className="footer-col">
        <ExternalLink href="https://discord.gg/tmu78Rc8Fn">
          Discord
        </ExternalLink>
      </div>
      <div className="footer-col">
        <span>&#8226;</span>
      </div>
      <div className="footer-col">
        <ExternalLink href="https://t.me/officialfloatprotocol">
          Telegram
        </ExternalLink>
      </div>
      <div className="footer-col">
        <span>&#8226;</span>
      </div>
      <div className="footer-col">
        <ExternalLink href="https://forum.floatprotocol.com/">
          Forum
        </ExternalLink>
      </div>
      <div className="footer-col">
        <span>&#8226;</span>
      </div>
      <div className="footer-col">
        <ExternalLink href="https://docs.floatprotocol.com/">
          Documentation
        </ExternalLink>
      </div>
      <div className="footer-col">
        <span>&#8226;</span>
      </div>
      <div className="footer-col">
        <ExternalLink href="https://snapshot.org/#/snapshot.floatprotocol.eth">
          Governance
        </ExternalLink>
      </div>
      <div className="footer-col">
        <span>&#8226;</span>
      </div>
      <div className="footer-col">
        <ExternalLink href="https://github.com/FloatProtocol">
          GitHub
        </ExternalLink>
      </div>
    </div>
  );
}

export default Footer;
