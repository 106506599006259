import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { parseUnits } from "@ethersproject/units";

export const PRICE_DECIMAL = 27;
export const PRICE_BASE = BigNumber.from(10).pow(PRICE_DECIMAL);
export const AMOUNT_DECIMAL = 18;
export const AMOUNT_BASE = BigNumber.from(10).pow(AMOUNT_DECIMAL);

export function toPrice(decimalPrice: string | number): BigNumber {
  return parseUnits(decimalPrice.toString(), PRICE_DECIMAL);
}

// 1e18 + 1e18 => 1e18
export function toApr(poolSizeInUSD?: BigNumber, weeklyDistInUSD?: BigNumber): BigNumber | undefined {
  if (!poolSizeInUSD || !weeklyDistInUSD || poolSizeInUSD.lte(0)) {
    return undefined;
  }
  const weeklyAPR = weeklyDistInUSD.mul(AMOUNT_BASE).div(poolSizeInUSD);

  return weeklyAPR.mul(52);
}

export function scaleUnit(
  amount?: BigNumberish,
  decimal = 18,
  newDecimal = 18
): BigNumber | undefined {
  if (!amount) {
    return undefined;
  }

  if (newDecimal > decimal) {
    return BigNumber.from(amount).mul(
      BigNumber.from(10).pow(newDecimal - decimal)
    );
  }

  return BigNumber.from(amount).div(
    BigNumber.from(10).pow(decimal - newDecimal)
  );
}

export function divPrice(
  numerator?: BigNumber,
  denominator?: BigNumber
): BigNumber | undefined {
  if (!numerator || !denominator) {
    return undefined;
  }

  if (denominator.eq(0)) {
    return BigNumber.from(0);
  }
  return numerator.mul(PRICE_BASE).div(denominator);
}

export function mulPrice(
  multiplicand?: BigNumber,
  multiplier?: BigNumber
): BigNumber | undefined {
  if (!multiplicand || !multiplier) {
    return undefined;
  }
  return multiplicand.mul(multiplier).div(PRICE_BASE);
}

export function amountOut(
  amountIn?: BigNumberish,
  outPerIn?: BigNumberish
): BigNumber | undefined {
  if (!amountIn || !outPerIn) {
    return undefined;
  }
  return mulPrice(BigNumber.from(amountIn), BigNumber.from(outPerIn));
}

export function amountIn(
  amountOut?: BigNumberish,
  outPerIn?: BigNumberish
): BigNumber | undefined {
  if (!amountOut || !outPerIn) {
    return undefined;
  }
  return divPrice(BigNumber.from(amountOut), BigNumber.from(outPerIn));
}
